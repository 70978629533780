.CustomizeOrganization{
    padding-bottom: 0 !important;
    .ant-layout.ant-layout-has-sider{
        width: 100%;
    }
    .ant-tree{
        padding: 10px ;
        color: #999999 !important
    }
    .ant-tree.ant-tree-show-line li:not(:last-child):before{
        display: none ;
    }
    .ant-tree.ant-tree-show-line li::before{
        display: none;
    }
    .anticon-file{
        display: none;
    }
    .linkbtn{
        border: 1px #fff solid;
        box-shadow: none;
        width: 60%;
        color: #333333;
        cursor: pointer;
    }
    .linkbtn:hover{
        // border:1px #F58320 solid
        opacity: 1 !important;
        // color: #333333!important;
        background-color: #f5f5f5 !important;
        border: 1px solid #d3d3d3 !important;
        height:32px!important;
    }
    .ant-modal-body{
        padding: 10px;
    }
    .ant-tree-title{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        width: 80%;
        color: #333333 !important;
        vertical-align: middle;
    }
    // .ouTree{
        .ant-tree-iconEle{
            float: right;
        }
    // }
    .NodeSytle{
        display: block;
        padding-bottom: 10px;
    }
    .AddMembers{
        padding: 0 0 10px 0;
    }
    // .ant-tree li ul{
    //     padding: 0 !important;
    // }
}
.deFrom{
    .ant-form-item-label{
        text-align: left !important;
    }
    .ant-form-item{
        margin-bottom: 5px !important;
    }
    .ant-form.ant-form-horizontal .ant-form-item-label{
        min-height: 1px !important;
    }

}
.oudrp{
        font-size: 12px;
        color: #333333 !important;
}
.oudropBnt{
    min-width: 0 !important;
    width: 0!important;
    height: 20px!important;
    border: 0!important;
    background-color:transparent !important;
}
.ouInput{
    width: 120px;
    height: 28px;
    line-height: 1.5;
    vertical-align: middle;
}
.ouInputList{
    padding-bottom: 10px;
}
.CustomizeOrganization.ant-tree-show-line li:not(:last-child):before{
    display: none ;
}
.CustomizeOrganization.ant-tree-show-line li::before{
    display: none;
}
.changIcon>*:first-child{
         display: none !important
    }
.changIcon>*:nth-child(2){
    width: 100% !important;
    max-width: 100% !important;
}
.iconhover:hover{
    color: #F58320 !important;
}
.LdapTreeNew{
    height: auto !important;
   .ant-tree.ant-tree-show-line li::before{
        height: calc(97%)!important;
    }
   .ant-tree.ant-tree-show-line li span.ant-tree-switcher{
     background: #fff !important;
    }
    .ant-tree li ul{
        padding: 0 0 0 15px !important;
    }
}
