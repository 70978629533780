.Adddirectory{
    padding-bottom: 10px;
    padding-right: 10px;
    display: inline-block;
}
.pa-20{
    padding: 20px;
}
.ldap_{
    .from-pd{
        padding-top: 25px;
    }
    .pd-30{
        padding-left: 30px;
    }
    .pdb-50{
        padding-bottom: 50px;
    }
    .ant-col , .ant-form-item-label label{
        font-size: 12px !important;
    }
    .LdapFromFrist{
        font-size: 14px;
        padding-top: 16px;
        display: inline-block;
    }
    .ant-form-item-required::before{
        content: '*' !important;
    }
    .ant-input-number-handler-wrap{
        display: none;
    }
}
.new-spin .ant-spin-blur{
    opacity: 0 !important;
}
.ldaptestFrom{
   .ant-form-item-control-wrapper{
       display: inline-block;
       width: 200px;
    }
}
.lengedicon{
    height: 18px;
    width: 8px;
    border-radius: 4px;
    display: inline-block;
}
.reports{
    .echarts-for-react {
        height: 350px !important;
    }
}
.min-select{
    min-width: 192px;
}
.header-custom {
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    position: fixed;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid #e4e4e4e4;
}
// CPDFI-2954
.ant-modal.licese-model{
    padding-bottom: 0 !important;
}

.licese-model{
    height: 600px;
    .ant-modal-close-x {
        height: 52px !important;
        line-height: 52px !important;
    }
    .ant-modal-header{
        padding: 15px 24px !important;
    }
    .ant-modal-title{
        font-size: 14px !important;
        font-weight: bold;
        color: #333333 !important;
    }
    .ant-modal-footer{
        padding: 14px 24px !important;
        border-top:1px solid #e2e2e2 !important;
    }
    .mt-m{
        margin-top: 14px !important;
    }
    .bottom-title{
        font-size: 14px ;
        font-weight: bold;
        color: #333333;
        margin: 0;
        padding: 0;
        padding-bottom: 11px;
    }
    .ant-modal-body{
        padding:24px 40px 20px 40px !important;
    }
    .ant-radio-wrapper{
        font-size: 12px !important;
        display: block;
        padding:5px;
    }
    .transfer-wrap{
        width: auto !important;
    }
    .newheight{
        .transfer-list-body{
            height: 280px !important
        }
        .selectTips{
            padding-top: 50% !important
        }  
    }
    .transfer-wrap-box{
        width: 256px !important;
        height: 346px !important;
        border:1px solid #e2e2e2;
        // border-radius:5px;
        .transfer-list-body{
            height: 355px !important;
        }
        .ant-tree-title{
            width: 92% !important;
        }
    }
    .Search{
        width:240px;
        height:20px;
        input{
            height: 20px;
            font-size: 12px;
            border-radius: 10px;
            line-height: 1;
        }
        .ant-input-suffix{
            top:60% !important;
        }
    }
    .transfer-wrap-title{
        font-size: 14px ;
        font-weight: bold;
        color: #333333;
        padding-bottom: 8px;
    }
    .transfer-list-content{
        text-align: center;
        padding: 6px 5px !important;
    }
    .transfer-wrap .ant-tree{
        text-align: left;
        height: auto !important;
    }
    .bg{
        background: #f8f8f8;
    }
    .ant-tree.ant-tree-show-line li span.ant-tree-switcher{
        background: #f8f8f8 !important;
    }
    
    .selectTips{
        width: 70%;
        display: inline-block;
        padding-top: 65%;
        font-size: 12px;
        color: #BBBBBB;
    }
    .ant-tree.ant-tree-show-line li:not(.group_)::before{
        //  display: none;
        margin: 0 !important;
    }
    li.group_::before{
        // margin: -28px 0 0!important;
     }
    .ant-tree.ant-tree-show-line li:last-child:before{
        height: calc(~"100% - 18px") !important;
        // height: 75% !important;
    }
    .ant-tree.ant-tree-show-line li::before{
        height: calc(100%) !important
    }
    .ant-tree-child-tree.ant-tree-child-tree-open li::before{
        height: calc(~"100% - 18px") !important;
    }
    .ant-tree-child-tree.ant-tree-child-tree-open li span.ant-tree-switcher{
        border-bottom: 1px dashed #d9d9d9;
        height: 12px !important;
        display: inline-block;
        padding: 0 11px;
    }
    .ant-modal-content .transfer-wrap .ant-tree .ant-tree-node-content-wrapper{
        // margin-left: 5px;
    }
    .cotent-tree .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop{
        margin-right: 0 !important;
    }
    .cotent-tree{
        border: none !important;
    }
   .ant-tree-child-tree.ant-tree-child-tree-open li.new-ch::before{
        display: none;
    }

    .cotent-tree .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background:#FFE7D2 !important;
    }
    .cotent-tree .ant-tree li .ant-tree-node-content-wrapper:hover {
        background: #FFE7D2 !important;
    }
    .ant-modal-content .transfer-wrap.r .transfer-list-content-item:hover{
        background-color: #FFE7D2 !important;
    }
    .ant-tree-title{
        color: #333333 !important;
    }
    .ant-radio-wrapper{
        color: #333333 !important;
    }
    .ant-tree li span.ant-tree-switcher, .ant-tree li span.ant-tree-iconEle{
        z-index: 1;
    }
}
.ant-tree li{
    padding: 2px 0 !important;
}
.group-model{
    .ant-modal-body {
        padding: 26px 40px 11px 40px !important;
    }
    .ant-form-item{
        margin-bottom: 0 !important;
    }
    .ant-form-text{
        padding-right: 0px !important;
        padding-bottom: 6px;
    }
    .ant-form-vertical .ant-form-item{
        padding-bottom: 0 !important;
    }
    .ant-form-item-control{
        line-height: 1 !important;
        padding-bottom: 21px;
    }
    .ant-form-item-label{
        padding:0 0 9px !important;
    }
   .ant-form-item-label label{
        color: #333333 !important;
        font-weight: bold;
   }
   form .ant-mentions, form textarea.ant-input{
       margin-bottom:0 !important ;
   }
   .ant-input{
       border: 1px solid #aeaeae !important;
   }
   #form_in_modal_group_note{
       height: 100px !important;
   }
}
.RemoveLivenseByCSV_ {
    .anticon-loading{
    display: none !important;
    }
}

.EnterpriseBrandCustomization_container{
    .ant-spin-nested-loading{
        height: calc(100vh - 55px);
    }
}

.i-mg-10{
    margin-left: 10px;
}
.LastConnectionTime_Tooltip{
    .ant-tooltip-inner{
        min-width: 470px !important;
    }   
}
.ManageLicensing_Tooltip{
    .ant-tooltip-inner{
        min-width: 300px !important;
    }   
}

.LastConnectionTime_Tooltip,.ManageLicensing_Tooltip{
    .ant-tooltip-inner{
        background-color: #f5faff !important;
        color: #333333;
    }
    @media screen and (max-width: 1160px) {
        .ant-tooltip-inner{
            min-width: 40px !important;
        }
    }
   .ant-tooltip-arrow{
        border-width: 5px !important;
        border-top-color: #f5faff !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        border-left-color: #f5faff !important;
    }
}

.tipsChange{
     margin-left: 10px;
    .ant-btn-edit{
        border: 0 !important;
    }
}

.tipsChanges{
    margin-right: 10px;
   .ant-btn-edit{
       border: 0 !important;
   }
}

.tipsChanges_{
    margin-right: 10px;
}

.tipsChange_{
    margin-left: 10px;
}

.ChooseEnterpriseTips{
    display: block;
    padding-bottom: 5px;
    color: #333333;
}
.chooseSelect{
    .ant-select-selection__placeholder{
      font-size: 12px !important;
    }
}
.chooseOption.ant-select-dropdown-menu-item-selected{
    color: #F58320;
}
.chooseOption.ant-select-dropdown-menu-item-actived{
    color: #F58320 !important;
}
.chooseSelectModel .ant-modal-body{
    padding: 30px 50px !important;
}
.tagchange{
    border: none !important;
    background:none !important
}
.tablethcenter th{
    text-align: center;
}
.icon-disable:before{
    font-size: 15px ;
}
.icon-detail:before{
    font-size: 15px;
}
.icon-active{
    font-size: 15px;
}
.pd-all{
    padding: 1rem ;
}
.display-i{
    display: inline-block;
    padding-top: 0.6rem;
    min-width: 500px;
}
.spform .ant-form-item{
  margin-bottom: 0 !important;
}
.sp_copy_icon{
    font-size: 20px;
    vertical-align: middle;
    padding-left: 10px;
    cursor:pointer 
}
.spinput{
    color: rgba(0, 0, 0, 0.45) !important;
    width: 80%;
}
.mySaml{
    background: #f8f8f8;
    padding: 1rem;
    max-width: 780px;
}
.max .ant-tooltip-inner{
    max-width: 550px;
}
.c6:before{
   color: #666666 !important; 
}
.display-n{
    display: none !important
}
.SSLCertificate{
    textarea{
        max-width: 300px;
    }
}
.SynchroniseBox{
    display: inline-block;
    opacity: 1 !important;
    color: #333333 !important;
    border: 1px solid #d3d3d3 !important;
    height: 32px!important;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    line-height: 30px;
    margin-left: 10px;
    padding: 0 15px;
    font-size: 14px;
    .ant-select-selection__rendered{
        line-height: 18px !important;
    }
    .ant-select-selection,.ant-time-picker-input{
        background-color: #f5f5f5  !important;
        border: 1px solid #f5f5f5 !important;
    }
    .ant-select-selection--single{
        height: 20px !important;
    }
    .SynchroniseBox_text{
        display: inline-block;
        padding-right: 10px;
    }
    .SynchroniseBox_time{
        display: inline-block;
        margin-left: 10px;
    }
    .ant-time-picker-input{
        height: 20px !important;
        font-size: 12px !important;
    }
    .ant-time-picker-icon{
        font-size: 12px !important;
        margin-top: -5px !important;
    }

}
.SynchroniseMonth{
    width: 210px !important;
    .ant-select-dropdown-menu-item{
        width: 33.33333333%;
        display: inline-block;
        flex-direction: row !important;
    }
}
.SynchroniseBox_ul{
    display: inline-block;
    margin: 0 !important;
    padding: 0 0 0 10px;
    li{
        display: inline-block;
        list-style: none;
        text-decoration: none;
        padding: 0 5px;
        color: #F58320;
        cursor: pointer;
    }
}
.SynchroniseDropdown{
    .ant-select-dropdown-menu-item-selected, .ant-select-dropdown-menu-item-selected:hover{
        color: #F58320 !important;
    }
    li.ant-time-picker-panel-select-option-selected{
        color: #F58320 !important;
    }
}
.ant-time-picker-panel-select-option-selected{
      color: #F58320 !important;
}
.companyName{
    font-size: 12px;
    padding: 0 20px;
    color: #ffffff;
    opacity: 0.8;
}
.BulkOperationTitle{
    display: inline-block;
    padding-left: 10px;
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 12px;
}
.BulkOperationBox{
    background-color: #f9f9f9;
    margin-left: 10px;
    padding: 25px;
    p{
         text-align: center;
         color: #aeaeae;
         font-size: 12px;
    }
}

.dataSelete-tree .ant-tree.ant-tree-show-line li span.ant-tree-switcher{
    background: #fff!important;
}
.fontst{
    font-size: 12px;
    color: #333333;
}
.ant-tree-node-content-wrapper:hover{
    .iconhover{
        display: inline-block !important;
        cursor: pointer;
    }
}
.versionstips{
    font-size: 12px !important;
    color: #333333;
}

.Configuration-row{
    .ant-select-dropdown-menu-item .ant-select-dropdown-menu-item-selected .ant-select-selected-icon{
        color: #F58320 !important;
    }
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
        color: #F58320 !important;
    }
    .ant-select-dropdown-menu-item-selected, .ant-select-dropdown-menu-item-selected:hover{
        color: #F58320 !important;
    }
}
.adminfrom{
    .ant-form-item{
        margin-bottom: 10px !important;
    }
    .ant-form-item-required::before{
        display:none!important;
    }
}

.todefault-from.ant-form-item{
        vertical-align: middle !important;
        .ant-form-item-control{
            line-height: 1 !important;
        }
    }
.min-height-ldap{
    min-height:calc(100vh -  218px)
}
.display_none{
    display: none !important;
}
.moreDrop{
    .ant-dropdown-menu-submenu-arrow{
        display: none !important
    }
}
.labelbyself{
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
}
.labelNew{
    text-align: center;
    // overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
.usersE_.ant-btn-edit{
        border:0 !important
 }
 .resultcss{
    display: inline-block;
    width: 220px;
    font-size: 12px;
    text-align: left;
 }
 .newSearchBox{
    width: 400px;
    position: absolute;
    z-index: 99;
    background: #ffffff;
    box-shadow: 0px 0px 5px 2px #e4e4e4;
    border-radius: 3px;
    margin-top: 1px;
 }
 .TotalCompany{
     position: absolute;
     margin-top: 5px;
     color: #999999;
 }
.prdocutList span{
    display: block;
    padding: 2px;
}
.inputEllipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}