small {
  opacity: 0.6;
}
.text-muted {
  opacity: 0.6;
}
.clear {
  display: block;
  overflow: hidden;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.y-center {
  display: flex;
  align-items: center;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.none {
  display: none;
}
.hidden {
  display: none;
}
.b-white {
  border-color: #ffffff;
}
.w-full {
  width: 100%;
}
.w-500 {
  width: 500px;
}
.w-auto {
  width: auto;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.h-v {
  height: 100vh;
}
.h-v-5 {
  height: 50vh;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}
.line {
  display: inline-block;
  box-sizing: border-box;
  width: 1px;
  height: 100px;
  background: #ffffff;
  margin: 0 24px;
}
.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #F58320;
  border-radius: 50%;
}
.circle.disable {
  background-color: #aaa;
}
.ant-btn-dominant,
.ant-select-dominant {
  background-color: #F58320 !important;
  border-color: #F58320 !important;
  color: #ffffff !important;
}
.ant-btn-dominant .ant-select-arrow,
.ant-select-dominant .ant-select-arrow {
  color: #ffffff !important;
}
.ant-btn-dominant:hover,
.ant-select-dominant:hover {
  background: #ff9a44 !important;
}
.ant-btn-dominant[disabled],
.ant-select-dominant[disabled] {
  background: #fbcda5 !important;
  border: none;
}
.ant-btn-dominant-white,
.ant-select-dominant-white {
  background: #e5e5e5 !important;
  color: #333333 !important;
  border: none!important;
}
.ant-btn-dominant-white .ant-select-arrow,
.ant-select-dominant-white .ant-select-arrow {
  color: #333333 !important;
}
.ant-btn-dominant-white[disabled],
.ant-select-dominant-white[disabled] {
  opacity: 0.5;
}
.ant-btn-dominant-white:hover,
.ant-select-dominant-white:hover {
  background: #dddddd !important;
}
.custom-modal .ant-btn {
  background: #e5e5e5 !important;
  color: #333333 !important;
  border: none!important;
}
.custom-modal .ant-btn .ant-select-arrow {
  color: #333333 !important;
}
.custom-modal .ant-btn[disabled] {
  opacity: 0.5;
}
.custom-modal .ant-btn:hover {
  background: #dddddd !important;
}
.custom-modal .ant-btn-primary {
  background-color: #F58320 !important;
  border-color: #F58320 !important;
  color: #ffffff !important;
}
.custom-modal .ant-btn-primary:hover {
  background: #ff9a44 !important;
}
.custom-modal .ant-btn-primary[disabled] {
  background: #fbcda5 !important;
  border: none;
}
.select-edit .ant-select-selection {
  background-color: #e5e5e5 !important;
  border-color: #d3d3d3 !important;
  color: #333333 !important;
}
.select-edit .ant-select-selection .ant-select-arrow {
  color: #333333 !important;
}
.select-edit .ant-select-selection:hover {
  background: #dddddd !important;
}
.select-edit .ant-select-selection[disabled] {
  background: #fbcda5 !important;
  border: none;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #dddddd !important;
}
.select-edit-item.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #dddddd !important;
}
.ant-btn-transparent {
  color: #F58320 !important;
  background-color: #fff !important;
  border-color: transparent!important;
  box-shadow: none;
}
.ant-btn-transparent[disabled] {
  opacity: 0.5;
}
.ant-select + .ant-select,
.ant-select + .ant-calendar-picker,
.ant-select + .ant-btn {
  margin-left: 10px;
}
.cursor-p {
  cursor: pointer;
}
.color-dominant {
  color: #F58320;
}
.font-bold,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
a.a-hover:hover {
  color: #F58320 !important;
  opacity: 0.85;
}
.title-border-l {
  border-left: 4px solid #F58320;
}
.border-t {
  border-top: 1px solid #e5e5e5;
}
.border-b {
  border-bottom: 1px solid #e5e5e5;
}
.border {
  border: 1px solid #e5e5e5;
}
.space-nowrap {
  white-space: nowrap;
}
.po-re {
  position: relative;
}
.po-ab {
  position: absolute;
  z-index: 10;
}
.ant-btn-edit {
  opacity: 1 !important;
  color: #333333 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #d3d3d3 !important;
  height: 32px!important;
}
.ant-btn-edit [class^="icon-"]:before,
.ant-btn-edit [class*=" icon-"]:before {
  color: #666666 !important;
}
.ant-btn-edit:hover {
  background: #e9e9e9 !important;
  border: 1px solid #999999 !important;
}
.ant-btn-edit[disabled] {
  border: 1px solid #d3d3d3 !important;
  background: #f5f5f5 !important;
  color: #cccccc !important;
}
.ant-btn-edit[disabled] [class^="icon-"]:before,
.ant-btn-edit[disabled] [class*=" icon-"]:before {
  color: #cccccc !important;
}
.Dropdown-self.ant-dropdown-trigger {
  background-color: #e5e5e5 !important;
  border-color: #d3d3d3 !important;
  color: #333333 !important;
  height: 32px !important;
}
.Dropdown-Menu-self .Dropdown-MenuItem-self .ant-upload-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.Dropdown-Menu-self .Dropdown-MenuItem-self:hover {
  background: #f1f1f1 !important;
  color: #F58320 !important;
}
.Dropdown-Menu-self .Dropdown-MenuItem-self:hover .ant-upload {
  color: #F58320 !important;
}
.Dropdown-Menu-self .Dropdown-MenuItem-self:hover .ant-upload-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.Dropdown-Menu-self .Dropdown-MenuItem-self.ant-dropdown-menu-item-disabled:hover {
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.modal-heightLimit-self .ant-modal-body {
  max-height: 60vh;
  overflow-y: scroll;
}
.modal-heightLimit-self .ant-modal-body .height-limit-40vh {
  max-height: 40vh;
  overflow-y: scroll;
}
/***********************************************************************************************************************
d-f -> display: flex;
jc-fs -> justify-content: flex-start;
b-1s -> border: 1px solid;
bc-ddd -> border-color: #ddd;
w-100 -> width: 100px;
w-100z -> width: 100%;
h-100vh -> height: 100vh;
mlmr-10 -> margin-left: 10px; margin-right: 10px;
ptpb-10 -> padding-top: 10px; padding-bottom: 10px;
***********************************************************************************************************************/
/* margin setting start end step */
/* padding setting start end step */
/* border setting start end step */
/* border-radius setting start end step */
/* font-size setting start end step */
/* width setting start end step */
/* height setting start end step */
/* line-height setting start end step */
/* top right bottom left setting start end step */
/* color、background、border-color */
/* color */
/* background */
/* border-color */
/**********************************************************************************************************************/
/* flex */
.fd-c {
  flex-direction: column !important;
}
.fw-w {
  flex-wrap: wrap !important;
}
.jc-fs {
  justify-content: flex-start !important;
}
.jc-c {
  justify-content: center !important;
}
.jc-fe {
  justify-content: flex-end !important;
}
.jc-sb {
  justify-content: space-between !important;
}
.jc-sa {
  justify-content: space-around !important;
}
.ai-fs {
  align-items: flex-start !important;
}
.ai-c {
  align-items: center !important;
}
.ai-fe {
  align-items: flex-end !important;
}
.fg-0 {
  flex-grow: 0 !important;
}
.fg-1 {
  flex-grow: 1 !important;
}
.fg-2 {
  flex-grow: 2 !important;
}
.fg-3 {
  flex-grow: 3 !important;
}
.fg-4 {
  flex-grow: 4 !important;
}
.fg-5 {
  flex-grow: 5 !important;
}
.fs-0 {
  flex-shrink: 0 !important;
}
.fs-1 {
  flex-shrink: 1 !important;
}
.fs-2 {
  flex-shrink: 2 !important;
}
.fs-3 {
  flex-shrink: 3 !important;
}
.fs-4 {
  flex-shrink: 4 !important;
}
.fs-5 {
  flex-shrink: 5 !important;
}
.fb-0 {
  flex-basis: 0 !important;
}
.fb-10z {
  flex-basis: 10% !important;
}
.fb-20z {
  flex-basis: 20% !important;
}
.fb-30z {
  flex-basis: 30% !important;
}
.fb-40z {
  flex-basis: 40% !important;
}
.fb-50z {
  flex-basis: 50% !important;
}
.fb-60z {
  flex-basis: 60% !important;
}
.fb-70z {
  flex-basis: 70% !important;
}
.fb-80z {
  flex-basis: 80% !important;
}
.fb-90z {
  flex-basis: 90% !important;
}
.fb-100z {
  flex-basis: 100% !important;
}
.fb-25z {
  flex-basis: 25% !important;
}
.fb-33z {
  flex-basis: 33.3% !important;
}
.f-0 {
  flex: 0 0 0 !important;
}
.f-1 {
  flex: 1 0 0 !important;
}
.f-2 {
  flex: 2 0 0 !important;
}
.f-3 {
  flex: 3 0 0 !important;
}
.f-4 {
  flex: 4 0 0 !important;
}
.f-5 {
  flex: 5 0 0 !important;
}
.f-6 {
  flex: 6 0 0 !important;
}
.f-7 {
  flex: 7 0 0 !important;
}
.f-8 {
  flex: 8 0 0 !important;
}
.f-9 {
  flex: 9 0 0 !important;
}
/* margin */
.m-a {
  margin: auto !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.mtmb-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.mlmr-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mt-1 {
  margin-top: 1px !important;
}
.mr-1 {
  margin-right: 1px !important;
}
.mb-1 {
  margin-bottom: 1px !important;
}
.ml-1 {
  margin-left: 1px !important;
}
.mtmb-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
.mlmr-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}
.mt-2 {
  margin-top: 2px !important;
}
.mr-2 {
  margin-right: 2px !important;
}
.mb-2 {
  margin-bottom: 2px !important;
}
.ml-2 {
  margin-left: 2px !important;
}
.mtmb-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.mlmr-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.mt-3 {
  margin-top: 3px !important;
}
.mr-3 {
  margin-right: 3px !important;
}
.mb-3 {
  margin-bottom: 3px !important;
}
.ml-3 {
  margin-left: 3px !important;
}
.mtmb-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.mlmr-3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.mtmb-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.mlmr-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mtmb-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.mlmr-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mt-6 {
  margin-top: 6px !important;
}
.mr-6 {
  margin-right: 6px !important;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.ml-6 {
  margin-left: 6px !important;
}
.mtmb-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.mlmr-6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.mt-7 {
  margin-top: 7px !important;
}
.mr-7 {
  margin-right: 7px !important;
}
.mb-7 {
  margin-bottom: 7px !important;
}
.ml-7 {
  margin-left: 7px !important;
}
.mtmb-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}
.mlmr-7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.mtmb-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.mlmr-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mt-9 {
  margin-top: 9px !important;
}
.mr-9 {
  margin-right: 9px !important;
}
.mb-9 {
  margin-bottom: 9px !important;
}
.ml-9 {
  margin-left: 9px !important;
}
.mtmb-9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}
.mlmr-9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mtmb-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.mlmr-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mt-11 {
  margin-top: 11px !important;
}
.mr-11 {
  margin-right: 11px !important;
}
.mb-11 {
  margin-bottom: 11px !important;
}
.ml-11 {
  margin-left: 11px !important;
}
.mtmb-11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}
.mlmr-11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mr-12 {
  margin-right: 12px !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.ml-12 {
  margin-left: 12px !important;
}
.mtmb-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.mlmr-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.mt-13 {
  margin-top: 13px !important;
}
.mr-13 {
  margin-right: 13px !important;
}
.mb-13 {
  margin-bottom: 13px !important;
}
.ml-13 {
  margin-left: 13px !important;
}
.mtmb-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}
.mlmr-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}
.mt-14 {
  margin-top: 14px !important;
}
.mr-14 {
  margin-right: 14px !important;
}
.mb-14 {
  margin-bottom: 14px !important;
}
.ml-14 {
  margin-left: 14px !important;
}
.mtmb-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}
.mlmr-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mtmb-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.mlmr-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mr-16 {
  margin-right: 16px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.ml-16 {
  margin-left: 16px !important;
}
.mtmb-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.mlmr-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mt-17 {
  margin-top: 17px !important;
}
.mr-17 {
  margin-right: 17px !important;
}
.mb-17 {
  margin-bottom: 17px !important;
}
.ml-17 {
  margin-left: 17px !important;
}
.mtmb-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}
.mlmr-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}
.mt-18 {
  margin-top: 18px !important;
}
.mr-18 {
  margin-right: 18px !important;
}
.mb-18 {
  margin-bottom: 18px !important;
}
.ml-18 {
  margin-left: 18px !important;
}
.mtmb-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}
.mlmr-18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}
.mt-19 {
  margin-top: 19px !important;
}
.mr-19 {
  margin-right: 19px !important;
}
.mb-19 {
  margin-bottom: 19px !important;
}
.ml-19 {
  margin-left: 19px !important;
}
.mtmb-19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}
.mlmr-19 {
  margin-left: 19px !important;
  margin-right: 19px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mtmb-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mlmr-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.mtmb-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.mlmr-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.mtmb-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.mlmr-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.ml-35 {
  margin-left: 35px !important;
}
.mtmb-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
.mlmr-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mtmb-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.mlmr-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mr-45 {
  margin-right: 45px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.ml-45 {
  margin-left: 45px !important;
}
.mtmb-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
.mlmr-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.mtmb-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.mlmr-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}
.mt-55 {
  margin-top: 55px !important;
}
.mr-55 {
  margin-right: 55px !important;
}
.mb-55 {
  margin-bottom: 55px !important;
}
.ml-55 {
  margin-left: 55px !important;
}
.mtmb-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}
.mlmr-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.mtmb-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.mlmr-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}
/* padding */
.p-a {
  padding: auto !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.ptpb-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.plpr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.pt-1 {
  padding-top: 1px !important;
}
.pr-1 {
  padding-right: 1px !important;
}
.pb-1 {
  padding-bottom: 1px !important;
}
.pl-1 {
  padding-left: 1px !important;
}
.ptpb-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.plpr-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.pt-2 {
  padding-top: 2px !important;
}
.pr-2 {
  padding-right: 2px !important;
}
.pb-2 {
  padding-bottom: 2px !important;
}
.pl-2 {
  padding-left: 2px !important;
}
.ptpb-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.plpr-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.pt-3 {
  padding-top: 3px !important;
}
.pr-3 {
  padding-right: 3px !important;
}
.pb-3 {
  padding-bottom: 3px !important;
}
.pl-3 {
  padding-left: 3px !important;
}
.ptpb-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.plpr-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.pt-4 {
  padding-top: 4px !important;
}
.pr-4 {
  padding-right: 4px !important;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.pl-4 {
  padding-left: 4px !important;
}
.ptpb-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.plpr-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.ptpb-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.plpr-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.pt-6 {
  padding-top: 6px !important;
}
.pr-6 {
  padding-right: 6px !important;
}
.pb-6 {
  padding-bottom: 6px !important;
}
.pl-6 {
  padding-left: 6px !important;
}
.ptpb-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.plpr-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.pt-7 {
  padding-top: 7px !important;
}
.pr-7 {
  padding-right: 7px !important;
}
.pb-7 {
  padding-bottom: 7px !important;
}
.pl-7 {
  padding-left: 7px !important;
}
.ptpb-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.plpr-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}
.pt-8 {
  padding-top: 8px !important;
}
.pr-8 {
  padding-right: 8px !important;
}
.pb-8 {
  padding-bottom: 8px !important;
}
.pl-8 {
  padding-left: 8px !important;
}
.ptpb-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.plpr-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.pt-9 {
  padding-top: 9px !important;
}
.pr-9 {
  padding-right: 9px !important;
}
.pb-9 {
  padding-bottom: 9px !important;
}
.pl-9 {
  padding-left: 9px !important;
}
.ptpb-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.plpr-9 {
  padding-left: 9px !important;
  padding-right: 9px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.ptpb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.plpr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.pt-11 {
  padding-top: 11px !important;
}
.pr-11 {
  padding-right: 11px !important;
}
.pb-11 {
  padding-bottom: 11px !important;
}
.pl-11 {
  padding-left: 11px !important;
}
.ptpb-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}
.plpr-11 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}
.pt-12 {
  padding-top: 12px !important;
}
.pr-12 {
  padding-right: 12px !important;
}
.pb-12 {
  padding-bottom: 12px !important;
}
.pl-12 {
  padding-left: 12px !important;
}
.ptpb-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.plpr-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.pt-13 {
  padding-top: 13px !important;
}
.pr-13 {
  padding-right: 13px !important;
}
.pb-13 {
  padding-bottom: 13px !important;
}
.pl-13 {
  padding-left: 13px !important;
}
.ptpb-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.plpr-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}
.pt-14 {
  padding-top: 14px !important;
}
.pr-14 {
  padding-right: 14px !important;
}
.pb-14 {
  padding-bottom: 14px !important;
}
.pl-14 {
  padding-left: 14px !important;
}
.ptpb-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.plpr-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.ptpb-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.plpr-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.pt-16 {
  padding-top: 16px !important;
}
.pr-16 {
  padding-right: 16px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.pl-16 {
  padding-left: 16px !important;
}
.ptpb-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.plpr-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.pt-17 {
  padding-top: 17px !important;
}
.pr-17 {
  padding-right: 17px !important;
}
.pb-17 {
  padding-bottom: 17px !important;
}
.pl-17 {
  padding-left: 17px !important;
}
.ptpb-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}
.plpr-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}
.pt-18 {
  padding-top: 18px !important;
}
.pr-18 {
  padding-right: 18px !important;
}
.pb-18 {
  padding-bottom: 18px !important;
}
.pl-18 {
  padding-left: 18px !important;
}
.ptpb-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.plpr-18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
.pt-19 {
  padding-top: 19px !important;
}
.pr-19 {
  padding-right: 19px !important;
}
.pb-19 {
  padding-bottom: 19px !important;
}
.pl-19 {
  padding-left: 19px !important;
}
.ptpb-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}
.plpr-19 {
  padding-left: 19px !important;
  padding-right: 19px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.ptpb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.plpr-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.ptpb-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.plpr-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.ptpb-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.plpr-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.pt-35 {
  padding-top: 35px !important;
}
.pr-35 {
  padding-right: 35px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
.pl-35 {
  padding-left: 35px !important;
}
.ptpb-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.plpr-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.ptpb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.plpr-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pr-45 {
  padding-right: 45px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pl-45 {
  padding-left: 45px !important;
}
.ptpb-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.plpr-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.ptpb-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.plpr-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.pt-55 {
  padding-top: 55px !important;
}
.pr-55 {
  padding-right: 55px !important;
}
.pb-55 {
  padding-bottom: 55px !important;
}
.pl-55 {
  padding-left: 55px !important;
}
.ptpb-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}
.plpr-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pr-60 {
  padding-right: 60px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pl-60 {
  padding-left: 60px !important;
}
.ptpb-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.plpr-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
/* border */
.b-n {
  border: none !important;
}
.b-1s {
  border: 1px solid !important;
}
.bt-1s {
  border-top: 1px solid !important;
}
.br-1s {
  border-right: 1px solid !important;
}
.bb-1s {
  border-bottom: 1px solid !important;
}
.bl-1s {
  border-left: 1px solid !important;
}
.b-2s {
  border: 2px solid !important;
}
.bt-2s {
  border-top: 2px solid !important;
}
.br-2s {
  border-right: 2px solid !important;
}
.bb-2s {
  border-bottom: 2px solid !important;
}
.bl-2s {
  border-left: 2px solid !important;
}
.b-3s {
  border: 3px solid !important;
}
.bt-3s {
  border-top: 3px solid !important;
}
.br-3s {
  border-right: 3px solid !important;
}
.bb-3s {
  border-bottom: 3px solid !important;
}
.bl-3s {
  border-left: 3px solid !important;
}
.b-1d {
  border: 1px dashed !important;
}
.bt-1d {
  border-top: 1px dashed !important;
}
.br-1d {
  border-right: 1px dashed !important;
}
.bb-1d {
  border-bottom: 1px dashed !important;
}
.bl-1d {
  border-left: 1px dashed !important;
}
.b-2d {
  border: 2px dashed !important;
}
.bt-2d {
  border-top: 2px dashed !important;
}
.br-2d {
  border-right: 2px dashed !important;
}
.bb-2d {
  border-bottom: 2px dashed !important;
}
.bl-2d {
  border-left: 2px dashed !important;
}
.b-3d {
  border: 3px dashed !important;
}
.bt-3d {
  border-top: 3px dashed !important;
}
.br-3d {
  border-right: 3px dashed !important;
}
.bb-3d {
  border-bottom: 3px dashed !important;
}
.bl-3d {
  border-left: 3px dashed !important;
}
/* border-radius */
.br-50z {
  border-radius: 50% !important;
}
.br-100z {
  border-radius: 100% !important;
}
.br-999 {
  border-radius: 999px !important;
}
.br-0 {
  border-radius: 0px !important;
}
.br-1 {
  border-radius: 1px !important;
}
.br-2 {
  border-radius: 2px !important;
}
.br-3 {
  border-radius: 3px !important;
}
.br-4 {
  border-radius: 4px !important;
}
.br-5 {
  border-radius: 5px !important;
}
.br-6 {
  border-radius: 6px !important;
}
.br-7 {
  border-radius: 7px !important;
}
.br-8 {
  border-radius: 8px !important;
}
.br-9 {
  border-radius: 9px !important;
}
.br-10 {
  border-radius: 10px !important;
}
.br-11 {
  border-radius: 11px !important;
}
.br-12 {
  border-radius: 12px !important;
}
.br-13 {
  border-radius: 13px !important;
}
.br-14 {
  border-radius: 14px !important;
}
.br-15 {
  border-radius: 15px !important;
}
.br-16 {
  border-radius: 16px !important;
}
.br-17 {
  border-radius: 17px !important;
}
.br-18 {
  border-radius: 18px !important;
}
.br-19 {
  border-radius: 19px !important;
}
.br-20 {
  border-radius: 20px !important;
}
.br-25 {
  border-radius: 25px !important;
}
.br-30 {
  border-radius: 30px !important;
}
.br-35 {
  border-radius: 35px !important;
}
.br-40 {
  border-radius: 40px !important;
}
.br-45 {
  border-radius: 45px !important;
}
.br-50 {
  border-radius: 50px !important;
}
.br-55 {
  border-radius: 55px !important;
}
.br-60 {
  border-radius: 60px !important;
}
/* border-color */
.bc-t {
  border-color: transparent !important;
}
.bc-000 {
  border-color: #000 !important;
}
.bc-111 {
  border-color: #111 !important;
}
.bc-222 {
  border-color: #222 !important;
}
.bc-333 {
  border-color: #333 !important;
}
.bc-444 {
  border-color: #444 !important;
}
.bc-555 {
  border-color: #555 !important;
}
.bc-666 {
  border-color: #666 !important;
}
.bc-777 {
  border-color: #777 !important;
}
.bc-888 {
  border-color: #888 !important;
}
.bc-999 {
  border-color: #999 !important;
}
.bc-aaa {
  border-color: #aaa !important;
}
.bc-bbb {
  border-color: #bbb !important;
}
.bc-ccc {
  border-color: #ccc !important;
}
.bc-ddd {
  border-color: #ddd !important;
}
.bc-eee {
  border-color: #eee !important;
}
.bc-fff {
  border-color: #fff !important;
}
.bc-primary {
  border-color: #F58320 !important;
}
.bc-red {
  border-color: #fb7878 !important;
}
.bc-green {
  border-color: #4DDC8A !important;
}
.bc-blue {
  border-color: #4cb3ff !important;
}
.bc-search {
  border-color: #454545 !important;
}
/* color */
.c-t {
  color: transparent !important;
}
.c-000 {
  color: #000 !important;
}
.c-111 {
  color: #111 !important;
}
.c-222 {
  color: #222 !important;
}
.c-333 {
  color: #333 !important;
}
.c-444 {
  color: #444 !important;
}
.c-555 {
  color: #555 !important;
}
.c-666 {
  color: #666 !important;
}
.c-777 {
  color: #777 !important;
}
.c-888 {
  color: #888 !important;
}
.c-999 {
  color: #999 !important;
}
.c-aaa {
  color: #aaa !important;
}
.c-bbb {
  color: #bbb !important;
}
.c-ccc {
  color: #ccc !important;
}
.c-ddd {
  color: #ddd !important;
}
.c-eee {
  color: #eee !important;
}
.c-fff {
  color: #fff !important;
}
.c-primary {
  color: #F58320 !important;
}
.c-red {
  color: #fb7878 !important;
}
.c-green {
  color: #4DDC8A !important;
}
.c-blue {
  color: #4cb3ff !important;
}
.c-error {
  color: #e12b2b !important;
}
/* background */
.b-t {
  background: transparent !important;
}
.b-000 {
  background: #000 !important;
}
.b-111 {
  background: #111 !important;
}
.b-222 {
  background: #222 !important;
}
.b-333 {
  background: #333 !important;
}
.b-444 {
  background: #444 !important;
}
.b-555 {
  background: #555 !important;
}
.b-666 {
  background: #666 !important;
}
.b-777 {
  background: #777 !important;
}
.b-888 {
  background: #888 !important;
}
.b-999 {
  background: #999 !important;
}
.b-aaa {
  background: #aaa !important;
}
.b-bbb {
  background: #bbb !important;
}
.b-ccc {
  background: #ccc !important;
}
.b-ddd {
  background: #ddd !important;
}
.b-eee {
  background: #eee !important;
}
.b-fff {
  background: #fff !important;
}
.b-primary {
  background: #F58320 !important;
}
.b-red {
  background: #fb7878 !important;
}
.b-green {
  background: #4DDC8A !important;
}
.b-blue {
  background: #4cb3ff !important;
}
.b-body {
  background: #f4f4f4 !important;
}
/* font-size */
.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-27 {
  font-size: 27px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-29 {
  font-size: 29px !important;
}
.fs-30 {
  font-size: 30px !important;
}
/* font-weight */
.fw-n {
  font-weight: normal !important;
}
.fw-b {
  font-weight: bold !important;
}
.fw-l {
  font-weight: lighter !important;
}
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
/* font-style */
.fs-n {
  font-style: normal !important;
}
.fs-i {
  font-style: italic !important;
}
/* width */
.w-a {
  width: auto !important;
}
.w-fc {
  width: fit-content !important;
}
.w-minc {
  width: min-content !important;
}
.w-maxc {
  width: max-content !important;
}
.w-10z {
  width: 10% !important;
}
.w-10vw {
  width: 10vw !important;
}
.w-20z {
  width: 20% !important;
}
.w-20vw {
  width: 20vw !important;
}
.w-30z {
  width: 30% !important;
}
.w-30vw {
  width: 30vw !important;
}
.w-40z {
  width: 40% !important;
}
.w-40vw {
  width: 40vw !important;
}
.w-50z {
  width: 50% !important;
}
.w-50vw {
  width: 50vw !important;
}
.w-60z {
  width: 60% !important;
}
.w-60vw {
  width: 60vw !important;
}
.w-70z {
  width: 70% !important;
}
.w-70vw {
  width: 70vw !important;
}
.w-80z {
  width: 80% !important;
}
.w-80vw {
  width: 80vw !important;
}
.w-90z {
  width: 90% !important;
}
.w-90vw {
  width: 90vw !important;
}
.w-100z {
  width: 100% !important;
}
.w-100vw {
  width: 100vw !important;
}
.w-0 {
  width: 0px !important;
}
.w-1 {
  width: 1px !important;
}
.w-2 {
  width: 2px !important;
}
.w-3 {
  width: 3px !important;
}
.w-4 {
  width: 4px !important;
}
.w-5 {
  width: 5px !important;
}
.w-6 {
  width: 6px !important;
}
.w-7 {
  width: 7px !important;
}
.w-8 {
  width: 8px !important;
}
.w-9 {
  width: 9px !important;
}
.w-10 {
  width: 10px !important;
}
.w-11 {
  width: 11px !important;
}
.w-12 {
  width: 12px !important;
}
.w-13 {
  width: 13px !important;
}
.w-14 {
  width: 14px !important;
}
.w-15 {
  width: 15px !important;
}
.w-16 {
  width: 16px !important;
}
.w-17 {
  width: 17px !important;
}
.w-18 {
  width: 18px !important;
}
.w-19 {
  width: 19px !important;
}
.w-20 {
  width: 20px !important;
}
.w-25 {
  width: 25px !important;
}
.w-30 {
  width: 30px !important;
}
.w-35 {
  width: 35px !important;
}
.w-40 {
  width: 40px !important;
}
.w-45 {
  width: 45px !important;
}
.w-50 {
  width: 50px !important;
}
.w-55 {
  width: 55px !important;
}
.w-60 {
  width: 60px !important;
}
.w-65 {
  width: 65px !important;
}
.w-70 {
  width: 70px !important;
}
.w-75 {
  width: 75px !important;
}
.w-80 {
  width: 80px !important;
}
.w-85 {
  width: 85px !important;
}
.w-90 {
  width: 90px !important;
}
.w-95 {
  width: 95px !important;
}
.w-100 {
  width: 100px !important;
}
.w-110 {
  width: 110px !important;
}
.w-120 {
  width: 120px !important;
}
.w-130 {
  width: 130px !important;
}
.w-140 {
  width: 140px !important;
}
.w-150 {
  width: 150px !important;
}
.w-160 {
  width: 160px !important;
}
.w-170 {
  width: 170px !important;
}
.w-180 {
  width: 180px !important;
}
.w-190 {
  width: 190px !important;
}
.w-200 {
  width: 200px !important;
}
.w-210 {
  width: 210px !important;
}
.w-220 {
  width: 220px !important;
}
.w-230 {
  width: 230px !important;
}
.w-240 {
  width: 240px !important;
}
.w-250 {
  width: 250px !important;
}
.w-260 {
  width: 260px !important;
}
.w-270 {
  width: 270px !important;
}
.w-280 {
  width: 280px !important;
}
.w-290 {
  width: 290px !important;
}
.w-300 {
  width: 300px !important;
}
/* height */
.h-a {
  height: auto !important;
}
.h-fc {
  height: fit-content !important;
}
.h-minc {
  height: min-content !important;
}
.h-maxc {
  height: max-content !important;
}
.h-10z {
  height: 10% !important;
}
.h-10vh {
  height: 10vh !important;
}
.h-20z {
  height: 20% !important;
}
.h-20vh {
  height: 20vh !important;
}
.h-30z {
  height: 30% !important;
}
.h-30vh {
  height: 30vh !important;
}
.h-40z {
  height: 40% !important;
}
.h-40vh {
  height: 40vh !important;
}
.h-50z {
  height: 50% !important;
}
.h-50vh {
  height: 50vh !important;
}
.h-60z {
  height: 60% !important;
}
.h-60vh {
  height: 60vh !important;
}
.h-70z {
  height: 70% !important;
}
.h-70vh {
  height: 70vh !important;
}
.h-80z {
  height: 80% !important;
}
.h-80vh {
  height: 80vh !important;
}
.h-90z {
  height: 90% !important;
}
.h-90vh {
  height: 90vh !important;
}
.h-100z {
  height: 100% !important;
}
.h-100vh {
  height: 100vh !important;
}
.h-0 {
  height: 0px !important;
}
.h-1 {
  height: 1px !important;
}
.h-2 {
  height: 2px !important;
}
.h-3 {
  height: 3px !important;
}
.h-4 {
  height: 4px !important;
}
.h-5 {
  height: 5px !important;
}
.h-6 {
  height: 6px !important;
}
.h-7 {
  height: 7px !important;
}
.h-8 {
  height: 8px !important;
}
.h-9 {
  height: 9px !important;
}
.h-10 {
  height: 10px !important;
}
.h-11 {
  height: 11px !important;
}
.h-12 {
  height: 12px !important;
}
.h-13 {
  height: 13px !important;
}
.h-14 {
  height: 14px !important;
}
.h-15 {
  height: 15px !important;
}
.h-16 {
  height: 16px !important;
}
.h-17 {
  height: 17px !important;
}
.h-18 {
  height: 18px !important;
}
.h-19 {
  height: 19px !important;
}
.h-20 {
  height: 20px !important;
}
.h-25 {
  height: 25px !important;
}
.h-30 {
  height: 30px !important;
}
.h-35 {
  height: 35px !important;
}
.h-40 {
  height: 40px !important;
}
.h-45 {
  height: 45px !important;
}
.h-50 {
  height: 50px !important;
}
.h-55 {
  height: 55px !important;
}
.h-60 {
  height: 60px !important;
}
.h-65 {
  height: 65px !important;
}
.h-70 {
  height: 70px !important;
}
.h-75 {
  height: 75px !important;
}
.h-80 {
  height: 80px !important;
}
.h-85 {
  height: 85px !important;
}
.h-90 {
  height: 90px !important;
}
.h-95 {
  height: 95px !important;
}
.h-100 {
  height: 100px !important;
}
.h-110 {
  height: 110px !important;
}
.h-120 {
  height: 120px !important;
}
.h-130 {
  height: 130px !important;
}
.h-140 {
  height: 140px !important;
}
.h-150 {
  height: 150px !important;
}
.h-160 {
  height: 160px !important;
}
.h-170 {
  height: 170px !important;
}
.h-180 {
  height: 180px !important;
}
.h-190 {
  height: 190px !important;
}
.h-200 {
  height: 200px !important;
}
.h-210 {
  height: 210px !important;
}
.h-220 {
  height: 220px !important;
}
.h-230 {
  height: 230px !important;
}
.h-240 {
  height: 240px !important;
}
.h-250 {
  height: 250px !important;
}
.h-260 {
  height: 260px !important;
}
.h-270 {
  height: 270px !important;
}
.h-280 {
  height: 280px !important;
}
.h-290 {
  height: 290px !important;
}
.h-300 {
  height: 300px !important;
}
/* line-height */
.lh-n {
  line-height: normal !important;
}
.lh-10z {
  line-height: 10% !important;
}
.lh-10vh {
  line-height: 10vh !important;
}
.lh-20z {
  line-height: 20% !important;
}
.lh-20vh {
  line-height: 20vh !important;
}
.lh-30z {
  line-height: 30% !important;
}
.lh-30vh {
  line-height: 30vh !important;
}
.lh-40z {
  line-height: 40% !important;
}
.lh-40vh {
  line-height: 40vh !important;
}
.lh-50z {
  line-height: 50% !important;
}
.lh-50vh {
  line-height: 50vh !important;
}
.lh-60z {
  line-height: 60% !important;
}
.lh-60vh {
  line-height: 60vh !important;
}
.lh-70z {
  line-height: 70% !important;
}
.lh-70vh {
  line-height: 70vh !important;
}
.lh-80z {
  line-height: 80% !important;
}
.lh-80vh {
  line-height: 80vh !important;
}
.lh-90z {
  line-height: 90% !important;
}
.lh-90vh {
  line-height: 90vh !important;
}
.lh-100z {
  line-height: 100% !important;
}
.lh-100vh {
  line-height: 100vh !important;
}
.lh-0 {
  line-height: 0px !important;
}
.lh-1 {
  line-height: 1px !important;
}
.lh-2 {
  line-height: 2px !important;
}
.lh-3 {
  line-height: 3px !important;
}
.lh-4 {
  line-height: 4px !important;
}
.lh-5 {
  line-height: 5px !important;
}
.lh-6 {
  line-height: 6px !important;
}
.lh-7 {
  line-height: 7px !important;
}
.lh-8 {
  line-height: 8px !important;
}
.lh-9 {
  line-height: 9px !important;
}
.lh-10 {
  line-height: 10px !important;
}
.lh-11 {
  line-height: 11px !important;
}
.lh-12 {
  line-height: 12px !important;
}
.lh-13 {
  line-height: 13px !important;
}
.lh-14 {
  line-height: 14px !important;
}
.lh-15 {
  line-height: 15px !important;
}
.lh-16 {
  line-height: 16px !important;
}
.lh-17 {
  line-height: 17px !important;
}
.lh-18 {
  line-height: 18px !important;
}
.lh-19 {
  line-height: 19px !important;
}
.lh-20 {
  line-height: 20px !important;
}
.lh-25 {
  line-height: 25px !important;
}
.lh-30 {
  line-height: 30px !important;
}
.lh-35 {
  line-height: 35px !important;
}
.lh-40 {
  line-height: 40px !important;
}
.lh-45 {
  line-height: 45px !important;
}
.lh-50 {
  line-height: 50px !important;
}
.lh-55 {
  line-height: 55px !important;
}
.lh-60 {
  line-height: 60px !important;
}
.lh-65 {
  line-height: 65px !important;
}
.lh-70 {
  line-height: 70px !important;
}
.lh-75 {
  line-height: 75px !important;
}
.lh-80 {
  line-height: 80px !important;
}
.lh-85 {
  line-height: 85px !important;
}
.lh-90 {
  line-height: 90px !important;
}
.lh-95 {
  line-height: 95px !important;
}
.lh-100 {
  line-height: 100px !important;
}
.lh-110 {
  line-height: 110px !important;
}
.lh-120 {
  line-height: 120px !important;
}
.lh-130 {
  line-height: 130px !important;
}
.lh-140 {
  line-height: 140px !important;
}
.lh-150 {
  line-height: 150px !important;
}
.lh-160 {
  line-height: 160px !important;
}
.lh-170 {
  line-height: 170px !important;
}
.lh-180 {
  line-height: 180px !important;
}
.lh-190 {
  line-height: 190px !important;
}
.lh-200 {
  line-height: 200px !important;
}
.lh-210 {
  line-height: 210px !important;
}
.lh-220 {
  line-height: 220px !important;
}
.lh-230 {
  line-height: 230px !important;
}
.lh-240 {
  line-height: 240px !important;
}
.lh-250 {
  line-height: 250px !important;
}
.lh-260 {
  line-height: 260px !important;
}
.lh-270 {
  line-height: 270px !important;
}
.lh-280 {
  line-height: 280px !important;
}
.lh-290 {
  line-height: 290px !important;
}
.lh-300 {
  line-height: 300px !important;
}
/* display */
.d-f {
  display: flex !important;
}
.d-i {
  display: inline !important;
}
.d-ib {
  display: inline-block !important;
}
.d-b {
  display: block !important;
}
.d-n {
  display: none !important;
}
/* position */
.p-a {
  position: absolute !important;
}
.p-r {
  position: relative !important;
}
.p-sti {
  position: sticky !important;
}
.p-sta {
  position: static !important;
}
.p-f {
  position: fixed !important;
}
/* top right bottom left */
.t-0 {
  top: 0px !important;
}
.r-0 {
  right: 0px !important;
}
.b-0 {
  bottom: 0px !important;
}
.l-0 {
  left: 0px !important;
}
.t-1 {
  top: 1px !important;
}
.r-1 {
  right: 1px !important;
}
.b-1 {
  bottom: 1px !important;
}
.l-1 {
  left: 1px !important;
}
.t-2 {
  top: 2px !important;
}
.r-2 {
  right: 2px !important;
}
.b-2 {
  bottom: 2px !important;
}
.l-2 {
  left: 2px !important;
}
.t-3 {
  top: 3px !important;
}
.r-3 {
  right: 3px !important;
}
.b-3 {
  bottom: 3px !important;
}
.l-3 {
  left: 3px !important;
}
.t-4 {
  top: 4px !important;
}
.r-4 {
  right: 4px !important;
}
.b-4 {
  bottom: 4px !important;
}
.l-4 {
  left: 4px !important;
}
.t-5 {
  top: 5px !important;
}
.r-5 {
  right: 5px !important;
}
.b-5 {
  bottom: 5px !important;
}
.l-5 {
  left: 5px !important;
}
.t-6 {
  top: 6px !important;
}
.r-6 {
  right: 6px !important;
}
.b-6 {
  bottom: 6px !important;
}
.l-6 {
  left: 6px !important;
}
.t-7 {
  top: 7px !important;
}
.r-7 {
  right: 7px !important;
}
.b-7 {
  bottom: 7px !important;
}
.l-7 {
  left: 7px !important;
}
.t-8 {
  top: 8px !important;
}
.r-8 {
  right: 8px !important;
}
.b-8 {
  bottom: 8px !important;
}
.l-8 {
  left: 8px !important;
}
.t-9 {
  top: 9px !important;
}
.r-9 {
  right: 9px !important;
}
.b-9 {
  bottom: 9px !important;
}
.l-9 {
  left: 9px !important;
}
.t-10 {
  top: 10px !important;
}
.r-10 {
  right: 10px !important;
}
.b-10 {
  bottom: 10px !important;
}
.l-10 {
  left: 10px !important;
}
.t-11 {
  top: 11px !important;
}
.r-11 {
  right: 11px !important;
}
.b-11 {
  bottom: 11px !important;
}
.l-11 {
  left: 11px !important;
}
.t-12 {
  top: 12px !important;
}
.r-12 {
  right: 12px !important;
}
.b-12 {
  bottom: 12px !important;
}
.l-12 {
  left: 12px !important;
}
.t-13 {
  top: 13px !important;
}
.r-13 {
  right: 13px !important;
}
.b-13 {
  bottom: 13px !important;
}
.l-13 {
  left: 13px !important;
}
.t-14 {
  top: 14px !important;
}
.r-14 {
  right: 14px !important;
}
.b-14 {
  bottom: 14px !important;
}
.l-14 {
  left: 14px !important;
}
.t-15 {
  top: 15px !important;
}
.r-15 {
  right: 15px !important;
}
.b-15 {
  bottom: 15px !important;
}
.l-15 {
  left: 15px !important;
}
.t-16 {
  top: 16px !important;
}
.r-16 {
  right: 16px !important;
}
.b-16 {
  bottom: 16px !important;
}
.l-16 {
  left: 16px !important;
}
.t-17 {
  top: 17px !important;
}
.r-17 {
  right: 17px !important;
}
.b-17 {
  bottom: 17px !important;
}
.l-17 {
  left: 17px !important;
}
.t-18 {
  top: 18px !important;
}
.r-18 {
  right: 18px !important;
}
.b-18 {
  bottom: 18px !important;
}
.l-18 {
  left: 18px !important;
}
.t-19 {
  top: 19px !important;
}
.r-19 {
  right: 19px !important;
}
.b-19 {
  bottom: 19px !important;
}
.l-19 {
  left: 19px !important;
}
.t-20 {
  top: 20px !important;
}
.r-20 {
  right: 20px !important;
}
.b-20 {
  bottom: 20px !important;
}
.l-20 {
  left: 20px !important;
}
.t-25 {
  top: 25px !important;
}
.r-25 {
  right: 25px !important;
}
.b-25 {
  bottom: 25px !important;
}
.l-25 {
  left: 25px !important;
}
.t-30 {
  top: 30px !important;
}
.r-30 {
  right: 30px !important;
}
.b-30 {
  bottom: 30px !important;
}
.l-30 {
  left: 30px !important;
}
.t-35 {
  top: 35px !important;
}
.r-35 {
  right: 35px !important;
}
.b-35 {
  bottom: 35px !important;
}
.l-35 {
  left: 35px !important;
}
.t-40 {
  top: 40px !important;
}
.r-40 {
  right: 40px !important;
}
.b-40 {
  bottom: 40px !important;
}
.l-40 {
  left: 40px !important;
}
.t-45 {
  top: 45px !important;
}
.r-45 {
  right: 45px !important;
}
.b-45 {
  bottom: 45px !important;
}
.l-45 {
  left: 45px !important;
}
.t-50 {
  top: 50px !important;
}
.r-50 {
  right: 50px !important;
}
.b-50 {
  bottom: 50px !important;
}
.l-50 {
  left: 50px !important;
}
.t-55 {
  top: 55px !important;
}
.r-55 {
  right: 55px !important;
}
.b-55 {
  bottom: 55px !important;
}
.l-55 {
  left: 55px !important;
}
.t-60 {
  top: 60px !important;
}
.r-60 {
  right: 60px !important;
}
.b-60 {
  bottom: 60px !important;
}
.l-60 {
  left: 60px !important;
}
/* opacity */
.o-0 {
  opacity: 0 !important;
}
.o-1 {
  opacity: 1 !important;
}
.o-01 {
  opacity: 0.1 !important;
}
.o-02 {
  opacity: 0.2 !important;
}
.o-03 {
  opacity: 0.3 !important;
}
.o-04 {
  opacity: 0.4 !important;
}
.o-05 {
  opacity: 0.5 !important;
}
.o-06 {
  opacity: 0.6 !important;
}
.o-07 {
  opacity: 0.7 !important;
}
.o-08 {
  opacity: 0.8 !important;
}
.o-09 {
  opacity: 0.9 !important;
}
/* text-align */
.ta-l {
  text-align: left !important;
}
.ta-c {
  text-align: center !important;
}
.ta-r {
  text-align: right !important;
}
.ta-s {
  text-align: start !important;
}
.ta-e {
  text-align: end !important;
}
/* float */
.f-n {
  float: none !important;
}
.f-l {
  float: left !important;
}
.f-r {
  float: right !important;
}
/* vertical-align */
.va-t {
  vertical-align: top !important;
}
.va-m {
  vertical-align: middle !important;
}
.va-b {
  vertical-align: bottom !important;
}
/* overflow */
.o-h {
  overflow: hidden !important;
}
.o-a {
  overflow: auto !important;
}
.o-v {
  overflow: visible !important;
}
.ox-h {
  overflow-x: hidden !important;
}
.oy-h {
  overflow-y: hidden !important;
}
.ox-s {
  overflow-x: scroll !important;
}
.oy-s {
  overflow-y: scroll !important;
}
/* z-index */
.zi-a {
  z-index: auto !important;
}
.zi-0 {
  z-index: 0 !important;
}
.zi-1 {
  z-index: 1 !important;
}
.zi-2 {
  z-index: 2 !important;
}
.zi-3 {
  z-index: 3 !important;
}
.zi-4 {
  z-index: 4 !important;
}
.zi-5 {
  z-index: 5 !important;
}
.zi-6 {
  z-index: 6 !important;
}
.zi-7 {
  z-index: 7 !important;
}
.zi-8 {
  z-index: 8 !important;
}
.zi-9 {
  z-index: 9 !important;
}
.zi-10 {
  z-index: 10 !important;
}
/* other */
.ws-n {
  white-space: nowrap !important;
}
.to-e {
  text-overflow: ellipsis !important;
}
.to-c {
  text-overflow: clip !important;
}
.ww-bw {
  word-wrap: break-word !important;
}
.wb-ba {
  word-break: break-all !important;
}
.v-h {
  visibility: hidden !important;
}
.c-b {
  clear: both !important;
}
.c-p {
  cursor: pointer !important;
}
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
::-webkit-scrollbar-thumb {
  background-color: none;
}
.table-operations {
  margin-bottom: 16px;
}
.table-operations > button {
  margin-right: 8px;
}
.editable-row-text {
  padding: 5px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.pb {
  display: block !important;
  padding-bottom: 10px;
}
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  height: 100vh;
  background: #F58320;
  overflow: hidden;
}
@media (max-width: 1366px) {
  .login .login-wrap-wrap {
    transform: scale(0.7);
  }
  .login .login-wrap-wrap .scale_1 {
    transform: scale(1.2) translateX(26px);
  }
  .login .login-wrap-wrap .scale_1 .item_email {
    transform: translateY(10px);
  }
}
@media screen and (min-width: 1366px) and (max-width: 1919px) {
  .login .login-wrap-wrap {
    transform: scale(0.75);
  }
  .login .login-wrap-wrap .scale_1 {
    transform: scale(1.2) translateX(26px);
  }
  .login .login-wrap-wrap .scale_1 .item_email {
    transform: translateY(15px);
  }
}
.login .login-wrap-wrap {
  position: relative;
  width: 1306px;
  height: 783px;
  margin-bottom: -28px;
}
.login .login-wrap-wrap .line_1 {
  width: 100vw;
  height: 100vmax;
  background: #ff9100;
  position: absolute;
  left: 385px;
  top: 429px;
  -webkit-transform: rotate(-34deg) skewY(45deg);
  transform: rotate(-56deg) skewY(21deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.login .login-wrap-wrap .line_2 {
  width: 100vw;
  height: 100vh;
  background: #f57520;
  position: absolute;
  left: 384px;
  top: 305px;
  -webkit-transform: rotate(-34deg) skewY(45deg);
  transform: rotate(-56deg) skewY(21deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.login .login-wrap-wrap .login-wrap {
  width: 1306px;
  height: 783px;
  background: url("../style/imgs/back_login.png") no-repeat;
  background-size: 100% 100%;
  justify-content: flex-start;
  display: flex;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.login .login-wrap-wrap .login-title {
  display: none;
  width: 450px;
  text-align: center;
  color: #484848;
  background: #ffffff;
}
.login .login-wrap-wrap .login-title .foxit-logo {
  margin-bottom: 20px;
}
.login .login-wrap-wrap .login-title span {
  font-size: 20px;
}
.login .login-wrap-wrap .login-title p {
  font-size: 13px;
  margin: 20px auto 50px;
  line-height: 1.8;
}
.login .login-wrap-wrap .login-title .table-i {
  width: 222px;
}
.login .login-wrap-wrap .login-form {
  max-width: 680px;
  z-index: 3;
  color: #d6d6d6;
  margin-left: 104px;
  margin-top: 15px;
  padding-top: 38px;
}
.login .login-wrap-wrap .login-form .ant-form-item {
  margin-bottom: 30px;
}
.login .login-wrap-wrap .login-form .ant-form-item-with-help {
  margin-bottom: 11px;
}
.login .login-wrap-wrap .login-form .login-logo {
  cursor: pointer;
  margin-bottom: 20px;
}
.login .login-wrap-wrap .login-form .login-logo div {
  width: 100%;
  height: 111px;
  overflow: hidden;
}
.login .login-wrap-wrap .login-form .login-logo .title {
  vertical-align: text-bottom;
  font-size: 40px;
  text-transform: capitalize;
  color: #333333;
  margin: 0 0 20px 0;
  font-weight: bold;
  font-family: "Segoe UI", "Open Sans", Verdana, "Microsoft YaHei", Arial, sans-serif;
  line-height: 1;
  white-space: nowrap;
  display: block;
}
.login .login-wrap-wrap .login-form .login-logo p.explain {
  font-size: 14px;
  max-width: 490px;
  display: inline-block;
  color: #999999;
}
.login .login-wrap-wrap .login-form .login-logo .foxit-logo {
  margin-bottom: 70px;
  width: auto;
  height: auto;
}
.login .login-wrap-wrap .login-form .iconMail,
.login .login-wrap-wrap .login-form .iconPassword {
  display: flex;
  margin-right: 10px;
}
.login .login-wrap-wrap .login-form .pwdInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}
.login .login-wrap-wrap .login-form .pwdInfo span {
  color: #999999;
  padding: 0;
}
.login .login-wrap-wrap .login-form .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
  border: none;
  border-bottom: 1px solid #f58320;
  border-radius: 0;
}
.login .login-wrap-wrap .login-form input:-internal-autofill-previewed,
.login .login-wrap-wrap .login-form input:-internal-autofill-selected {
  transition: background-color 5000s ease-in-out 0s !important;
}
.login .login-wrap-wrap .login-form .ant-checkbox-checked .ant-checkbox-inner,
.login .login-wrap-wrap .login-form .ant-checkbox-inner {
  border-color: #F58320;
}
.login .login-wrap-wrap .login-form .ant-checkbox-wrapper {
  color: #d6d6d6;
}
.login .login-wrap-wrap .login-form .ant-btn-primary {
  color: #fff;
  background-color: #F58320;
  border-color: #F58320;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  border-radius: 4px;
}
.login .login-wrap-wrap .login-form .ant-btn-primary:hover {
  background-color: #ff9a44;
  border-color: #ff9a44;
}
.login .login-wrap-wrap .login-form .ant-input {
  border-color: #4f5154;
  color: #666666;
  font-size: 14px;
  line-height: 1.42857143;
}
.login .login-wrap-wrap .login-form .ant-input:focus {
  box-shadow: none;
}
.login .login-wrap-wrap .login-form .ant-input::-webkit-input-placeholder {
  color: #666666;
}
.signUp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  height: 100vh;
  background: #F58320;
  overflow: hidden;
}
@media (max-width: 1366px) {
  .signUp .signUp-wrap-wrap {
    transform: scale(0.7);
  }
  .signUp .signUp-wrap-wrap .scale_1 {
    transform: scale(1.2) translateX(26px);
  }
}
@media screen and (min-width: 1366px) and (max-width: 1919px) {
  .signUp .signUp-wrap-wrap {
    transform: scale(0.875);
  }
  .signUp .signUp-wrap-wrap .scale_1 {
    transform: scale(1.1) translateX(26px);
  }
}
.signUp .signUp-wrap-wrap {
  position: relative;
  width: 1306px;
  height: 783px;
  margin-bottom: -28px;
}
.signUp .signUp-wrap-wrap .line_1 {
  width: 100vw;
  height: 100vmax;
  background: #ff9100;
  position: absolute;
  left: 385px;
  top: 429px;
  -webkit-transform: rotate(-34deg) skewY(45deg);
  transform: rotate(-56deg) skewY(21deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.signUp .signUp-wrap-wrap .line_2 {
  width: 100vw;
  height: 100vh;
  background: #f57520;
  position: absolute;
  left: 384px;
  top: 305px;
  -webkit-transform: rotate(-34deg) skewY(45deg);
  transform: rotate(-56deg) skewY(21deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.signUp .signUp-wrap-wrap .signUp-wrap {
  width: 1306px;
  height: 783px;
  background: url("../style/imgs/back_login.png") no-repeat;
  background-size: 100% 100%;
  justify-content: flex-start;
  display: flex;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.signUp .signUp-wrap-wrap .signUp-title {
  display: none;
  width: 450px;
  text-align: center;
  color: #484848;
  background: #ffffff;
}
.signUp .signUp-wrap-wrap .signUp-title .foxit-logo {
  margin-bottom: 20px;
}
.signUp .signUp-wrap-wrap .signUp-title span {
  font-size: 20px;
}
.signUp .signUp-wrap-wrap .signUp-title p {
  font-size: 13px;
  margin: 20px auto 50px;
  line-height: 1.8;
}
.signUp .signUp-wrap-wrap .signUp-title .table-i {
  width: 222px;
}
.signUp .signUp-wrap-wrap .signUp-form {
  max-width: 510px;
  z-index: 3;
  color: #d6d6d6;
  margin-left: 104px;
  margin-top: 15px;
  padding-top: 38px;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-form-item {
  min-height: 65px;
  margin-bottom: 0;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-form-item .ant-form-explain {
  font-size: 12px!important;
}
.signUp .signUp-wrap-wrap .signUp-form .foxit-logo {
  margin-bottom: 70px;
  width: 81px;
  height: 33px;
}
.signUp .signUp-wrap-wrap .signUp-form .signUp-logo {
  cursor: pointer;
  margin-bottom: 55px;
}
.signUp .signUp-wrap-wrap .signUp-form .signUp-logo p.title {
  vertical-align: text-bottom;
  font-size: 34px;
  display: inline-block;
  color: #333333;
  margin: 0 0 20px 0;
  font-weight: bold;
  font-family: "Segoe UI", "Open Sans", Verdana, "Microsoft YaHei", Arial, sans-serif;
  line-height: 1;
  white-space: nowrap;
}
.signUp .signUp-wrap-wrap .signUp-form .signUp-logo p.explain {
  font-size: 14px;
  text-transform: capitalize;
  display: inline-block;
  color: #999999;
  text-align: justify;
}
.signUp .signUp-wrap-wrap .signUp-form .iconMail,
.signUp .signUp-wrap-wrap .signUp-form .iconPassword {
  display: flex;
  margin-right: 10px;
}
.signUp .signUp-wrap-wrap .signUp-form .pwdInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}
.signUp .signUp-wrap-wrap .signUp-form .pwdInfo span {
  color: #999999;
  padding: 0;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-input-affix-wrapper .ant-input:not(:first-child) {
  font-size: 16px;
  padding-left: 40px;
  border: none;
  border-bottom: 1px solid #f58320;
  border-radius: 0;
  outline: none!important;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-input-affix-wrapper .ant-input::-webkit-input-placeholder {
  font-size: 16px;
}
.signUp .signUp-wrap-wrap .signUp-form input:-internal-autofill-previewed,
.signUp .signUp-wrap-wrap .signUp-form input:-internal-autofill-selected {
  transition: background-color 5000s ease-in-out 0s !important;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-checkbox-checked .ant-checkbox-inner,
.signUp .signUp-wrap-wrap .signUp-form .ant-checkbox-inner {
  background-color: #F58320;
  border-color: #F58320;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-checkbox-wrapper {
  color: #d6d6d6;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-btn-primary {
  color: #fff;
  background-color: #F58320;
  border-color: #F58320;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  border-radius: 4px;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-btn-primary:hover {
  background-color: #ff9a44;
  border-color: #ff9a44;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-input {
  outline: none;
  border: none;
  color: #666666;
  font-size: 16px;
  line-height: 1.42857143;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-input::-webkit-input-placeholder {
  color: #666666;
}
.signUp .signUp-wrap-wrap .signUp-form .ant-input:focus {
  box-shadow: none;
}
.signUp .signUp-wrap-wrap .signUp-form .wrapBtn {
  margin-bottom: 30px;
}
.signUp .signUp-wrap-wrap .SignUpGuide {
  color: #aaaaaa;
  width: 384px;
  margin-left: -6px;
  font-size: 14px;
}
@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?yia4el');
  src: url('fonts/icomoon.eot?yia4el#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?yia4el') format('truetype'), url('fonts/icomoon.woff?yia4el') format('woff'), url('fonts/icomoon.svg?yia4el#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-support:before {
  content: "\e96a";
  color: #999;
}
.icon-box:before {
  content: "\e95e";
  color: #0071f7;
}
.icon-dropbox:before {
  content: "\e95f";
  color: #0061ff;
}
.icon-Google-drive .path1:before {
  content: "\e960";
  color: #4285f4;
}
.icon-Google-drive .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: #000000;
}
.icon-Google-drive .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: #0f9d58;
}
.icon-Google-drive .path4:before {
  content: "\e963";
  margin-left: -1em;
  color: #ffcd40;
}
.icon-local:before {
  content: "\e964";
  color: #f68b1f;
}
.icon-one-drive .path1:before {
  content: "\e965";
  color: #0364b8;
}
.icon-one-drive .path2:before {
  content: "\e966";
  margin-left: -0.96191406em;
  color: #0f78d4;
}
.icon-one-drive .path3:before {
  content: "\e967";
  margin-left: -0.96191406em;
  color: #1490df;
}
.icon-one-drive .path4:before {
  content: "\e968";
  margin-left: -0.96191406em;
  color: #28a8ea;
}
.icon-url:before {
  content: "\e969";
  color: #f68b1f;
}
.icon-ou:before {
  content: "\e95c";
}
.icon-transfer:before {
  content: "\e95b";
  color: #999;
}
.icon-cc-copy:before {
  content: "\e95a";
  color: #f58320;
}
.icon-add-user:before {
  content: "\e955";
  color: #666;
}
.icon-active .path1:before {
  content: "\e956";
  color: #ffffff;
}
.icon-active .path2:before {
  content: "\e957";
  margin-left: -1em;
  color: #999999;
}
.icon-active .path3:before {
  content: "\e958";
  margin-left: -1em;
  color: #ffffff;
}
.icon-active .path4:before {
  content: "\e959";
  margin-left: -1em;
  color: #999999;
}
.icon-account-mode:before {
  content: "\e900";
  color: #f58320;
}
.icon-account:before {
  content: "\e901";
  color: #666;
}
.icon-activated-licenses .path1:before {
  content: "\e902";
  color: #a4a1fb;
}
.icon-activated-licenses .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: #ffffff;
}
.icon-admin-foxit .path1:before {
  content: "\e904";
  color: #f58220;
}
.icon-admin-foxit .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: #ffffff;
}
.icon-admin-role:before {
  content: "\e906";
  color: #888;
}
.icon-admin-role1:before {
  content: "\e95d";
  color: #888;
}
.icon-approve:before {
  content: "\e907";
  color: #fff;
}
.icon-arrow-down:before {
  content: "\e908";
}
.icon-arrow-up:before {
  content: "\e909";
}
.icon-cancel-push:before {
  content: "\e90b";
  color: #999;
}
.icon-captchat:before {
  content: "\e90c";
  color: #f58320;
}
.icon-dashboard-new:before {
  content: "\e90d";
  color: #888;
}
.icon-date:before {
  content: "\e90e";
  color: #ddd;
}
.icon-delete:before {
  content: "\e90f";
  color: #fff;
}
.icon-detail-big:before {
  content: "\e910";
  color: #f58320;
}
.icon-detail:before {
  content: "\e911";
  color: #999;
}
.icon-disable:before {
  content: "\e912";
  color: #999;
}
.icon-down-full:before {
  content: "\e913";
  color: #666;
}
.icon-down-gray:before {
  content: "\e914";
  color: #d8d8d8;
}
.icon-down:before {
  content: "\e915";
  color: #aaa;
}
.icon-edit:before {
  content: "\e917";
  color: #999;
}
.icon-enable:before {
  content: "\e918";
  color: #999;
}
.icon-enterprise:before {
  content: "\e919";
  color: #888;
}
.icon-expired:before {
  content: "\e91a";
  color: #919191;
}
.icon-export:before {
  content: "\e91b";
  color: #fff;
}
.icon-good:before {
  content: "\e91c";
  color: #fff;
}
.icon-googledrive:before {
  content: "\e91d";
  color: #777;
}
.icon-icon-assigned-licenses .path1:before {
  content: "\e91e";
  color: #5fe3a1;
}
.icon-icon-assigned-licenses .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: #ffffff;
}
.icon-icon-assigned-licenses .path3:before {
  content: "\e920";
  margin-left: -1em;
  color: #ffffff;
}
.icon-icon-assigned-licenses .path4:before {
  content: "\e921";
  margin-left: -1em;
  color: #ffffff;
}
.icon-icon-assigned-licenses .path5:before {
  content: "\e922";
  margin-left: -1em;
  color: #ffffff;
}
.icon-icon-assigned-licenses .path6:before {
  content: "\e923";
  margin-left: -1em;
  color: #ffffff;
}
.icon-info:before {
  content: "\e924";
  color: #2e6dad;
}
.icon-internal-update:before {
  content: "\e925";
  color: #888;
}
.icon-license-new:before {
  content: "\e926";
  color: #888;
}
.icon-list:before {
  content: "\e927";
  color: #fff;
}
.icon-logo:before {
  content: "\e928";
  color: #f68b1f;
}
.icon-logout:before {
  content: "\e929";
  color: #666;
}
.icon-logs:before {
  content: "\e92a";
  color: #888;
}
.icon-mail-servers:before {
  content: "\e92b";
  color: #888;
}
.icon-new-user .path1:before {
  content: "\e92c";
  color: #e4e4e4;
}
.icon-new-user .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: #aaaaaa;
}
.icon-non-account-mode:before {
  content: "\e92e";
  color: #f58320;
}
.icon-offline-activation .path1:before {
  content: "\e92f";
  color: #404040;
}
.icon-offline-activation .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: #f58320;
}
.icon-onedrive .path1:before {
  content: "\e931";
  color: #777777;
}
.icon-onedrive .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: #888888;
}
.icon-onedrive .path3:before {
  content: "\e933";
  margin-left: -1em;
  color: #999999;
}
.icon-onedrive .path4:before {
  content: "\e934";
  margin-left: -1em;
  color: #aaaaaa;
}
.icon-online-activation .path1:before {
  content: "\e935";
  color: #404040;
}
.icon-online-activation .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: #f58320;
}
.icon-open-file:before {
  content: "\e937";
  color: #555;
}
.icon-orange:before {
  content: "\e938";
  color: #f99e00;
}
.icon-order-down .path1:before {
  content: "\e939";
  color: #999999;
}
.icon-order-down .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: #666666;
}
.icon-order-up .path1:before {
  content: "\e93b";
  color: #666666;
}
.icon-order-up .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: #999999;
}
.icon-product-configuration:before {
  content: "\e93d";
  color: #888;
}
.icon-product-new:before {
  content: "\e93e";
  color: #888;
}
.icon-push-grey:before {
  content: "\e93f";
  color: #999;
}
.icon-push-withe:before {
  content: "\e940";
  color: #fff;
}
.icon-push:before {
  content: "\e941";
  color: #fff;
}
.icon-reports:before {
  content: "\e942";
  color: #888;
}
.icon-retry:before {
  content: "\e943";
  color: #fff;
}
.icon-right:before {
  content: "\e944";
  color: #aaa;
}
.icon-send-test-email:before {
  content: "\e945";
  color: #999;
}
.icon-settings:before {
  content: "\e946";
  color: #888;
}
.icon-success:before {
  content: "\e947";
  color: #36d67b;
}
.icon-time:before {
  content: "\e948";
  color: #999;
}
.icon-total-licenses .path1:before {
  content: "\e949";
  color: #f99e00;
}
.icon-total-licenses .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: #ffffff;
}
.icon-total-licenses .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: #ffffff;
}
.icon-unapproved:before {
  content: "\e94c";
  color: #999;
}
.icon-uniE934:before {
  content: "\e94d";
  color: #888;
}
.icon-up-full:before {
  content: "\e94e";
  color: #999;
}
.icon-up-gray:before {
  content: "\e94f";
  color: #d8d8d8;
}
.icon-user-id-new:before {
  content: "\e951";
  color: #888;
}
.icon-users .path1:before {
  content: "\e952";
  color: #e4e4e4;
}
.icon-users .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: #aaaaaa;
}
.icon-view-details:before {
  content: "\e954";
  color: #999;
}
.icon-delete-999:before {
  content: "\e90f";
  color: #888;
}
.ant-btn + .ant-btn {
  margin-left: 10px;
}
.ant-btn-link {
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}
.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}
/*
// Use flex which not working in IE
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}
*/
.sider-wrap.ant-layout-sider,
.sider-wrap .ant-menu-root.ant-menu-inline,
.sider-wrap .ant-menu-sub {
  background-color: #2d3742 !important;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item-selected {
  background-color: #2d3742 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #2d3742 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: #F58320 !important;
}
.ant-menu-item-selected::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 2px solid #F58320;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.custom-trigger {
  font-size: 18px;
  line-height: 60px;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-layout-sider-collapsed {
  overflow-y: initial !important;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 32px;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
}
.avatar i {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
}
.avatar i.bottom {
  left: auto;
  top: auto;
  bottom: 0;
  right: 0;
}
.avatar i.on {
  background-color: #6cc788;
}
.avatar img {
  border-radius: 500px;
  width: 100%;
}
.switcher {
  z-index: 1050;
  position: fixed;
  top: 78px;
  right: -240px;
  width: 240px;
  transition: right 0.2s ease;
  border: 1px solid rgba(120, 120, 120, 0.1);
  background-clip: padding-box;
}
.switcher.active {
  right: -2px;
}
.switcher .sw-btn {
  position: absolute;
  left: -43px;
  top: -1px;
  padding: 10px 15px;
  z-index: 1045;
  border: 1px solid rgba(120, 120, 120, 0.1);
  border-right-width: 0;
  background-clip: padding-box;
}
.ant-menu-submenu > .ant-menu {
  border-radius: 0px !important;
}
.ant-menu-submenu-popup {
  border-radius: 0px !important;
}
.lang-menu .ant-menu-sub .ant-menu-item {
  width: 130px !important;
}
.lang-menu .ant-menu-vertical.ant-menu-sub {
  min-width: 130px !important;
}
.ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: top !important;
  top: 0px !important;
}
.custom-trigger-new {
  font-size: 18px;
  line-height: 60px;
  cursor: pointer;
  transition: color 0.3s;
  position: fixed;
  bottom: 0;
}
.pd-left-24 {
  padding: 0 24px;
}
.pd-left-32 {
  padding: 0 32px;
}
.ant-menu-submenu-placement-rightTop ul {
  background-color: #2d3742 !important;
}
.ant-menu-submenu-placement-rightTop ul a {
  color: #fff !important;
}
.nav-text {
  font-size: 14px !important;
  color: #aaaaaa;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .nav-text {
  color: #F58320 !important;
}
.ant-menu-submenu-selected .ant-menu-item-selected .nav-text {
  color: #F58320 !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .icon::before {
  color: #F58320 !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 2px solid #F58320;
  -webkit-transition: -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-submenu-selected > .ant-menu-sub > .ant-menu-item-selected::before {
  border-left: 0 !important;
}
.ant-menu-item.ant-menu-item-selected .nav-text {
  color: #F58320 !important;
}
.ant-menu-submenu-arrow::before {
  background: #aaa !important;
}
.ant-menu-submenu-arrow::after {
  background: #aaa !important;
}
.pd-left-20 {
  padding-left: 0 20px;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
  padding-left: 20px !important;
}
.ant-menu-item {
  padding-left: 20px !important;
}
.ant-menu-submenu-open .ant-menu-item {
  padding-left: 48px !important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  width: 5px !important;
}
.new-logo {
  background: #2d3742;
  line-height: 60px;
  position: fixed;
  z-index: 999;
  border-bottom: 1px solid #222c37;
}
.new-meun-open {
  width: 256px;
  padding-left: 20px !important;
  height: 64px;
  line-height: 55px;
}
.new-meun-close {
  width: 56px;
}
.new-width-56 {
  overflow: auto;
  left: 0px;
  z-index: 10;
  flex: 0 0 56px !important;
  max-width: 56px !important;
  min-width: 56px !important;
  width: 56px !important;
}
.new-width-290 {
  overflow: auto;
  left: 0px;
  z-index: 10;
  flex: 0 0 256px !important;
  max-width: 256px !important;
  min-width: 256px !important;
  width: 256px !important;
}
.new-width-56 .ant-menu-item {
  width: 56px;
  padding: 0 !important;
  text-align: center;
  height: 48px;
  line-height: 48px;
}
.new-width-56 .ant-menu-inline-collapsed > .ant-menu-item,
.new-width-56 .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.new-width-56 .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.new-width-56 .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0!important;
}
.new-width-56 .ant-menu-submenu {
  width: 56px;
  padding: 0 !important;
  text-align: center;
}
.new-width-56 .ant-menu-item .icon,
.new-width-56 .ant-menu-submenu-title .icon {
  margin-right: 0 !important;
}
.openMenuBtn {
  width: 100%;
  text-align: center;
  height: 60px;
}
.close_title .ant-menu-item-group-title {
  color: #F58320;
  background-color: #333333 !important;
  margin-left: -72px;
  height: 48px;
  line-height: 32px;
}
.close_title .titleIcon {
  width: 56px;
  display: inline-block;
  text-align: center;
}
.close_title .titleIcon .icon::before {
  color: #F58320 !important;
  font-size: 16px !important;
}
.ant-menu-submenu-placement-rightTop > .ant-menu > .ant-menu-item-selected::before {
  border: 0 !important;
}
.ant-menu-submenu-placement-rightTop .ant-menu-item {
  background-color: #2d3742;
  margin: 0 !important;
}
.ant-menu-inline-collapsed .ant-menu-submenu-title {
  height: 36px !important;
  line-height: 36px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  height: 36px !important;
  line-height: 36px !important;
  color: #aaaaaa;
}
.ant-menu-inline-collapsed .ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #2d3742 !important;
}
.ant-menu-submenu-placement-rightTop .ant-menu-item:hover {
  color: #F58320;
}
.ant-menu-submenu.ant-menu-submenu-vertical:hover {
  color: #F58320;
  background-color: #2d3742 !important;
  height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-submenu-title :hover .icon::before {
  color: #F58320;
}
.ant-menu-submenu-placement-rightTop .ant-menu:hover ~ .ant-menu-submenu-title {
  background-color: #2d3742 !important;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-inner .icon::before {
  display: none;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-inner .ant-tooltip-arrow {
  display: none;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
  left: 48px !important;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  width: 219px;
  height: 48px;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-content {
  height: 48px;
  width: 225px;
  border-radius: 0 !important;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow {
  display: none;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner {
  height: 48px;
  padding: 0;
  line-height: 48px;
  border-radius: 0 !important;
  background: #333333;
  color: #F58320;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner a {
  margin-left: -8px;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner a .nav-text {
  color: #F58320;
}
.ant-menu.ant-menu-light.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical .ant-menu-item:hover {
  background: #2d3742;
}
.ant-menu.ant-menu-light.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical .ant-menu-item:hover .icon::before {
  color: #F58320;
}
.logo_over {
  position: fixed;
  z-index: 999;
}
.titleTitle {
  margin-left: -8px;
}
.Dropdown_new_meun {
  line-height: 60px;
  padding-right: 60px;
  font-size: 14px;
  color: #fff;
  height: 58px !important;
  position: absolute;
  right: 50px;
}
.Dropdown_new_meun a {
  font-size: 14px;
  color: #fff;
  height: 60px !important;
}
.Dropdown_new_meun .icon {
  padding-left: 10px;
}
.Dropdown_new_list a {
  color: #999999;
}
.icon-account,
.icon-logout {
  font-size: 15px;
}
.headerNewTitle {
  font-size: 16px;
  color: #222c37;
  padding-left: 16px;
  font-weight: 600;
}
.banner-user {
  height: 200px;
}
.banner-user-elem {
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner-user-elem .banner-user-title {
  font-size: 32px;
  top: 40%;
}
.banner-user-elem .banner-user-text {
  top: 40%;
}
.banner-anim-elem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.custom-arrow-thumb {
  height: 220px;
}
.custom-arrow-thumb .user-arrow {
  top: 50%;
  margin-top: -40px;
}
.custom-arrow-thumb .user-arrow .img-wrapper {
  width: 120px;
  height: 80px;
  float: left;
  position: relative;
}
.custom-arrow-thumb .user-arrow .img-wrapper li {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
}
.custom-arrow-thumb .user-arrow .arrow {
  width: 20px;
  height: 80px;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
}
.custom-arrow-thumb .user-arrow .arrow:before,
.custom-arrow-thumb .user-arrow .arrow:after {
  width: 2px;
  height: 15px;
  background: #fff;
  display: block;
  content: ' ';
  position: absolute;
}
.custom-arrow-thumb .user-arrow.next {
  right: -120px;
}
.custom-arrow-thumb .user-arrow.next .arrow {
  float: left;
}
.custom-arrow-thumb .user-arrow.next .arrow:before {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  top: 28px;
  left: 10px;
}
.custom-arrow-thumb .user-arrow.next .arrow:after {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  bottom: 27px;
  left: 10px;
}
.custom-arrow-thumb .user-arrow.prev {
  left: -120px;
}
.custom-arrow-thumb .user-arrow.prev .arrow {
  float: right;
}
.custom-arrow-thumb .user-arrow.prev .arrow:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  top: 28px;
  left: 8px;
}
.custom-arrow-thumb .user-arrow.prev .arrow:after {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  bottom: 27px;
  left: 8px;
}
.custom-arrow-thumb .user-thumb {
  overflow: hidden;
  background: rgba(255, 255, 255, 0.15);
  height: 40px;
}
.custom-arrow-thumb .user-thumb > span {
  width: 50px;
  height: 30px;
  margin: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  background: transparent;
}
.custom-arrow-thumb .user-thumb > span.active {
  background: rgba(255, 255, 255, 0.45);
}
.custom-arrow-thumb .user-thumb > span i {
  display: block;
  width: 46px;
  height: 26px;
  margin: 2px;
  background-size: cover;
  background-position: center;
}
.react-draggable,
.cursor-move {
  cursor: move;
}
.react-draggable strong,
.cursor-move strong {
  background: #ddd;
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
}
.no-cursor {
  cursor: auto;
}
.card-tool {
  position: absolute;
  right: 24px;
  top: 24px;
}
.list-group .list-group-item {
  position: relative;
  display: block;
  margin-bottom: -1px;
  padding: 12px 16px;
  background: transparent;
  border: 1px solid #ddd;
  border-color: rgba(120, 130, 140, 0.065);
  border-width: 1px 0;
}
.list-group .list-group-item:first-child {
  border-top-width: 0;
}
.list-group .list-group-item:last-child {
  border-bottom-width: 0;
}
.no-padding .ant-card-body {
  padding: 0 !important;
}
.new-card .ant-card-bordered {
  box-shadow: 0px 0px 5px 1px #e8e8e8;
}
.new-card .ant-card {
  border-radius: 5px !important;
}
.new-card .ant-col {
  padding-left: 15px!important;
  padding-right: 15px!important;
}
.new-card .ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.09) !important;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.09) !important;
}
.new-card .ant-card-cover {
  border-bottom: 1px solid #edf1f6;
}
.new-card .ant-card-cover img {
  padding: 10%;
}
.new-card .ant-card-meta-title {
  font-size: 1em !important;
}
.new-card .ant-card-meta-description {
  font-size: 0.8em !important;
}
.new-card .ant-card-body {
  min-height: 135px;
}
.backListBtn {
  height: 16px;
  position: absolute;
  z-index: 1;
  top: 68px;
  cursor: pointer;
}
img {
  vertical-align: middle;
}
.img-responsive {
  width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
i img {
  width: 1.2em;
  height: 1.2em;
}
.text {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.5rem;
}
.text-2x {
  font-size: 2rem;
}
.text-3x {
  font-size: 3rem;
}
.text-4x {
  font-size: 4rem;
}
.text-5x {
  font-size: 5rem;
}
.text-sm {
  font-size: 0.5rem;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-danger,
.text-danger-hover a:hover {
  color: #f44455 !important;
}
.text-dark,
.text-dark-hover a:hover {
  color: #2e3e4e !important;
}
.text-info,
.text-info-hover a:hover {
  color: #6887ff !important;
}
.text-success,
.text-success-hover a:hover {
  color: #6cc788 !important;
}
.text-blue,
.text-blue-hover a:hover {
  color: #2196f3 !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.dark-white {
  color: #2e3e4e;
  background-color: #ffffff;
}
.white {
  color: #ffffff;
}
.min-black {
  color: rgba(255, 255, 255, 0.87);
  background-color: rgba(0, 0, 0, 0.065);
}
.black {
  color: rgba(255, 255, 255, 0.87);
  background-color: #2a2b3c;
}
.dark {
  color: rgba(255, 255, 255, 0.87);
  background-color: #2e3e4e;
}
.grey {
  color: rgba(255, 255, 255, 0.87);
  background-color: #424242;
}
.primary {
  color: rgba(255, 255, 255, 0.87);
  background-color: #F58320;
}
.info {
  color: rgba(255, 255, 255, 0.87);
  background-color: #6887ff;
}
.warn {
  color: rgba(255, 255, 255, 0.87);
  background-color: #fcc100;
}
.danger {
  color: rgba(255, 255, 255, 0.87);
  background-color: #f44455;
}
.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block;
}
.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block;
}
.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
}
.w-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
}
.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}
.w-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center;
}
.w-56 {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  text-align: center;
}
.h-100 {
  height: 100px;
}
.b-a {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
.b-l {
  border-left: 1px solid #ffffff;
}
.b-r {
  border-right: 1px solid #ffffff;
}
.pb-s {
  padding-bottom: 0.5rem !important;
}
.pb-m {
  padding-bottom: 1rem !important;
}
.pb-l {
  padding-bottom: 2rem !important;
}
.pl-s {
  padding-left: 0.5rem !important;
}
.pl-m {
  padding-left: 1rem !important;
}
.pl-l {
  padding-left: 2rem !important;
}
.pr-s {
  padding-right: 0.5rem !important;
}
.pr-m {
  padding-right: 1rem !important;
}
.pr-l {
  padding-right: 2rem !important;
}
.pt-s {
  padding-top: 0.5rem !important;
}
.pt-m {
  padding-top: 1rem !important;
}
.pt-l {
  padding-top: 2rem !important;
}
.pa-s {
  padding: 0.5rem !important;
}
.pa-m {
  padding: 1rem !important;
}
.pa-l {
  padding: 2rem !important;
}
.pt-4 {
  padding-top: 4px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-20 {
  padding-right: 20px;
}
.pa-20 {
  padding: 20px;
}
.pa-30 {
  padding: 30px;
}
.ma-s {
  margin: 0.5rem !important;
}
.ma-m {
  margin: 1rem !important;
}
.ma-l {
  margin: 2rem !important;
}
.mr-s {
  margin-right: 0.5rem !important;
}
.mr-m {
  margin-right: 1rem !important;
}
.mr-l {
  margin-right: 2rem !important;
}
.mb-s {
  margin-bottom: 0.5rem !important;
}
.mb-m {
  margin-bottom: 1rem !important;
}
.mb-l {
  margin-bottom: 2rem !important;
}
.ml-s {
  margin-left: 0.5rem !important;
}
.ml-m {
  margin-left: 1rem !important;
}
.ml-l {
  margin-left: 2rem !important;
}
.mt-s {
  margin-top: 0.5rem !important;
}
.mt-m {
  margin-top: 1rem !important;
}
.mt-l {
  margin-top: 2rem !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-20 {
  margin-bottom: 20px;
}
.display-flex {
  display: flex;
  /*
        flex-direction属性：决定主轴的方向（即项目的排列方向）
    */
  /*
        align-items属性：定义在交叉轴上的对齐方式
    */
  /*
        flex-grow: 0;定义项目的放大比例   默认值为0，即如果空间有剩余，也不放大。
        flex-shrink: 1;定义项目的缩小比例 默认值都为1，即如果空间不足将等比例缩小。如果有一个项目的值为0，其他项目为1，当空间不足时，该项目不缩小。
        flex-basis: auto;定义在分配多余空间之前，项目占据的主轴空间。默认值为auto，浏览器根据此属性检查主轴是否有多余空间。
    */
}
.display-flex.justify-center {
  justify-content: center;
}
.display-flex.space-between {
  justify-content: space-between;
}
.display-flex.space-around {
  justify-content: space-around;
}
.display-flex.direction-column {
  flex-direction: column;
}
.display-flex.flex-start {
  justify-content: flex-start;
}
.display-flex.align-center {
  align-items: center;
}
.display-flex.align-top {
  align-items: flex-start;
}
.display-flex.align-bottom {
  align-items: flex-end;
}
.display-flex.align-baseline {
  align-items: baseline;
}
.display-flex.flex-01 > * {
  flex: 0 1 auto;
}
.display-flex.flex-10 > * {
  flex: 1 0 auto;
}
.display-flex.flex-11 > * {
  flex: 1 1 auto;
}
.breathe {
  webkit-animation: breathe 1500ms ease infinite;
  -moz-animation: breathe 1500ms ease infinite;
  -o-animation: breathe 1500ms ease infinite;
  animation: breathe 1500ms ease infinite;
}
.breathe.first {
  height: 80px;
  width: 80px;
  border: 5px solid #7bc76f;
  background-color: transparent;
  border-radius: 100%;
  top: -24px;
  left: 40px;
}
@media (min-width: 768px) {
  .breathe.first {
    left: 40px;
  }
}
@media (min-width: 992px) {
  .breathe.first {
    left: 150px;
  }
}
@media (min-width: 1200px) {
  .breathe.first {
    left: 247px;
  }
}
@-webkit-keyframes breathe {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes breathe {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes breathe {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
}
@keyframes breathe {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
}
.breathe-shadow {
  webkit-animation: breathe 1500ms ease infinite;
  -moz-animation: breathe 1500ms ease infinite;
  -o-animation: breathe 1500ms ease infinite;
  animation: breathe 1500ms ease infinite;
}
.breathe-shadow.second {
  left: calc(50%  - 10px );
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: rgba(123, 199, 111, 0.4);
}
.breathe-shadow.second.t-l {
  top: -13px;
  left: -35px;
}
.breathe-shadow.second.t {
  top: -27px;
}
.breathe-shadow.second::after {
  content: " ";
  position: absolute;
  top: -10px;
  left: -10px;
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: rgba(123, 199, 111, 0.3);
}
@-webkit-keyframes breathe-shadow {
  0% {
    opacity: 0.2;
    box-shadow: 0 1px 10px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    box-shadow: 0 1px 40px rgba(123, 199, 111, 0.5);
  }
  50% {
    opacity: 1;
    box-shadow: 0 1px 80px #7bc76f;
  }
}
@-moz-keyframes breathe-shadow {
  0% {
    opacity: 0.2;
    box-shadow: 0 1px 10px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    box-shadow: 0 1px 40px rgba(123, 199, 111, 0.5);
  }
  50% {
    opacity: 1;
    box-shadow: 0 1px 80px #7bc76f;
  }
}
@-o-keyframes breathe-shadow {
  0% {
    opacity: 0.2;
    box-shadow: 0 1px 10px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    box-shadow: 0 1px 40px rgba(123, 199, 111, 0.5);
  }
  50% {
    opacity: 1;
    box-shadow: 0 1px 80px #7bc76f;
  }
}
@keyframes breathe-shadow {
  0% {
    opacity: 0.2;
    box-shadow: 0 1px 10px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    box-shadow: 0 1px 40px rgba(123, 199, 111, 0.5);
  }
  50% {
    opacity: 1;
    box-shadow: 0 1px 80px #7bc76f;
  }
}
.breathe-drop,
.breathe-bor-drop {
  width: 320px;
  padding: 15px 25px;
  background-color: #7bc76f;
  color: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 15px;
}
.breathe-drop > *,
.breathe-bor-drop > * {
  color: #ffffff !important;
}
.breathe-bor-drop {
  top: -45px;
  left: 20px;
}
.breathe-bor-drop .anticon-close {
  position: absolute;
  right: 10px;
}
.initia-cloud {
  max-width: 700px;
  margin: auto;
}
.initia-cloud .ant-input-lg {
  font-size: 14px;
}
.initia-cloud .breathe-shadow.second.t-l {
  top: -32px;
  left: 74px;
}
.breathe-bor {
  width: 100%;
  height: 40px;
  border: 2px solid #7bc76f;
  border-radius: 4px;
  top: -40px;
  left: -10px;
}
.ant-btn-transparent-white {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.breathe-bag {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  cursor: default;
}
.drop-right {
  right: 0;
}
.dashboard-main p {
  margin: 0 !important;
  padding: 0 !important;
}
.dashboard-main .title {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 5px 5px #e8e8e8;
}
.dashboard-main .title .cotent-tips {
  height: 43px;
  line-height: 43px;
}
.dashboard-main .title .title-detail {
  height: 43px;
  line-height: 43px;
  font-weight: bold;
  display: inline-block;
}
.dashboard-main .title .icon-info {
  font-size: 14px!important;
}
.dashboard-main .pd-20 {
  padding: 20px;
}
.dashboard-main .select {
  text-align: right;
  color: #333333;
  line-height: 3;
  text-transform: none;
  border-color: #f2f2f2;
}
.dashboard-main .select .ant-select-selection-selected-value {
  color: #333333 !important;
}
.dashboard-main .select div {
  text-align: left;
}
.dashboard-main .dashboard-connect {
  height: 100px;
  padding-left: 30px;
}
.dashboard-main [class^="icon-"],
.dashboard-main [class*=" icon-"] {
  font-size: 30px !important;
}
.dashboard-main .ant-card-meta-avatar {
  float: left;
  padding-right: 14px !important;
  line-height: 60px;
}
.dashboard-main .ant-card-meta-description {
  color: #999999 !important;
}
.dashboard-main .ant-card-meta-title {
  font-size: 30px !important;
  color: #333333 !important;
}
.dashboard-main .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0px !important;
}
.dashboard-main .gutter-box {
  padding: 0 0 20px !important;
}
.dashboard-main .ant-card-head-title {
  text-transform: none !important;
  font-size: 16px !important;
  color: #333333;
}
.dashboard-main .tips_chart {
  width: 155px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  vertical-align: middle;
}
.dashboard-main .tips_chart p {
  text-transform: none !important;
  font-size: 12px !important;
  color: #ffffff;
}
.dashboard-main .ant-card {
  box-shadow: 5px 5px 5px #e8e8e8;
}
.dashboard-main .other_crad {
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #e8e8e8;
  margin: 0 !important;
}
.dashboard-main .other_crad .ant-card {
  box-shadow: none !important;
}
.dashboard-main .gcs-radius {
  padding: 20px 10px 10px 10px;
}
.dashboard-main .gcs-radius a {
  width: 110px;
  height: 110px;
  line-height: 105px;
  text-align: center;
  display: inline-block;
  background: #ffffff;
  text-decoration: none;
  color: #333333;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 24px;
}
.dashboard-main .border_a4 {
  border: 5px solid #a4a1fb;
}
.dashboard-main .border_5f {
  border: 5px solid #5fe3a1;
}
.dashboard-main .border_f9 {
  border: 5px solid #f99e00;
}
.dashboard-main .border_6b {
  border: 5px solid #6BBFFF;
}
.dashboard-main .gutter-box-ohter {
  text-align: center;
}
.dashboard-main .gcs-radius-tips {
  color: #4d4f5c;
  font-size: 14px;
  text-transform: none;
}
.dashboard-main .last-div .gutter-box {
  padding: 0 !important;
}
@media (min-height: 960px) {
  .dashboard-main .echarts-for-react {
    height: 39vh !important;
  }
}
@media (min-height: 900px) and (max-height: 959px) {
  .dashboard-main .echarts-for-react {
    height: 32vh !important;
  }
}
@media (min-height: 859px) and (max-height: 899px) {
  .dashboard-main .echarts-for-react {
    height: 29vh !important;
  }
}
.dashboard-main .week-select {
  position: absolute;
  z-index: 1;
  top: 25px;
  right: 28px;
}
.dashboard-main .ant-select-focused .ant-select-selection,
.dashboard-main .ant-select-selection:focus,
.dashboard-main .ant-select-selection:active,
.dashboard-main .ant-select-selection:hover {
  border-color: #999999 !important;
  box-shadow: none !important;
}
.dashboard-main .ant-card-head-title {
  padding: 20px 0 0 0!important;
}
.dashboard-main .ant-card-loading-content {
  padding: 10px;
  height: 100px;
  overflow: hidden;
}
.dashboard-main .minSelect .ant-select-selection {
  min-width: 192px;
}
.dashboard-main .minSelect .ant-form-item {
  margin-bottom: 0 !important;
}
.dashboard-main .echarts-for-react div {
  overflow: visible !important;
}
.dashboard-main .echarts-for-react div canvas {
  min-height: 275px!important;
  width: 101.5% !important;
}
.icon-new-user .path1::before {
  font-size: 28px;
}
.icon-new-user .path2::before {
  font-size: 28px;
}
.icon-new-user .path2 {
  line-height: 2;
}
.lang-menu .ant-menu-submenu-title {
  height: 60px !important;
}
.min-width-time {
  min-width: 100px;
  display: inline-block;
}
.Dropdown_new_list.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f1f1f1 !important;
}
.Dropdown_new_list.ant-dropdown-menu-item:hover a,
.ant-dropdown-menu-submenu-title:hover a {
  color: #f58320 !important;
}
.drive-checkbox {
  display: inline-flex;
  align-items: center;
  height: 32px;
}
.drive-checkbox span:nth-child(2) {
  display: inline-flex;
  align-items: center;
}
.drive-checkbox .ant-checkbox {
  top: 0;
}
.Adddirectory {
  padding-bottom: 10px;
  padding-right: 10px;
  display: inline-block;
}
.pa-20 {
  padding: 20px;
}
.ldap_ .from-pd {
  padding-top: 25px;
}
.ldap_ .pd-30 {
  padding-left: 30px;
}
.ldap_ .pdb-50 {
  padding-bottom: 50px;
}
.ldap_ .ant-col,
.ldap_ .ant-form-item-label label {
  font-size: 12px !important;
}
.ldap_ .LdapFromFrist {
  font-size: 14px;
  padding-top: 16px;
  display: inline-block;
}
.ldap_ .ant-form-item-required::before {
  content: '*' !important;
}
.ldap_ .ant-input-number-handler-wrap {
  display: none;
}
.new-spin .ant-spin-blur {
  opacity: 0 !important;
}
.ldaptestFrom .ant-form-item-control-wrapper {
  display: inline-block;
  width: 200px;
}
.lengedicon {
  height: 18px;
  width: 8px;
  border-radius: 4px;
  display: inline-block;
}
.reports .echarts-for-react {
  height: 350px !important;
}
.min-select {
  min-width: 192px;
}
.header-custom {
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: fixed;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #e4e4e4e4;
}
.ant-modal.licese-model {
  padding-bottom: 0 !important;
}
.licese-model {
  height: 600px;
}
.licese-model .ant-modal-close-x {
  height: 52px !important;
  line-height: 52px !important;
}
.licese-model .ant-modal-header {
  padding: 15px 24px !important;
}
.licese-model .ant-modal-title {
  font-size: 14px !important;
  font-weight: bold;
  color: #333333 !important;
}
.licese-model .ant-modal-footer {
  padding: 14px 24px !important;
  border-top: 1px solid #e2e2e2 !important;
}
.licese-model .mt-m {
  margin-top: 14px !important;
}
.licese-model .bottom-title {
  font-size: 14px ;
  font-weight: bold;
  color: #333333;
  margin: 0;
  padding: 0;
  padding-bottom: 11px;
}
.licese-model .ant-modal-body {
  padding: 24px 40px 20px 40px !important;
}
.licese-model .ant-radio-wrapper {
  font-size: 12px !important;
  display: block;
  padding: 5px;
}
.licese-model .transfer-wrap {
  width: auto !important;
}
.licese-model .newheight .transfer-list-body {
  height: 280px !important;
}
.licese-model .newheight .selectTips {
  padding-top: 50% !important;
}
.licese-model .transfer-wrap-box {
  width: 256px !important;
  height: 346px !important;
  border: 1px solid #e2e2e2;
}
.licese-model .transfer-wrap-box .transfer-list-body {
  height: 355px !important;
}
.licese-model .transfer-wrap-box .ant-tree-title {
  width: 92% !important;
}
.licese-model .Search {
  width: 240px;
  height: 20px;
}
.licese-model .Search input {
  height: 20px;
  font-size: 12px;
  border-radius: 10px;
  line-height: 1;
}
.licese-model .Search .ant-input-suffix {
  top: 60% !important;
}
.licese-model .transfer-wrap-title {
  font-size: 14px ;
  font-weight: bold;
  color: #333333;
  padding-bottom: 8px;
}
.licese-model .transfer-list-content {
  text-align: center;
  padding: 6px 5px !important;
}
.licese-model .transfer-wrap .ant-tree {
  text-align: left;
  height: auto !important;
}
.licese-model .bg {
  background: #f8f8f8;
}
.licese-model .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: #f8f8f8 !important;
}
.licese-model .selectTips {
  width: 70%;
  display: inline-block;
  padding-top: 65%;
  font-size: 12px;
  color: #BBBBBB;
}
.licese-model .ant-tree.ant-tree-show-line li:not(.group_)::before {
  margin: 0 !important;
}
.licese-model .ant-tree.ant-tree-show-line li:last-child:before {
  height: calc(100% - 18px) !important;
}
.licese-model .ant-tree.ant-tree-show-line li::before {
  height: calc(100%) !important;
}
.licese-model .ant-tree-child-tree.ant-tree-child-tree-open li::before {
  height: calc(100% - 18px) !important;
}
.licese-model .ant-tree-child-tree.ant-tree-child-tree-open li span.ant-tree-switcher {
  border-bottom: 1px dashed #d9d9d9;
  height: 12px !important;
  display: inline-block;
  padding: 0 11px;
}
.licese-model .cotent-tree .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  margin-right: 0 !important;
}
.licese-model .cotent-tree {
  border: none !important;
}
.licese-model .ant-tree-child-tree.ant-tree-child-tree-open li.new-ch::before {
  display: none;
}
.licese-model .cotent-tree .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: #FFE7D2 !important;
}
.licese-model .cotent-tree .ant-tree li .ant-tree-node-content-wrapper:hover {
  background: #FFE7D2 !important;
}
.licese-model .ant-modal-content .transfer-wrap.r .transfer-list-content-item:hover {
  background-color: #FFE7D2 !important;
}
.licese-model .ant-tree-title {
  color: #333333 !important;
}
.licese-model .ant-radio-wrapper {
  color: #333333 !important;
}
.licese-model .ant-tree li span.ant-tree-switcher,
.licese-model .ant-tree li span.ant-tree-iconEle {
  z-index: 1;
}
.ant-tree li {
  padding: 2px 0 !important;
}
.group-model .ant-modal-body {
  padding: 26px 40px 11px 40px !important;
}
.group-model .ant-form-item {
  margin-bottom: 0 !important;
}
.group-model .ant-form-text {
  padding-right: 0px !important;
  padding-bottom: 6px;
}
.group-model .ant-form-vertical .ant-form-item {
  padding-bottom: 0 !important;
}
.group-model .ant-form-item-control {
  line-height: 1 !important;
  padding-bottom: 21px;
}
.group-model .ant-form-item-label {
  padding: 0 0 9px !important;
}
.group-model .ant-form-item-label label {
  color: #333333 !important;
  font-weight: bold;
}
.group-model form .ant-mentions,
.group-model form textarea.ant-input {
  margin-bottom: 0 !important ;
}
.group-model .ant-input {
  border: 1px solid #aeaeae !important;
}
.group-model #form_in_modal_group_note {
  height: 100px !important;
}
.RemoveLivenseByCSV_ .anticon-loading {
  display: none !important;
}
.EnterpriseBrandCustomization_container .ant-spin-nested-loading {
  height: calc(100vh - 55px);
}
.i-mg-10 {
  margin-left: 10px;
}
.LastConnectionTime_Tooltip .ant-tooltip-inner {
  min-width: 470px !important;
}
.ManageLicensing_Tooltip .ant-tooltip-inner {
  min-width: 300px !important;
}
.LastConnectionTime_Tooltip .ant-tooltip-inner,
.ManageLicensing_Tooltip .ant-tooltip-inner {
  background-color: #f5faff !important;
  color: #333333;
}
@media screen and (max-width: 1160px) {
  .LastConnectionTime_Tooltip .ant-tooltip-inner,
  .ManageLicensing_Tooltip .ant-tooltip-inner {
    min-width: 40px !important;
  }
}
.LastConnectionTime_Tooltip .ant-tooltip-arrow,
.ManageLicensing_Tooltip .ant-tooltip-arrow {
  border-width: 5px !important;
  border-top-color: #f5faff !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: #f5faff !important;
}
.tipsChange {
  margin-left: 10px;
}
.tipsChange .ant-btn-edit {
  border: 0 !important;
}
.tipsChanges {
  margin-right: 10px;
}
.tipsChanges .ant-btn-edit {
  border: 0 !important;
}
.tipsChanges_ {
  margin-right: 10px;
}
.tipsChange_ {
  margin-left: 10px;
}
.ChooseEnterpriseTips {
  display: block;
  padding-bottom: 5px;
  color: #333333;
}
.chooseSelect .ant-select-selection__placeholder {
  font-size: 12px !important;
}
.chooseOption.ant-select-dropdown-menu-item-selected {
  color: #F58320;
}
.chooseOption.ant-select-dropdown-menu-item-actived {
  color: #F58320 !important;
}
.chooseSelectModel .ant-modal-body {
  padding: 30px 50px !important;
}
.tagchange {
  border: none !important;
  background: none !important;
}
.tablethcenter th {
  text-align: center;
}
.icon-disable:before {
  font-size: 15px ;
}
.icon-detail:before {
  font-size: 15px;
}
.icon-active {
  font-size: 15px;
}
.pd-all {
  padding: 1rem ;
}
.display-i {
  display: inline-block;
  padding-top: 0.6rem;
  min-width: 500px;
}
.spform .ant-form-item {
  margin-bottom: 0 !important;
}
.sp_copy_icon {
  font-size: 20px;
  vertical-align: middle;
  padding-left: 10px;
  cursor: pointer;
}
.spinput {
  color: rgba(0, 0, 0, 0.45) !important;
  width: 80%;
}
.mySaml {
  background: #f8f8f8;
  padding: 1rem;
  max-width: 780px;
}
.max .ant-tooltip-inner {
  max-width: 550px;
}
.c6:before {
  color: #666666 !important;
}
.display-n {
  display: none !important;
}
.SSLCertificate textarea {
  max-width: 300px;
}
.SynchroniseBox {
  display: inline-block;
  opacity: 1 !important;
  color: #333333 !important;
  border: 1px solid #d3d3d3 !important;
  height: 32px!important;
  border-radius: 2px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  line-height: 30px;
  margin-left: 10px;
  padding: 0 15px;
  font-size: 14px;
}
.SynchroniseBox .ant-select-selection__rendered {
  line-height: 18px !important;
}
.SynchroniseBox .ant-select-selection,
.SynchroniseBox .ant-time-picker-input {
  background-color: #f5f5f5 !important;
  border: 1px solid #f5f5f5 !important;
}
.SynchroniseBox .ant-select-selection--single {
  height: 20px !important;
}
.SynchroniseBox .SynchroniseBox_text {
  display: inline-block;
  padding-right: 10px;
}
.SynchroniseBox .SynchroniseBox_time {
  display: inline-block;
  margin-left: 10px;
}
.SynchroniseBox .ant-time-picker-input {
  height: 20px !important;
  font-size: 12px !important;
}
.SynchroniseBox .ant-time-picker-icon {
  font-size: 12px !important;
  margin-top: -5px !important;
}
.SynchroniseMonth {
  width: 210px !important;
}
.SynchroniseMonth .ant-select-dropdown-menu-item {
  width: 33.33333333%;
  display: inline-block;
  flex-direction: row !important;
}
.SynchroniseBox_ul {
  display: inline-block;
  margin: 0 !important;
  padding: 0 0 0 10px;
}
.SynchroniseBox_ul li {
  display: inline-block;
  list-style: none;
  text-decoration: none;
  padding: 0 5px;
  color: #F58320;
  cursor: pointer;
}
.SynchroniseDropdown .ant-select-dropdown-menu-item-selected,
.SynchroniseDropdown .ant-select-dropdown-menu-item-selected:hover {
  color: #F58320 !important;
}
.SynchroniseDropdown li.ant-time-picker-panel-select-option-selected {
  color: #F58320 !important;
}
.ant-time-picker-panel-select-option-selected {
  color: #F58320 !important;
}
.companyName {
  font-size: 12px;
  padding: 0 20px;
  color: #ffffff;
  opacity: 0.8;
}
.BulkOperationTitle {
  display: inline-block;
  padding-left: 10px;
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 12px;
}
.BulkOperationBox {
  background-color: #f9f9f9;
  margin-left: 10px;
  padding: 25px;
}
.BulkOperationBox p {
  text-align: center;
  color: #aeaeae;
  font-size: 12px;
}
.dataSelete-tree .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: #fff !important;
}
.fontst {
  font-size: 12px;
  color: #333333;
}
.ant-tree-node-content-wrapper:hover .iconhover {
  display: inline-block !important;
  cursor: pointer;
}
.versionstips {
  font-size: 12px !important;
  color: #333333;
}
.Configuration-row .ant-select-dropdown-menu-item .ant-select-dropdown-menu-item-selected .ant-select-selected-icon {
  color: #F58320 !important;
}
.Configuration-row .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  color: #F58320 !important;
}
.Configuration-row .ant-select-dropdown-menu-item-selected,
.Configuration-row .ant-select-dropdown-menu-item-selected:hover {
  color: #F58320 !important;
}
.adminfrom .ant-form-item {
  margin-bottom: 10px !important;
}
.adminfrom .ant-form-item-required::before {
  display: none!important;
}
.todefault-from.ant-form-item {
  vertical-align: middle !important;
}
.todefault-from.ant-form-item .ant-form-item-control {
  line-height: 1 !important;
}
.min-height-ldap {
  min-height: calc(100vh - 218px);
}
.display_none {
  display: none !important;
}
.moreDrop .ant-dropdown-menu-submenu-arrow {
  display: none !important;
}
.labelbyself {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.labelNew {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.usersE_.ant-btn-edit {
  border: 0 !important;
}
.resultcss {
  display: inline-block;
  width: 220px;
  font-size: 12px;
  text-align: left;
}
.newSearchBox {
  width: 400px;
  position: absolute;
  z-index: 99;
  background: #ffffff;
  box-shadow: 0px 0px 5px 2px #e4e4e4;
  border-radius: 3px;
  margin-top: 1px;
}
.TotalCompany {
  position: absolute;
  margin-top: 5px;
  color: #999999;
}
.prdocutList span {
  display: block;
  padding: 2px;
}
.inputEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.forgotPassword {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  height: 100vh;
  background: #F58320;
  overflow: hidden;
}
@media (max-width: 1366px) {
  .forgotPassword .signUp-wrap-wrap {
    transform: scale(0.7);
  }
}
@media screen and (min-width: 1366px) and (max-width: 1919px) {
  .forgotPassword .signUp-wrap-wrap {
    transform: scale(0.875);
  }
}
.forgotPassword .signUp-wrap-wrap {
  position: relative;
  display: block;
  min-width: 650px;
}
.forgotPassword .signUp-wrap-wrap .line_1 {
  width: 100vw;
  height: 100vmax;
  background: #ff9100;
  position: absolute;
  top: 300px;
  -webkit-transform: rotate(-34deg) skewY(45deg);
  transform: rotate(-56deg) skewY(21deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.forgotPassword .signUp-wrap-wrap .line_2 {
  width: 100vw;
  height: 100vh;
  background: #f57520;
  position: absolute;
  top: 120px;
  -webkit-transform: rotate(-34deg) skewY(45deg);
  transform: rotate(-56deg) skewY(21deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.forgotPassword .signUp-wrap-wrap .signUp-wrap {
  box-shadow: 20px 20px 1px 2px;
  background: #fff;
  border-radius: 20px;
  justify-content: flex-start;
  display: flex;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
  padding: 10%;
  width: 100%;
}
.forgotPassword .signUp-wrap-wrap .signUp-title {
  display: none;
  width: 450px;
  text-align: center;
  color: #484848;
  background: #ffffff;
}
.forgotPassword .signUp-wrap-wrap .signUp-title .foxit-logo {
  margin-bottom: 20px;
}
.forgotPassword .signUp-wrap-wrap .signUp-title span {
  font-size: 20px;
}
.forgotPassword .signUp-wrap-wrap .signUp-title p {
  font-size: 13px;
  margin: 20px auto 50px;
  line-height: 1.8;
}
.forgotPassword .signUp-wrap-wrap .signUp-title .table-i {
  width: 222px;
}
.forgotPassword .signUp-wrap-wrap .signUp-form {
  width: 100%;
  color: #d6d6d6;
  background: #fff;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-form-item {
  min-height: 65px;
  margin-bottom: 0;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-form-item .ant-form-explain {
  font-size: 12px!important;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .foxit-logo {
  margin-bottom: 70px;
  width: 81px;
  height: 33px;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .signUp-logo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .signUp-logo p.title {
  vertical-align: text-bottom;
  font-size: 34px;
  text-align: center;
  display: inline-block;
  color: #333333;
  margin: 0 0 20px 0;
  font-weight: bold;
  font-family: "Segoe UI", "Open Sans", Verdana, "Microsoft YaHei", Arial, sans-serif;
  line-height: 1;
  white-space: nowrap;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .signUp-logo p.explain {
  font-size: 14px;
  text-transform: capitalize;
  display: inline-block;
  color: #999999;
  text-align: justify;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .iconMail,
.forgotPassword .signUp-wrap-wrap .signUp-form .iconPassword {
  display: flex;
  margin-right: 10px;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .pwdInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .pwdInfo span {
  color: #999999;
  padding: 0;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-input-affix-wrapper .ant-input:not(:first-child) {
  font-size: 16px;
  padding-left: 40px;
  border: none;
  border-bottom: 1px solid #f58320;
  border-radius: 0;
  outline: none!important;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-input-affix-wrapper .ant-input::-webkit-input-placeholder {
  font-size: 16px;
}
.forgotPassword .signUp-wrap-wrap .signUp-form input:-internal-autofill-previewed,
.forgotPassword .signUp-wrap-wrap .signUp-form input:-internal-autofill-selected {
  transition: background-color 5000s ease-in-out 0s !important;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-checkbox-checked .ant-checkbox-inner,
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-checkbox-inner {
  background-color: #F58320;
  border-color: #F58320;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-checkbox-wrapper {
  color: #d6d6d6;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-btn-primary {
  color: #fff;
  background-color: #F58320;
  border-color: #F58320;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  border-radius: 4px;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-btn-primary:hover {
  background-color: #ff9a44;
  border-color: #ff9a44;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-input {
  outline: none;
  border: none;
  color: #666666;
  font-size: 16px;
  line-height: 1.42857143;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-input::-webkit-input-placeholder {
  color: #666666;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .ant-input:focus {
  box-shadow: none;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .wrapBtn {
  width: 100%;
}
.forgotPassword .signUp-wrap-wrap .signUp-form .wrapBtn a {
  color: red;
}
.forgotPassword .signUp-wrap-wrap .SignUpGuide {
  color: #aaaaaa;
  width: 450px;
  font-size: 14px;
  text-align: left;
}
.forgotPassword .icon-captchat::before {
  font-weight: 600 !important;
}
.forgotPassword .signUp-icon {
  padding-bottom: 20px;
  text-align: center;
}
.forgotPassword .icon-expired::before,
.forgotPassword .icon-success::before {
  font-size: 60px;
}
.forgotPassword .Captchat .ant-form-item-control {
  width: 100%;
}
.forgotPassword .Captchat .ant-form-item-children {
  width: 100%;
}
.forgotPassword .Captchat .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  width: 65%;
  vertical-align: middle;
}
.forgotPassword .Captchat .code_Captchat {
  margin-left: 2%;
  width: 33%;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
}
.forgotPassword .Captchat .code_Captchat img {
  width: 100%;
  height: 100%;
}
.forgotPassword .Captchat .clickNone {
  pointer-events: none;
  cursor: not-allowed !important;
}
.main.AdAuthentication_container {
  background: linear-gradient(#ffffff 50%, #F3F5F7 50%);
}
.main .main-cotent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
}
.main .main-cotent .detail-title {
  text-align: center;
  font-weight: bold;
}
.main .main-cotent .stepsContainer {
  position: relative;
}
.main .main-cotent .stepsContainer .wrap_step {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.main .main-cotent .stepsContainer .wrap_step .goPrev {
  width: 35%;
  height: 64px;
  position: absolute;
  left: 0;
  top: 0;
}
.main .main-cotent .stepsContainer .wrap_step .goPrev_step2 {
  cursor: pointer;
}
.main .main-cotent .stepsContainer .wrap_step .stepsSelf {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.main .main-cotent .stepsContainer .wrap_step .stepsSelf .stepItem {
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  opacity: 0.3;
}
.main .main-cotent .stepsContainer .wrap_step .stepsSelf .stepItem.act {
  opacity: 1;
}
.main .main-cotent .stepsContainer .wrap_step .stepsSelf .stepItem .stepNum {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #F58320;
  border-radius: 50%;
  color: #ffffff;
  margin-bottom: 10px;
}
.main .main-cotent .stepsContainer .progressBorder {
  border-bottom: 1px dashed #F58320;
  opacity: 0.3;
  margin-top: 18px;
  width: calc(100%  - 242px );
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.main .main-cotent .stepsContainer .progressBorder.nextStep {
  opacity: 1;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item {
  display: flex;
  flex-direction: column;
  width: 250px;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item.ant-steps-item-wait {
  opacity: 0.5 !important;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item .ant-steps-item-icon {
  background: #F58320 !important;
  border: none;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
  color: #ffffff !important;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item .ant-steps-item-title::after {
  background-color: transparent;
  height: 0;
  border-bottom: 1px dashed #F58320;
  opacity: 0.3;
  display: none;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: transparent;
  opacity: 1;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item-wait {
  opacity: 0.3;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item:nth-child(1) .ant-steps-item-icon {
  margin-left: 80px;
}
.main .main-cotent .stepsContainer .ant-steps .ant-steps-item:nth-child(2) .ant-steps-item-icon {
  margin-left: 100px;
}
.main .main-cotent .stepsContainer .stepContainer1 .ant-form .ant-form-item-required::before {
  content: '*' !important;
}
.main .main-cotent .stepsContainer .stepContainer1 .ant-form .ant-form-explain {
  font-size: 12px;
}
.main .main-cotent .stepsContainer .userGuide {
  padding: 0px 30px 10px 30px;
  background: #f9f9f9;
}
.main .main-cotent .stepsContainer .userGuide .wrapInput {
  display: flex;
}
.main .main-cotent .stepsContainer .userGuide .wrapInput .ant-input {
  width: 325px;
  margin-right: 10px;
  background: #f3f3f3;
  border: 1px solid #b7b7b7;
}
.main .main-cotent .stepsContainer .userGuide .CollapseHeader {
  display: flex;
  justify-content: space-between;
}
.main .main-cotent .stepsContainer .userGuide .CollapseHeader span:nth-child(1) {
  font-weight: bold;
}
.main .main-cotent .stepsContainer .userGuide .CollapseHeader .btnRight {
  color: #3b73af;
}
.main .main-cotent .stepsContainer .userGuide .wrapCode {
  display: flex;
}
.main .main-cotent .stepsContainer .userGuide .wrapCode .detailCode {
  white-space: nowrap;
}
.main .main-cotent .stepsContainer .userGuide .wrapCode .wrapLine {
  text-align: right;
  width: 20px;
  margin-right: 10px;
  padding-right: 5px;
  border-right: 1px solid #666666;
}
.main .main-cotent .stepsContainer .guideTitle {
  padding-top: 15px;
  color: #F58320;
  cursor: pointer;
}
.securityContainer .from-wrap .ant-form-item-control-wrapper {
  max-width: 400px;
}
.DissolveBox {
  background-color: #f9f9f9;
  padding: 0px 12px;
  border: 2px solid #eeeeee;
  max-width: 720px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.DissolveTitlte {
  display: inline-block;
  padding-bottom: 8px;
}
.DissolveBtn {
  padding-top: 8px;
}
.editable-cell {
  position: relative;
}
.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}
.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}
.mactable .ant-table-placeholder {
  border-bottom: 1px solid #e8e8e8 !important;
  padding: 0px !important;
}
.mactable .ant-input-group-sm .ant-input,
.mactable .ant-input-group-sm > .ant-input-group-addon {
  padding: 5px !important;
  text-align: center;
}
.macerror {
  font-size: 12px;
  color: red;
  text-align: left;
}
.Configuration-check {
  color: #333333 !important;
}
.new-cotent-from .ant-empty-description {
  margin: 0 auto !important;
}
.noExpand .ant-table-row-expand-icon {
  display: none;
}
.edittimeform {
  margin: 20px 0 0 0;
}
.edittimeform .ant-form-item-label > label::after {
  content: '' !important;
}
.edittimeform .ant-form-item-required::before {
  margin-right: 0 !important;
}
.macinput .has-error .ant-form-explain,
.has-error .ant-form-split {
  display: none;
}
.sche-model .transfer-wrap-box {
  width: 340px !important;
  height: 300px !important;
}
.approve-modal .transfer-wrap-box {
  width: auto !important;
  height: 200px !important;
}
.approve-modal .selectTips {
  padding-top: 20%;
}
.none-c {
  opacity: 0.5;
}
.approve_ul {
  padding: 10px;
  margin: 0;
}
.approve_p {
  opacity: 0.4;
  text-align: left;
  margin: 0;
}
.approve_li {
  list-style: none;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 92% !important;
  font-size: 12px;
  text-align: left;
}
.approve_li .ant-tree-switcher-noop i {
  display: none;
}
.approve_li .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background-color: #fff !important;
}
.approve-modal .transfer-list-content {
  overflow: scroll;
  height: 100%;
}
.approve-modal .transfer-wrap-box .ant-tree-title {
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100% !important;
  font-size: 12px;
  text-align: left;
}
.approve-modal .ant-col-2 .ant-input {
  padding: 0;
  text-align: center;
}
.icon_pd {
  padding-left: 10px;
}
.approve_li .ant-tree {
  display: inline-block;
}
.mactable .ant-table-tbody > tr:hover > td {
  background: none !important;
}
.cvsmodal {
  width: 540px !important;
}
.cvsmodal .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}
.nomailp {
  color: #888888 !important;
}
.accessdenied .ant-empty {
  position: relative;
}
.accessdenied .ant-empty-description {
  width: 100% !important;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 45px;
  font-size: 14px;
}
.accessdenied .title {
  font-size: 22px!important;
  font-weight: bold;
  color: #444444;
}
.accessdenied button {
  font-size: 14px;
  background-color: #444444;
  border: 1px solid #444444;
}
.accessdenied .ant-empty-image {
  height: auto !important;
}
.Skeleton-main {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 45%;
}
.Skeleton-main .loading {
  height: 20px;
  margin: 0 auto;
  text-align: center;
}
.Skeleton-main .loading span {
  display: inline-block;
  width: 20px;
  height: 100%;
  margin-right: 15px;
  background: #F58320;
  animation: load 1.04s ease infinite;
  -webkit-animation: load 1.04s ease infinite;
}
.Skeleton-main .loading span:last-child {
  margin-right: 0px;
}
@keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.Skeleton-main .loading span:nth-child(1) {
  animation-delay: 0.13s;
  -webkit-animation-delay: 0.13s;
}
.Skeleton-main .loading span:nth-child(2) {
  animation-delay: 0.26s;
  -webkit-animation-delay: 0.26s;
}
.Skeleton-main .loading span:nth-child(3) {
  animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
}
.Skeleton-main .loading span:nth-child(4) {
  animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
}
.Skeleton-main .loading span:nth-child(5) {
  animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
}
.Skeleton-main .loading span:nth-child(6) {
  animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
}
.Skeleton-main .tips {
  padding-top: 15px;
}
.CustomizeOrganization {
  padding-bottom: 0 !important;
}
.CustomizeOrganization .ant-layout.ant-layout-has-sider {
  width: 100%;
}
.CustomizeOrganization .ant-tree {
  padding: 10px ;
  color: #999999 !important;
}
.CustomizeOrganization .ant-tree.ant-tree-show-line li:not(:last-child):before {
  display: none ;
}
.CustomizeOrganization .ant-tree.ant-tree-show-line li::before {
  display: none;
}
.CustomizeOrganization .anticon-file {
  display: none;
}
.CustomizeOrganization .linkbtn {
  border: 1px #fff solid;
  box-shadow: none;
  width: 60%;
  color: #333333;
  cursor: pointer;
}
.CustomizeOrganization .linkbtn:hover {
  opacity: 1 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #d3d3d3 !important;
  height: 32px!important;
}
.CustomizeOrganization .ant-modal-body {
  padding: 10px;
}
.CustomizeOrganization .ant-tree-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 80%;
  color: #333333 !important;
  vertical-align: middle;
}
.CustomizeOrganization .ant-tree-iconEle {
  float: right;
}
.CustomizeOrganization .NodeSytle {
  display: block;
  padding-bottom: 10px;
}
.CustomizeOrganization .AddMembers {
  padding: 0 0 10px 0;
}
.deFrom .ant-form-item-label {
  text-align: left !important;
}
.deFrom .ant-form-item {
  margin-bottom: 5px !important;
}
.deFrom .ant-form.ant-form-horizontal .ant-form-item-label {
  min-height: 1px !important;
}
.oudrp {
  font-size: 12px;
  color: #333333 !important;
}
.oudropBnt {
  min-width: 0 !important;
  width: 0!important;
  height: 20px!important;
  border: 0!important;
  background-color: transparent !important;
}
.ouInput {
  width: 120px;
  height: 28px;
  line-height: 1.5;
  vertical-align: middle;
}
.ouInputList {
  padding-bottom: 10px;
}
.CustomizeOrganization.ant-tree-show-line li:not(:last-child):before {
  display: none ;
}
.CustomizeOrganization.ant-tree-show-line li::before {
  display: none;
}
.changIcon > *:first-child {
  display: none !important;
}
.changIcon > *:nth-child(2) {
  width: 100% !important;
  max-width: 100% !important;
}
.iconhover:hover {
  color: #F58320 !important;
}
.LdapTreeNew {
  height: auto !important;
}
.LdapTreeNew .ant-tree.ant-tree-show-line li::before {
  height: calc(97%) !important;
}
.LdapTreeNew .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: #fff !important;
}
.LdapTreeNew .ant-tree li ul {
  padding: 0 0 0 15px !important;
}
.support {
  margin: 0 auto;
}
.support .supportTitle {
  width: 100%;
  text-align: center;
  padding: 100px 0 0 0;
}
.support .TitleOne {
  width: 100%;
  font-size: 24px;
  padding-bottom: 20px;
}
.support .TitleTwo {
  width: 50%;
  font-size: 16px;
  display: inline-block;
}
.support .ant-result-title {
  font-size: 18px !important;
  padding-bottom: 20px;
}
.support .ant-result-extra {
  margin-top: 16px !important;
}
.support .ant-result {
  padding: 32px !important;
}
.support .supportBox {
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 5px 3px #e4e4e4;
  border-radius: 6px ;
  min-width: 288px;
}
.support .ant-btn-primary {
  width: 146px;
  height: 30px;
}
.support .subTitle p {
  margin: 0 !important;
}
::selection {
  background: #F58320 !important;
  color: #fff;
}
html {
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
  -ms-scrollbar-width: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Open Sans", Verdana, "Microsoft YaHei", Arial, sans-serif;
  color: #333333 !important;
  min-width: 600px;
}
#root {
  height: 100%;
}
.textCenter {
  text-align: center;
}
.ant-layout {
  min-height: 100%;
}
.ant-layout .logo {
  height: 60px;
  background: #F58320;
}
.ant-layout .ant-layout-sider-collapsed .anticon {
  font-size: 16px;
}
.ant-layout .ant-layout-sider-collapsed .nav-text {
  display: none;
}
.ant-layout .ant-layout-sider-collapsed .ant-menu-submenu-vertical > .ant-menu-submenu-title:after {
  display: none;
}
.ant-layout .ant-layout-sider-collapsed .ant-menu-dark:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: inherit;
}
.ant-layout p {
  margin: 10px 0 10px 0;
}
.ant-menu-item-selected .icon:before,
.ant-menu-submenu-open.ant-menu-submenu-selected .icon:before {
  color: #F58320 !important;
}
.ant-menu-item .icon,
.ant-menu-submenu-title .icon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 16px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.gutter-example {
  flex: 1 1;
}
.gutter-example .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  padding: 0 0 16px;
}
:global .ant-card-head-title {
  font-size: 14px !important;
}
/*
configuration
*/
.main {
  background-color: #ffffff;
  height: 100%;
  overflow-y: scroll ;
}
.main-new {
  overflow-x: hidden;
  overflow-y: scroll ;
  background: linear-gradient(#fff 50%, #f3f5f7 0);
  height: 100%;
}
.main-title {
  padding: 10px 20px 10px;
  margin: 0 !important;
  position: relative;
  white-space: nowrap;
  background: #ffffff;
}
.main-title > p {
  font-weight: bold;
  margin-top: 0;
  display: inline-block;
}
.main-title .icon-info {
  color: #2E6DAD;
  margin-right: 5px;
}
.main-title .cotent-tips {
  height: 35px;
  font-size: 12px;
  display: inline-block;
  background-color: #F5FAFF;
  padding: 0 10px;
  margin-left: 5px;
  border: 1px solid #E8E8E8;
}
.main-title .cotent-tips p {
  height: 35px;
  line-height: 35px;
  color: #666666;
  display: inline-block;
  margin: 0;
}
.main-title .notification-custom {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #ffeef0;
  border: 1px solid #f9d0d1;
  color: #ff0000;
  z-index: 2;
}
.main-title .notification-custom .icon-info,
.main-title .notification-custom .icon-info:before {
  color: #ff0000;
}
@media (max-width: 1366px) {
  .main-title .cotent-tips {
    opacity: 0;
  }
}
.main-cotent {
  width: 100%;
  background-color: #ffffff;
  border: 16px solid #edf1f6;
  position: relative;
  height: 100%;
  min-height: calc(100vh -  118px);
}
.main-cotent .wrap-tab .ant-spin-nested-loading {
  padding-top: 0;
}
.main-cotent .wrap-tab .ant-table-title {
  padding: 10px 0;
}
.main-cotent .wrap-tab .btn-tb {
  margin-top: 20px;
}
.main-cotent .cotent {
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  height: 100%;
}
.main-cotent .cotent .cotent-item {
  padding-bottom: 50px;
}
.main-cotent .menu-bottom {
  border-top: 1px solid #e8e8e8;
  padding: 15px 0 15px 50px;
}
.cotent-from {
  padding: 10px 0px 0px 30px;
  width: 100%;
}
.cotent-from main {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.cotent-from section {
  display: table-row;
  border-bottom: 1px solid #f9f9f9;
}
.cotent-from aside {
  display: table-cell;
  width: 25%;
  line-height: 50px;
}
.cotent-from article {
  display: table-cell;
  width: 70%;
}
.cotent-from article a {
  color: #F58320;
}
.tree-main {
  width: 100%;
}
.cotent-tree {
  width: 100%;
  height: 180px;
  border: 1px solid #bfbfbf;
  overflow-y: scroll;
}
.cotent-tree .ant-tree li .ant-tree-node-content-wrapper {
  font-size: 12px;
}
.cotent-tree .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #e5e5e5 !important;
}
.cotent-tree .ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #e5e5e5 !important;
}
.cotent-tree::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #ffffff;
}
.cotent-tree::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #eeeeee;
}
.tree-tip {
  color: #a0a0a0;
  background-color: #f5faff;
  font-size: 12px;
  margin-top: 20px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 16px 10px;
  width: 313px;
  position: fixed;
}
.tree-title {
  vertical-align: middle;
  padding-bottom: 5px;
}
.tree-title span {
  color: #333333;
}
.tree-tip p {
  color: #999999;
  word-break: break-all;
}
.content-tip {
  color: #aaaaaa;
  font-size: 12px;
  padding-top: 10px;
}
.info-content-tip {
  background-color: #f0f5f9;
  width: 47%;
  padding: 5px;
}
.info-content-tip p {
  color: #a0a0a0;
  margin-left: 30px !important;
  width: 70%;
}
.manual-btn {
  background-color: #F58320 !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  border-color: #F58320 !important;
  margin-left: 20px;
}
.kong-btn {
  background-color: #ffffff !important;
  color: #F58320 !important;
  border-radius: 5px !important;
  border-color: #F58320 !important;
  margin-left: 20px;
}
.input-text {
  display: inline-block;
  width: 70px;
}
.input-tr {
  margin-bottom: 10px;
}
.btn-list {
  float: right;
  margin-top: 20px;
}
.btn-list button {
  padding: 0px 30px;
}
/*
version
*/
.version-main {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh -  140px);
  height: 100%;
}
.version-main .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fdeeea !important;
  color: #F58320 !important;
}
.version-main .ant-menu-item-selected::before {
  border-right: 0!important;
  right: 0;
  left: auto;
}
.version-main .ant-menu-item-selected::after {
  border-right: 2px solid #F58320 !important;
}
.version-main .ant-menu-item::after {
  border-right: 2px solid #F58320 !important;
}
.version-input-tr {
  padding: 10px 0px;
}
.down-down {
  margin-top: 10px;
}
.ant-empty-description {
  margin: 0 auto !important;
  width: 35%;
}
/* Dashboard */
.charts-wrap.text-uppercase {
  text-transform: uppercase;
}
.charts-wrap .ant-card-head {
  border-bottom: transparent;
}
.charts-wrap .ant-card-head-title {
  padding-bottom: 0;
}
.charts-wrap .ant-card-body {
  padding-top: 0;
}
.charts-wrap .ant-card-extra {
  color: #F58320;
  padding-bottom: 0;
}
.dl-wrap dd {
  margin: 0;
}
/* LicenseManagement */
.cotent-wrap .ant-table-tbody > tr.ant-table-row-selected td {
  background-color: #fef5f3;
}
@media (min-width: 575px) {
  .cotent-wrap .ant-advanced-search-form .ant-form-item {
    display: flex;
    margin-bottom: 6px;
  }
  .cotent-wrap .ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.cotent-wrap .from-wrap .ant-form-item {
  min-height: 65px;
  margin-bottom: 0!important;
}
.cotent-wrap .from-wrap .ant-form-item-with-help {
  margin-bottom: 24px;
}
.cotent-wrap .ant-table-title {
  padding: 8px 0;
}
.cotent-wrap .ant-checkbox-wrapper {
  color: #606060;
  font-size: 12px;
  font-weight: 100;
}
.cotent-wrap .input-number-wrap .ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-modal-content {
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.55) !important;
}
.ant-modal-content .ant-modal-header {
  background: #F6F6F6 !important;
}
.ant-modal-content .transfer-wrap {
  width: 50%;
}
.ant-modal-content .transfer-wrap .transfer-list-body {
  height: 300px;
}
.ant-modal-content .transfer-wrap .transfer-list-body.l {
  border-right: 1px solid #d9d9d9;
}
.ant-modal-content .transfer-wrap .transfer-list-body .transfer-list-content {
  height: 100%;
  margin: 0;
  padding: 3px 5px;
  overflow: auto;
  list-style: none;
}
.ant-modal-content .transfer-wrap .transfer-list-body .transfer-list-content .transfer-list-content-item {
  padding: 2px 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-modal-content .transfer-wrap .transfer-list-body .transfer-list-content .transfer-list-content-item .anticon-close-circle {
  display: none;
}
.ant-modal-content .transfer-wrap.r .transfer-list-content-item:hover {
  background-color: #fef2ef;
}
.ant-modal-content .transfer-wrap.r .transfer-list-content-item:hover .anticon-close-circle {
  display: inline-block;
  cursor: pointer;
}
.ant-modal-content .transfer-wrap.l .transfer-list-content-item:hover {
  background-color: #fef2ef;
}
.ant-modal-content .transfer-wrap .ant-checkbox-inner {
  border-radius: 50%;
}
.ant-modal-content .transfer-wrap .ant-tree {
  padding: 3px 0;
  height: 100%;
  overflow: scroll;
}
.ant-modal-content .transfer-wrap .ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  cursor: Default;
  height: 24px;
}
.ant-modal-content .transfer-wrap .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  position: absolute;
  right: 0;
}
.ant-modal-content .transfer-wrap .ant-tree .ant-tree-node-content-wrapper .ant-tree-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 95%;
}
.ant-modal-content .transfer-wrap .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #ffffff;
}
.ant-modal-content .transfer-wrap .ant-tree .ant-tree-treenode-disabled {
  cursor: Default;
}
.ant-modal-content .transfer-wrap .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  cursor: Default;
}
.ant-modal-content .transfer-wrap .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper span {
  cursor: Default;
  color: #000000;
}
.ant-modal-content .ant-checkbox-wrapper {
  color: #606060;
}
.ant-modal-content .ant-btn.ant-btn-primary {
  background-color: #F58320;
  border-color: #F58320;
  color: #ffffff;
}
.ant-modal-content .ant-btn.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-modal-content .ant-btn.ant-btn-primary:hover {
  background-color: #F58320;
  border-color: #F58320;
  color: #ffffff;
}
.inline-model-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-warap .menu-content .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  margin: 0 0 5px;
}
.menu-warap .menu-content .ant-checkbox-wrapper:last-child {
  margin: 0;
}
.upload-wrap {
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
}
.table-custom {
  height: 140px;
  overflow-x: scroll;
}
.table-custom .ant-table-thead tr th {
  color: #aeaeae;
  border-bottom-color: transparent !important;
  padding: 2px 8px 4px !important;
}
.table-custom .ant-table-tbody > tr > td {
  border-bottom-color: transparent !important;
  padding: 0 8px !important;
}
.table-custom .ant-table-small {
  border-color: transparent !important;
}
.ant-select-selection .ant-upload {
  color: #F58320;
}
.table-wrap {
  /*定义滚动条的轨道，内阴影及圆角*/
  /*定义滑块，内阴影及圆角*/
}
.table-wrap .ant-table-body {
  overflow-x: auto !important;
}
.table-wrap ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(245, 245, 245, 0.47);
}
.table-wrap ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.table-wrap ::-webkit-scrollbar-thumb {
  /*width: 10px;*/
  height: 20px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(85, 85, 85, 0.25);
}
/* Products */
.cards-wrap {
  background-color: #f9f9f9;
}
.cards-wrap .product-card {
  border: 1px solid #f1f1f1;
  background-color: #ffffff;
  color: #666970;
}
.cards-wrap .product-card .product-logo {
  text-align: center;
}
.cards-wrap .product-card .product-logo img {
  width: 50px;
  height: 50px;
}
.cards-wrap .product-card .ant-typography {
  color: #666970;
}
.color-middle {
  color: #666970;
}
.color-weak {
  color: #c7c8ca;
}
/* Reports */
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #fef2ef !important;
}
.weak .ant-radio-button-wrapper {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border: 1px solid #d9d9d9;
}
.weak .ant-radio-button-wrapper:first-child {
  border: 1px solid #d9d9d9;
}
.weak .ant-radio-button-wrapper-checked:first-child,
.weak .ant-radio-button-wrapper-checked {
  color: #F58320 !important;
  border-color: #F58320;
  box-shadow: none !important;
}
.weak .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #d9d9d9;
}
.no-required-i .ant-form-item-required::before {
  display: none;
}
/* Header */
.header {
  height: 60px;
  position: fixed;
}
.header .foxit-logo {
  width: 75px;
}
.header .logo-wrap span {
  margin: 0 0 0 10px;
  color: #ffffff;
  font-size: 16px;
}
.header .ant-menu-horizontal {
  border: none;
}
.header .ant-menu-item input::-webkit-input-placeholder,
.header .ant-menu-item textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.header .ant-menu-item input:-moz-placeholder,
.header .ant-menu-item textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.header .ant-menu-item input:-ms-input-placeholder,
.header .ant-menu-item textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.header .ant-menu-item input::-webkit-outer-spin-button,
.header .ant-menu-item input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
.header .ant-menu-item .ant-input {
  vertical-align: middle;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
}
.header .ant-menu-item .ant-input:hover,
.header .ant-menu-item .ant-input:focus {
  border-color: #fff;
}
.header .ant-menu-item .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #fff;
}
/* Initialization */
.content-wrap .container-wrap {
  padding: 8% 45px;
  margin-right: auto;
  margin-left: auto;
}
.content-wrap .waek-bg .activation-wrap {
  width: 380px;
  height: 300px;
  padding: 20px 20px 60px;
}
@media (min-width: 768px) {
  .content-wrap .container-wrap {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .content-wrap .container-wrap {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .content-wrap .container-wrap {
    width: 1170px;
  }
}
.white-bg {
  padding: 8% 70px;
}
.white-bg .border {
  border: 1px dashed #d9d9d9;
  padding: 30px 30px 40px;
}
.white-bg .mode-wrap {
  padding: 30px 30px 40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 280px;
  min-height: 246px;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 12px;
}
.white-bg .mode-wrap .bg-good {
  display: none;
}
.white-bg .mode-wrap:hover {
  border: 1px solid #F58320;
  transform: scale(1.01);
  -ms-transform: scale(1.01);
  /* IE 9 */
  -moz-transform: scale(1.01);
  /* Firefox */
  -webkit-transform: scale(1.01);
  /* Safari 和 Chrome */
  -o-transform: scale(1.01);
}
.white-bg .mode-wrap.clicked {
  border: 1px solid #F58320;
  position: relative;
}
.white-bg .mode-wrap.clicked .bg-good {
  display: inline-block;
  position: absolute;
  top: -1px;
  right: -1px;
}
.white-bg .mode-wrap.clicked .icon-good {
  position: absolute;
  top: 10px;
  right: 5px;
}
.white-bg .mode-wrap + .mode-wrap {
  margin-left: 90px;
}
.waek-bg {
  padding: 30px 40px 60px;
  background-color: #ffffff;
  margin: auto -24px -24px;
}
.waek-bg .activation-wrap {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  border: transparent;
  padding: 40px 20px 60px;
  background-color: #ffffff;
  width: 45%;
  font-size: 12px;
}
.waek-bg .activation-wrap.dragger {
  padding: 0;
  border: transparent;
}
.waek-bg .activation-wrap.dragger .ant-upload.ant-upload-drag {
  background: #ffffff;
}
.waek-bg .activation-wrap.dragger .ant-upload.ant-upload-drag .ant-upload-btn {
  padding: 30px 40px 60px;
}
.waek-bg .activation-wrap .ant-typography {
  display: block;
  min-height: 80px;
}
.waek-bg .activation-wrap + .activation-wrap {
  margin-left: 90px;
}
.dragger .ant-upload-list {
  display: none;
}
.modal-message .ant-message {
  position: absolute;
}
.mg-b .ant-form-item {
  margin-bottom: 10px !important;
}
.mg-b .ant-calendar-picker {
  width: 100%;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 0px !important;
}
@media (min-width: 575px) {
  .ant-advanced-search-form .ant-form-item {
    display: flex;
  }
  .ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.invited-action a {
  color: #F58320;
  margin-right: 10px;
}
.ldap-action a {
  color: #F58320;
}
.color_btn_text {
  color: #F58320;
  padding-left: 5px;
}
.details_list {
  padding: 0px 20px;
}
.details_list > .ant-descriptions {
  padding-bottom: 30px;
}
.details_list .ant-descriptions-item > span {
  text-align: right;
  width: 120px;
}
.details_list .ant-descriptions-item-content {
  text-align: left !important;
}
.seach_package {
  width: 100%;
}
.Login-span span:last-child {
  width: auto !important;
  vertical-align: top;
}
.Login-span a {
  font-weight: bold;
  cursor: default;
}
.Login-span li {
  list-style: none;
  padding-bottom: 10px;
}
.Login-span li button {
  background: none;
  color: #F58320;
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}
.time-span span:last-child {
  width: auto !important;
  vertical-align: top;
}
.invited_tips {
  margin: 0 0 15px 0 !important;
  width: 100%;
  padding: 5px 10px !important;
}
.invited_tips p {
  margin: 0;
  font-size: 12px;
}
.invited_tips a {
  color: #F58320;
}
.desc-i {
  width: 200px;
}
.ant-btn-initi {
  background-color: #7bc76f !important;
  border-color: #7bc76f !important;
  color: #ffffff !important;
}
.ant-btn-initi-white {
  background-color: #ffffff !important;
  border-color: transparent !important;
  color: #7bc76f !important;
}
/* MailServers */
.table-wrap.r {
  position: relative;
  width: 100%;
}
.table-wrap.r .ant-table-small .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th {
  background-color: #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
.table-wrap.r .ant-table-small .ant-empty-normal {
  margin: 0;
}
.table-wrap .ant-table-thead > tr > th .ant-table-header-column {
  font-weight: bold;
}
.hlep-s .ant-form-explain {
  font-size: 12px;
}
.hlep-s .ant-form-item-control input,
.hlep-s .ant-form-item-control .ant-select {
  max-width: 300px;
}
.hlep-s .ant-form-item {
  margin-bottom: 20px;
}
.hlep-s .ant-input-number,
.hlep-s .ant-input-affix-wrapper {
  margin: 0;
  width: 300px;
}
.hlep-s.ant-form-vertical .ant-form-item-control input,
.hlep-s.ant-form-vertical .ant-form-item-control .ant-select {
  max-width: 500px;
}
/* SAMLConfiguration */
.add-saml-wrap .cotent-tips {
  background-color: #f8f8f8;
  color: rgba(50, 50, 50, 0.85);
}
.add-saml-wrap .cotent-tips strong {
  color: #333;
}
/* Domain */
.radio-button-wrap .ant-radio-button-wrapper-checked {
  background: #F58320 !important;
}
.user_divider {
  border-right: 1px solid #eeeeee;
  min-height: 300px;
}
.usered_title {
  padding-top: 15px;
  display: inline-block;
  font-weight: 400;
}
.usered_seleted {
  padding-top: 15px;
  display: inline-block;
  float: right;
}
.group_list_item {
  border: none !important;
  cursor: pointer;
  padding: 2px !important;
}
.group_list_item_select {
  border: none !important;
  cursor: pointer;
  background-color: #fdf1eb;
  padding: 2px !important;
  display: block;
}
.view_model {
  /*定义滚动条的轨道，内阴影及圆角*/
  /*定义滑块，内阴影及圆角*/
}
.view_model .ant-modal-body {
  margin: 21px 27px !important;
  height: 250px;
  background: #f8f8f8;
  overflow-y: scroll;
  padding: 6px !important;
  border: 1px solid #d8d8d8;
}
.view_model ::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: rgba(245, 245, 245, 0.47);
}
.view_model ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f8f8f8;
  border-radius: 10px;
  background-color: #f8f8f8;
}
.view_model ::-webkit-scrollbar-thumb {
  /*width: 10px;*/
  height: 20px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(85, 85, 85, 0.25);
}
.t-c {
  text-align: center!important;
}
.pd-f {
  padding-left: 20px;
  padding-bottom: 20px;
}
.t-s {
  font-size: 10px;
}
.ldapicon::before {
  color: #F58320 !important;
  cursor: pointer;
}
.unldapicon {
  font-size: 20px!important;
  cursor: default;
}
.ldap-tree {
  width: 100%;
  border: 1px solid #e5e5e5;
  min-height: 400px;
  border-radius: 5px;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-select-dropdown-menu-item-selected {
  font-weight: inherit!important;
}
.ant-tree li .ant-tree-node-content-wrapper {
  height: auto;
}
.ant-tree-node-content-wrapper {
  white-space: normal;
  max-width: 95%;
}
.ant-input-disabled {
  color: #333333 !important;
}
.disabled-input .ant-input-disabled {
  background-color: #ffffff;
  border: none;
  cursor: text;
  max-width: 100%!important;
}
.export_a {
  font-size: '16px';
  color: #F58320;
}
.search-tree-wrap {
  margin-left: -20px;
}
.ldap_Tooltip .ant-tooltip-inner {
  color: #000000 !important;
  background-color: #ffffff !important;
}
.ldap_Tooltip .ant-tooltip-arrow {
  display: none;
}
.multi-no-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.word-break {
  word-break: break-all;
}
.word-wrap {
  word-wrap: break-word;
}
.treehide {
  display: none;
}
.example {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30% 50px;
  min-height: 100vh;
  background-color: #fff;
}
.td_text_max td {
  max-width: 300px;
  word-wrap: break-word;
}
.drawer_self {
  width: 100%;
}
.drawer_self > div {
  text-align: center;
  padding: 20px;
}
.drawer_title {
  text-align: center;
  font-size: 14px;
  margin: 0px !important;
  color: #333333;
  padding-bottom: 30px;
}
.drawer_content {
  font-size: 12px;
  margin: 0px !important;
  color: #666666;
}
.mg-left-80 {
  margin-left: 56px !important;
}
.mg-left-300 {
  margin-left: 256px !important;
}
.meunTest {
  position: absolute;
}
.package_name_width {
  display: inline-block;
  min-width: 250px;
}
.add_package_name_width {
  display: inline-block;
  min-width: 150px;
}
.new-cotent-from {
  padding: 30px 0px 0px 50px;
  width: 100%;
  background-color: #fff;
  min-height: calc(100vh -  145px);
}
.new-cotent-from .ant-col.ant-col-6 {
  padding: 0 !important;
}
.new-cotent-from .ant-col.ant-col-18 {
  padding: 0 !important;
}
.new-cotent-from .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.new-cotent-from .ant-form-item {
  font-size: 12px !important;
}
.new-cotent-from main {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.new-cotent-from section {
  display: table-row;
  border-bottom: 1px solid #f9f9f9;
}
.new-cotent-from aside {
  display: table-cell;
  width: 25%;
  line-height: 50px;
  font-size: 12px;
  font-weight: 600;
}
.new-cotent-from article {
  display: table-cell;
  width: 70%;
}
.new-cotent-from article a {
  color: #F58320;
}
.new-cotent-from p {
  margin: 0 !important;
}
.Configuration-setting {
  font-size: 12px;
  font-weight: bold;
  color: #333333 !important;
}
.Configuration-row {
  margin-top: 50px;
}
.Configuration-from {
  margin: 0 !important;
}
.Configuration-from .ant-form-item-control {
  line-height: 0 !important;
}
.Configuration-from .ant-input-number {
  margin: -5px 5px 0px 5px !important;
}
.Configuration-input {
  width: 215px;
  height: 32px;
  color: #333333 !important;
  font-size: 12px !important;
}
.c-mg-10 {
  margin-top: 10 !important ;
}
.Configuration-right {
  max-width: 612px;
}
.Configuration-a {
  color: #f58320;
}
.tips-new {
  max-width: 926px;
  min-height: 34px;
  line-height: 34px;
  background-color: #f5faff;
  border: 1px solid #e8e8e8;
  padding: 0px 14px !important;
  border-radius: 4px;
  color: #666666;
  font-size: 12px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  top: 12px;
}
.Configuration-switch {
  min-width: 39px !important;
  height: 16px !important;
  line-height: 16px !important;
  border: 1px solid #bbbbbb !important;
  background-color: #ebebeb !important;
}
.Configuration-switch .ant-switch-loading-icon,
.ant-switch::after {
  top: -5px !important;
  left: -2px !important;
  width: 23px !important ;
  height: 23px !important;
  border-radius: 23px !important;
  background-color: #cccccc !important;
  border: 1px solid #999999 !important;
}
.Configuration-switch.ant-switch-checked {
  border: 1px solid #f58320 !important;
  background-color: #ffc797 !important;
}
.Configuration-switch.ant-switch-checked::after {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  border: 1px solid #ff5e00 !important;
  background-color: #f58320 !important;
}
.version-tab,
.wrap-tab {
  padding: 10px;
}
.version-tab .ant-tabs-bar,
.wrap-tab .ant-tabs-bar {
  margin: 0 !important;
  margin-left: -10px !important;
}
.version-tab .ant-tabs-nav-container,
.wrap-tab .ant-tabs-nav-container {
  height: 52px;
}
.version-tab .ant-tabs-nav,
.wrap-tab .ant-tabs-nav {
  height: 52px;
}
.version-tab .ant-tabs-nav .ant-tabs-tab,
.wrap-tab .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
  padding: 10px 20px 20px 20px !important;
  color: #999999 !important;
}
.version-tab .ant-tabs-ink-bar,
.wrap-tab .ant-tabs-ink-bar {
  height: 3px !important;
  background-color: #F58320 !important;
}
.version-tab .ant-spin-nested-loading,
.wrap-tab .ant-spin-nested-loading {
  padding-top: 10px;
}
.version-tab .ant-tabs-nav .ant-tabs-tab-active,
.wrap-tab .ant-tabs-nav .ant-tabs-tab-active {
  color: #F58320 !important;
}
.version-tab .ant-radio-group,
.wrap-tab .ant-radio-group {
  font-size: 12px;
  height: 32px !important;
}
.version-tab .ant-radio-button-wrapper-checked,
.wrap-tab .ant-radio-button-wrapper-checked {
  background-color: #b5b5b5 !important;
  border: 1px solid #dddddd !important;
  color: #ffffff !important;
  border-right: 0 !important;
}
.version-tab .ant-radio-button-wrapper-checked:first-child,
.wrap-tab .ant-radio-button-wrapper-checked:first-child {
  border-color: #b5b5b5 !important;
  border-left: 0 !important;
}
.version-tab .ant-radio-button-wrapper,
.wrap-tab .ant-radio-button-wrapper {
  color: #999999;
  border: 1px solid #dddddd !important;
  border-right: 0 !important;
  box-shadow: none !important;
  border-radius: 0 0px 0px 0!important;
  padding: 0;
  width: 120px;
  text-align: center;
}
.version-tab .ant-radio-button-wrapper-checked::before,
.wrap-tab .ant-radio-button-wrapper-checked::before {
  background-color: #dddddd !important;
}
.version-tab .ant-radio-button-wrapper:last-child,
.wrap-tab .ant-radio-button-wrapper:last-child {
  border-right: 1px solid #dddddd !important;
}
.version-tab .ant-radio-button-wrapper:not(:first-child)::before,
.wrap-tab .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #dddddd;
  border-right: 1px solid #dddddd !important;
}
.version-tab .ant-radio-button-wrapper-checked,
.wrap-tab .ant-radio-button-wrapper-checked {
  box-shadow: 0 0 0 0 #dddddd !important;
}
.version-tab .pdt-0,
.wrap-tab .pdt-0 {
  padding-top: 0 !important;
}
.ant-table table {
  font-size: 12px !important ;
  color: #666666 !important;
}
.ant-table-thead > tr > th {
  background: #f6f6f6 !important;
  height: 46px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px !important;
  font-weight: 600 ;
}
.ant-table-thead > tr > th {
  padding: 12px 16px;
}
.ant-table-tbody > tr > td {
  padding: 11px 16px;
}
.marker {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
}
.marker-Unapproved {
  border: 1px solid #aaaaaa;
  background-color: #aaaaaa;
  color: #aaaaaa;
}
.marker-Unapproved-text {
  color: #aaaaaa;
}
.marker-Approved {
  border: 1px solid #ffab61;
  background-color: #ffab61;
}
.marker-Approved-text {
  color: #ffab61;
}
.marker-Pushed {
  border: 1px solid #6DC885;
  background-color: #6DC885;
}
.marker-Pushed-text {
  color: #6DC885;
}
.down-time {
  min-width: 240px;
  display: inline-block;
}
.status-min {
  min-width: 137px;
  display: inline-block;
}
.icon-padding a {
  margin: 0 10px 0 0;
}
.table-wrap-new {
  /*定义滚动条的轨道，内阴影及圆角*/
  /*定义滑块，内阴影及圆角*/
}
.table-wrap-new .ant-table-body {
  overflow-x: auto !important;
}
.table-wrap-new ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #ffffff;
}
.table-wrap-new ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background-color: #ffffff;
}
.table-wrap-new ::-webkit-scrollbar-thumb {
  /*width: 10px;*/
  height: 20px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #eeeeee;
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  border-width: 5px 5px !important;
}
.ant-tooltip-inner {
  font-size: 12px !important;
}
.button-wh-icon {
  width: 106px;
  font-size: 14px;
  padding: 0 !important;
}
.pd-right-6 {
  padding-right: 6px;
}
.button-wh-icon.ant-btn-dominant[disabled],
.ant-select-dominant .ant-select-selection[disabled] {
  opacity: 1 !important;
  color: #333333 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #d3d3d3 !important;
}
.tableList i.empty-icon {
  font-size: 36px!important;
  display: block;
}
.ant-table-placeholder {
  width: 100%;
  padding: 30px 0px !important;
}
.empty-table {
  width: 100%;
  padding-top: 10% ;
  text-align: center;
}
.empty-table p {
  line-height: 22px;
  font-size: 12px;
  color: #cccccc;
  margin: 0 !important;
  padding-top: 20px;
}
.icon-grey::before {
  color: #999999;
}
.icon-white::before {
  color: #fff !important;
}
.drawer_detail .ant-drawer-body {
  padding: 30px !important;
}
.ant-table-pagination.ant-pagination {
  margin: 20px 20px 30px 0 !important;
}
.icon-list:hover::before {
  color: #F58320;
}
.search_box {
  background: #f6f6f6;
  border: 1px solid #e9e9e9;
  padding: 0px 10px;
  width: 100%;
}
.pd-top-20 {
  padding-top: 20px;
}
.input-lable-ver {
  font-size: 12px;
  color: #333333;
  padding: 0px 10px 0px 20px;
}
.ant-btn-reset {
  background: #e5e5e5 !important;
  color: #333333 !important;
  border: none!important;
}
.ant-btn-reset .ant-select-arrow {
  color: #333333 !important;
}
.ant-btn-reset[disabled] {
  opacity: 0.5;
}
.ant-btn-reset:hover {
  background: #dddddd !important;
}
.button-wh-search {
  width: 80px;
  font-size: 14px;
}
.ant-select-dropdown-menu {
  font-size: 12px;
}
.ant-menu-submenu-placement-rightTop {
  left: 56px !important;
  min-width: 219px !important;
}
.input-wrap-r input {
  padding-right: 275px!important;
}
.new-model-class .ant-modal {
  width: 500px !important;
  top: 180px !important;
}
.new-model-class .ant-modal-header {
  height: 50px ;
  background-color: #f6f6f6 !important;
  padding: 14px 20px !important;
  border-bottom: 1px solid #dddddd !important;
}
.new-model-class .ant-modal-close-x {
  width: 50px !important;
  height: 50px !important;
  font-size: 12px !important;
  line-height: 50px !important;
}
.new-model-class .ant-modal-title {
  font-size: 14px !important;
  font-family: "Segoe UI", "Open Sans", Verdana, "Microsoft YaHei", Arial, sans-serif !important;
  color: #333333 !important;
  font-weight: 600;
}
.new-model-class .ant-modal-body {
  padding: 30px 34px !important;
  font-family: "Segoe UI", "Open Sans", Verdana, "Microsoft YaHei", Arial, sans-serif !important;
  color: #333333 !important;
}
.new-model-class .ant-modal-body p {
  display: inline;
}
.new-model-class .ant-modal-footer .ant-btn {
  min-width: 80px !important;
  min-height: 32px !important;
}
.new-model-class .ant-modal-footer {
  padding: 0 20px 0 0 !important;
  line-height: 62px;
  border-bottom: 1px solid #dddddd !important;
}
.new-model-class .ant-modal-footer button:first-child {
  background-color: #e5e5e5 !important;
  color: #333333 !important;
  border: 0 !important;
}
.new-model-class .ant-modal-footer button:first-child:hover {
  background: #dddddd !important;
}
.new-model-class .ant-modal-footer button:first-child[disabled] {
  color: #bbbbbb !important;
  background: #e5e5e5 !important;
}
.new-model-class .ant-modal-footer button.ant-btn-primary {
  font-size: 14px!important;
  color: #ffffff !important;
  border: none!important;
}
.new-model-class .ant-modal-footer button.ant-btn-primary:hover {
  background: #ff9a44 !important;
}
.new-model-class .ant-modal-footer button.ant-btn-primary[disabled] {
  background: #fbcda5 !important;
}
.ant-drawer.drawer_detail .ant-drawer-mask {
  opacity: 0 !important;
}
.logs-search-box {
  margin: 0 !important;
  padding: 10px 0!important;
}
.logs-search-box label {
  font-size: 12px;
  color: #333333;
}
.logs-search-box .ant-row {
  padding: 0!important;
  margin: 0!important;
}
.logs-search-box .ant-row .ant-col .ant-col-12 {
  padding: 0 !important;
}
.logs-search-box .ant-row .ant-form-item {
  margin-bottom: 0px !important;
}
.pd-10 {
  padding: 10px;
}
#AdminOperationLogs_search_Status {
  width: 70%;
}
#AdminOperationLogs_search_Time {
  width: 95% !important;
}
#InternalUpdateOperationLogs_search_Status {
  width: 70%;
}
#InternalUpdateOperationLogs_search_Time {
  width: 95% !important;
}
#LoginLogs_search_Status {
  width: 70%;
}
#LoginLogs_search_Time {
  width: 95% !important;
}
.pd-top-10 {
  padding-top: 10px;
}
.ant-select-selection-selected-value {
  font-size: 12px;
}
.eportCheckbox {
  float: left;
  margin-left: 20px;
}
.ant-tree.ant-tree-show-line li::before {
  border-left: 1px dashed #d9d9d9 !important;
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  height: calc(78%);
  margin: 22px 0 0;
  content: ' ';
}
.ant-tree.ant-tree-show-line li.ant-tree-treenode-switcher-close:last-child:before {
  display: none;
}
.ant-tree-child-tree > .ant-tree-treenode-switcher-close::before {
  display: none;
}
.cotent-tree .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop i {
  display: none !important;
}
.cotent-tree .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  border-bottom: 1px dashed #d9d9d9;
  height: 12px !important;
  display: inline-block;
  width: 18px;
  margin-right: 5px;
}
.cotent-tree .ant-tree li ul {
  margin: 0;
  padding: 0 0 0 14px !important;
}
.toggle-order {
  color: #d8d8d8;
}
.toggle-order.up .icon-arrow-up {
  color: #F58320;
}
.toggle-order.down .icon-arrow-down {
  color: #F58320;
}
.operation-wrap {
  background: #f6f6f6;
  border: 1px solid #ededed;
}
.tableList [class^="icon-"],
[class*=" icon-"] {
  font-size: 15px!important;
}
.ant-input-number-handler-wrap {
  opacity: 1 !important;
}
.ant-menu-submenu-active .ant-menu-submenu-title .nav-text {
  color: #F58320 !important;
}
.ant-menu-item:hover .icon:before {
  color: #F58320 !important;
}
.ant-menu-item:hover .nav-text {
  color: #F58320 !important;
}
.product-info {
  min-height: 70px;
}
.ant-form.ant-form-horizontal .ant-form-item-label {
  white-space: normal;
  line-height: normal;
  min-height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5px;
}
.ant-form.ant-form-horizontal .ant-form-item-required::before {
  content: '*';
  display: inline;
}
.ant-form-item {
  color: #333333 !important;
}
.EnterpriseBrandCustomization_container {
  background: linear-gradient(#ffffff 50%, #F3F5F7 50%);
  height: calc(100vh -  55px);
  padding-bottom: 48px;
  overflow: scroll;
}
.main_haveBottom {
  background: linear-gradient(#ffffff 50%, #F3F5F7 50%);
  height: calc(100vh -  55px);
  padding-bottom: 48px;
  overflow: scroll;
}
.search_box .ant-form-item-label label {
  font-size: 12px !important;
}
.search_box .ant-form-item-label > label::after {
  content: '' !important;
}
.search_box .ant-calendar-picker {
  width: 100% !important;
}
.search_box .ant-calendar-range-picker-input {
  width: 32% !important;
}
.search_box .menu-pdt-10 {
  padding-top: 6px;
}
.s_ .ant-col .ant-form-item-control-wrapper {
  min-width: 200px;
}
.ant-tooltip-inner {
  background-color: #333333 !important;
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  border-top-color: transparent !important;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #F6F6F6 !important;
}
.set_pd {
  padding: 10px 20px;
  font-size: 12px;
}
.search-pd-5 {
  padding: 5px 0;
}
.pdb-10 {
  padding-bottom: 10px;
}
.ant-btn {
  min-width: 80px;
  height: 36px;
  border-radius: 5px !important;
}
[id='/product-configuration$Menu'],
[id='/contentlogs$Menu'] {
  background: #444444 !important;
  opacity: 1 !important;
  margin-left: -4px;
}
.loading_prog .ant-progress-line {
  width: 200px !important;
}
.loading_prog .text_prog {
  color: #999999;
  padding-left: 10px;
}
.ant-row .ant-form-item label {
  font-size: 12px!important;
}
.ant-calendar-picker-input i.ant-calendar-picker-icon svg {
  fill: #666666 !important;
}
.ant-calendar-picker-input .ant-calendar-range-picker-input {
  color: #333333 !important;
  font-size: 12px;
}
.wrap_bottom {
  height: 80px;
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 17px;
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  border-bottom: 16px solid #edf1f6;
}
.wrap_bottom.shadowBottom {
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.09);
}
.red-select-item.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #FFEBD9 !important;
}
.color-AAAAAA {
  color: #aaaaaa !important;
}
.width-100 {
  width: 100%;
}
.fz-12 {
  font-size: 12px!important;
}
.bgc#F3F5F7 {
  background: #F3F5F7;
}
.ant-popover .ant-popover-inner-content {
  padding: 6px 8px!important;
  font-size: 12px!important;
}
.ant-popover p {
  margin: 0!important;
}
.ant-dropdown-menu.dropdown-wrap {
  background-color: #F5FAFF;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  width: 340px;
  font-size: 12px !important;
  margin-left: 30px;
}
.ant-dropdown-menu.dropdown-wrap i {
  margin-top: 3px;
}
.ant-dropdown-menu.dropdown-wrap span {
  font-weight: bold;
}
.routeRecord {
  height: 58px;
  line-height: 58px;
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 12px;
  z-index: 1;
}
.routeRecord .preRoute {
  color: #F58320;
  cursor: pointer;
}
.routeRecord .currentRoute {
  color: #999999;
}
.newTooltipSpan {
  cursor: pointer;
}
.newTooltip {
  max-width: none !important;
}
.newTooltip .ant-tooltip-arrow {
  display: none;
}
.newTooltip .ant-tooltip-inner {
  font-size: 12px;
  color: #555555;
  background-color: #fff !important;
}
.max-table {
  max-width: 700px;
}
.maxW-480 div {
  max-width: 480px;
}
