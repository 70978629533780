.sider-wrap{
  &.ant-layout-sider,
  .ant-menu-root.ant-menu-inline,.ant-menu-sub{
    background-color: #2d3742 !important;

  }
}

.ant-menu-dark {
  &.ant-menu-inline{
    .ant-menu-item-selected{
      background-color: #2d3742 !important;
    }
  }
}
.ant-menu:not(.ant-menu-horizontal){
  .ant-menu-item-selected{
      background-color: #2d3742!important;
      a{
        color:@dominant-color!important;
      }
  }
}

.ant-menu-item-selected::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 2px solid #F58320;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.custom-trigger {
  font-size: 18px;
  line-height: 60px;
  padding: 0 16px;
  cursor: pointer;
  transition: color .3s;
}
.ant-layout-sider-collapsed {
  overflow-y: initial !important;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 32px;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
  i {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    margin: 1px;
    border-width: 2px;
    border-style: solid;
    border-radius: 100%;
    &.bottom {
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
    }
    &.on {
      background-color: #6cc788;
    }
  }
  img {
    border-radius: 500px;
    width: 100%;
  }
}
.switcher {
  z-index: 1050;
  position: fixed;
  top: 78px;
  right: -240px;
  width: 240px;
  transition: right 0.2s ease;
  border: 1px solid rgba(120, 120, 120, 0.1);
  background-clip: padding-box;
  &.active {
    right: -2px;
  }
  .sw-btn {
    position: absolute;
    left: -43px;
    top: -1px;
    padding: 10px 15px;
    z-index: 1045;
    border: 1px solid rgba(120, 120, 120, 0.1);
    border-right-width: 0;
    background-clip: padding-box;
  }
}
.ant-menu-submenu > .ant-menu{
  border-radius: 0px !important;
}
.ant-menu-submenu-popup{
  border-radius: 0px !important;
}
.lang-menu{
  .ant-menu-sub .ant-menu-item{
    width: 130px !important;
  }
  .ant-menu-vertical.ant-menu-sub{
   min-width: 130px !important;
 }
}

.ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: top !important;
  top:0px !important;
}

.custom-trigger-new {
  font-size: 18px;
  line-height: 60px;
  cursor: pointer;
  transition: color .3s;
  position: fixed;
  bottom: 0;
}
.pd-left-24{
  padding: 0 24px
}
.pd-left-32{
  padding: 0 32px
}
.ant-menu-submenu-placement-rightTop ul{
  background-color: #2d3742 !important;
}
.ant-menu-submenu-placement-rightTop ul a{
  color: #fff !important;
}
.nav-text{
  font-size: 14px !important;
  color: #aaaaaa;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .nav-text{
  color: #F58320 !important;
}
.ant-menu-submenu-selected .ant-menu-item-selected .nav-text{
  color: #F58320 !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .icon::before{
  color: #F58320 !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-left: 2px solid #F58320;
    -webkit-transition: -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
}
.ant-menu-submenu-selected >.ant-menu-sub >.ant-menu-item-selected::before{
  border-left:0 !important
}
.ant-menu-item.ant-menu-item-selected .nav-text{
  color: #F58320 !important;
}
.ant-menu-submenu-arrow::before{
  background:#aaa !important
}
.ant-menu-submenu-arrow::after{
  background:#aaa !important
}
.pd-left-20{
   padding-left:0 20px;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
  padding-left: 20px !important;
}
.ant-menu-item{
  padding-left: 20px !important;
}
.ant-menu-submenu-open .ant-menu-item{
  padding-left: 48px !important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  width: 5px !important;
}
.new-logo{
  background: #2d3742;
  line-height: 60px;
  // margin-top: 60px;
  position:fixed;
  z-index: 999;
  border-bottom: 1px solid #222c37
}
.new-meun-open{
  width: 256px;
  padding-left:20px !important;
  height: 64px;
  line-height: 55px;
}
.new-meun-close{
  width: 56px;
}
.new-width-56{
  overflow: auto;
    left: 0px;
    z-index: 10;
    flex: 0 0 56px !important;
    max-width: 56px !important;
    min-width: 56px !important;
    width: 56px !important;
}
.new-width-290{
  overflow: auto;
    left: 0px;
    z-index: 10;
    flex: 0 0 256px !important;
    max-width: 256px !important;
    min-width: 256px !important;
    width: 256px !important;
}
.new-width-56 {

  .ant-menu-item{
    width: 56px;
    padding: 0 !important;
    text-align: center;
    height: 48px;
    line-height: 48px;
  }
  .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0!important;
  }
  .ant-menu-submenu{
    width: 56px;
    padding: 0 !important;
    text-align: center;
  }
  .ant-menu-item .icon, .ant-menu-submenu-title .icon{
    margin-right: 0 !important;
  }
}
.openMenuBtn{
 width:100%;
 text-align: center;
 height: 60px;
}
.close_title .ant-menu-item-group-title{
  color: #F58320;
  // padding: 15px 0px 0px 0px !important;
  background-color:  #333333 !important;
  margin-left: -72px;
  height: 48px;
  line-height: 32px;
}
.close_title .titleIcon{
   width: 56px;
   display: inline-block;
   text-align: center;
  //  line-height: 48px;
   .icon::before{
    color: #F58320 !important;
    font-size: 16px !important;
   }
}

.ant-menu-submenu-placement-rightTop > .ant-menu > .ant-menu-item-selected::before{
  border: 0 !important;
}
.ant-menu-submenu-placement-rightTop .ant-menu-item{
  background-color: #2d3742;
  margin: 0 !important;
}
.ant-menu-inline-collapsed .ant-menu-submenu-title{
  height: 36px !important;
  line-height: 36px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title{
  height: 36px !important;
  line-height: 36px !important;
  color: #aaaaaa;
}
.ant-menu-inline-collapsed .ant-menu-submenu-selected .ant-menu-submenu-title{
  background-color:  #2d3742 !important
}
.ant-menu-submenu-placement-rightTop .ant-menu-item:hover{
  color: #F58320;
}
.ant-menu-submenu.ant-menu-submenu-vertical:hover{
  color: #F58320;
  background-color:  #2d3742 !important;
  height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-submenu-title :hover{
  .icon::before{
    color: #F58320;
  }
}
.ant-menu-submenu-placement-rightTop .ant-menu:hover ~ .ant-menu-submenu-title{
    // color: #F58320;
    background-color:  #2d3742 !important;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-inner .icon::before{
   display: none;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-inner {
  .ant-tooltip-arrow{
    display: none;
  }
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right{
  left: 48px !important;
}
.ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom{
  width: 219px;
  height: 48px;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-content{
  height: 48px;
  width: 225px;
  border-radius: 0 !important;
  .ant-tooltip-arrow{
    display: none;
  }
  .ant-tooltip-inner{
    height: 48px;
    padding: 0;
    // margin-top: 4px;
    line-height: 48px;
    border-radius: 0 !important;
    background: #333333;
    color: #F58320;
  }
  .ant-tooltip-inner a{
    margin-left: -8px;
    .nav-text{
      color: #F58320;
    }
  }
}
.ant-menu.ant-menu-light.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical .ant-menu-item:hover{
  background: #2d3742;
  .icon::before{
    color: #F58320;
  }
}
.logo_over{
  position:fixed;
  z-index: 999;
}
.titleTitle{
  margin-left: -8px;
}
.Dropdown_new_meun{
  line-height: 60px;
  padding-right: 60px;
  font-size:14px;
  color: #fff;
  height: 58px !important;
  position: absolute;
  right: 50px;
}
.Dropdown_new_meun a{
  font-size:14px;
  color: #fff;
  height: 60px !important;
}
.Dropdown_new_meun .icon{
  padding-left: 10px;
}
.Dropdown_new_list a{
 color: #999999;
}
.icon-account,.icon-logout{
  font-size: 15px;
}
.headerNewTitle{
  font-size: 16px;
  color: #222c37;
  padding-left: 16px;
  font-weight: 600;
}
