.drive-checkbox {
    display: inline-flex;
    align-items: center;
    height: 32px;
    span:nth-child(2){
        display: inline-flex;
        align-items: center;
    }

    .ant-checkbox{
        top:0
    }
}