.support{
    margin: 0 auto;
    .supportTitle{
        width: 100%;
        text-align: center;
        padding: 100px 0 0 0;
    }
    .TitleOne{
        width: 100%;
        font-size: 24px;
        padding-bottom: 20px;
        // display: inline-block
    }
    .TitleTwo{
        width: 50%;
        font-size: 16px;
        display: inline-block
    }
    .ant-result-title{
        font-size: 18px !important;
        padding-bottom: 20px;
    }
    .ant-result-extra{
        margin-top: 16px !important;
    }
    .ant-result{
        padding: 32px !important;
    }
    .supportBox{
        width: 100%;
        min-height: 400px;
        background: #ffffff;
        border: 1px solid #e8e8e8;
        box-shadow: 0px 0px 5px 3px #e4e4e4;
        border-radius: 6px ;
        min-width: 288px;
    }
    .ant-btn-primary{
        width: 146px;
        height: 30px;
    }
    .subTitle {
        
    }
    .subTitle p{
        margin: 0 !important;
    }
}