/***********************************************************************************************************************
d-f -> display: flex;
jc-fs -> justify-content: flex-start;
b-1s -> border: 1px solid;
bc-ddd -> border-color: #ddd;
w-100 -> width: 100px;
w-100z -> width: 100%;
h-100vh -> height: 100vh;
mlmr-10 -> margin-left: 10px; margin-right: 10px;
ptpb-10 -> padding-top: 10px; padding-bottom: 10px;
***********************************************************************************************************************/

@primary: #F58320;

@red: #fb7878;
@green: #4DDC8A;
@blue: #4cb3ff;

/* margin setting start end step */
@marginSettingA: 0, 20, 1;
@marginSettingB: 25, 60, 5;

/* padding setting start end step */
@paddingSettingA: 0, 20, 1;
@paddingSettingB: 25, 60, 5;

/* border setting start end step */
@borderSetting: 1, 3, 1;

/* border-radius setting start end step */
@borderRadiusSettingA: 0, 20, 1;
@borderRadiusSettingB: 25, 60, 5;

/* font-size setting start end step */
@fontSizeSetting: 8, 30, 1;

/* width setting start end step */
@widthSettingA: 0, 20, 1;
@widthSettingB: 25, 100, 5;
@widthSettingC: 110, 300, 10;

/* height setting start end step */
@heightSettingA: 0, 20, 1;
@heightSettingB: 25, 100, 5;
@heightSettingC: 110, 300, 10;

/* line-height setting start end step */
@lineHeightSettingA: 0, 20, 1;
@lineHeightSettingB: 25, 100, 5;
@lineHeightSettingC: 110, 300, 10;

/* top right bottom left setting start end step */
@topRightBottomLeftSettingA: 0, 20, 1;
@topRightBottomLeftSettingB: 25, 60, 5;

/* color、background、border-color */
@black2white: e('000'), 111, 222, 333, 444, 555, 666, 777, 888, 999, aaa, bbb, ccc, ddd, eee, fff;
@tones: { primary: @primary; red: @red; green: @green; blue: @blue};
/* color */
@tonesColor: { error: #e12b2b };
/* background */
@tonesBackground: { body: #f4f4f4 };
/* border-color */
@tonesBorderColor: { search: #454545 };

/**********************************************************************************************************************/

/* flex */
.fd-c {
  flex-direction: column !important;
}

.fw-w {
  flex-wrap: wrap !important;
}

.jc-fs {
  justify-content: flex-start !important;
}

.jc-c {
  justify-content: center !important;
}

.jc-fe {
  justify-content: flex-end !important;
}

.jc-sb {
  justify-content: space-between !important;
}

.jc-sa {
  justify-content: space-around !important;
}

.ai-fs {
  align-items: flex-start !important;
}

.ai-c {
  align-items: center !important;
}

.ai-fe {
  align-items: flex-end !important;
}

each(range(0, 5, 1), {
  .fg-@{value} {
    flex-grow: @value !important;
  }
});

each(range(0, 5, 1), {
  .fs-@{value} {
    flex-shrink: @value !important;
  }
});

.fb-0 {
  flex-basis: 0 !important;
}

each(range(10, 100, 10), {
  .fb-@{value}z {
    flex-basis: 0% + @value !important;
  }
});

.fb-25z {
  flex-basis: 25% !important;
}

.fb-33z {
  flex-basis: 33.3% !important;
}

each(range(0, 9, 1), {
  .f-@{value} {
    flex: @value 0 0 !important;
  }
});

/* margin */
.m-a {
  margin: auto !important;
}

each(range(extract(@marginSettingA, 1), extract(@marginSettingA, 2), extract(@marginSettingA, 3)), {
  .mt-@{value} {
    margin-top: 1px * @value !important;
  }

  .mr-@{value} {
    margin-right: 1px * @value !important;
  }

  .mb-@{value} {
    margin-bottom: 1px * @value !important;
  }

  .ml-@{value} {
    margin-left: 1px * @value !important;
  }

  .mtmb-@{value} {
    margin-top: 1px * @value !important;
    margin-bottom: 1px * @value !important;
  }

  .mlmr-@{value} {
    margin-left: 1px * @value !important;
    margin-right: 1px * @value !important;
  }
});

each(range(extract(@marginSettingB, 1), extract(@marginSettingB, 2), extract(@marginSettingB, 3)), {
  .mt-@{value} {
    margin-top: 1px * @value !important;
  }

  .mr-@{value} {
    margin-right: 1px * @value !important;
  }

  .mb-@{value} {
    margin-bottom: 1px * @value !important;
  }

  .ml-@{value} {
    margin-left: 1px * @value !important;
  }

  .mtmb-@{value} {
    margin-top: 1px * @value !important;
    margin-bottom: 1px * @value !important;
  }

  .mlmr-@{value} {
    margin-left: 1px * @value !important;
    margin-right: 1px * @value !important;
  }
});

/* padding */
.p-a {
  padding: auto !important;
}

each(range(extract(@paddingSettingA, 1), extract(@paddingSettingA, 2), extract(@paddingSettingA, 3)), {
  .pt-@{value} {
    padding-top: 1px * @value !important;
  }

  .pr-@{value} {
    padding-right: 1px * @value !important;
  }

  .pb-@{value} {
    padding-bottom: 1px * @value !important;
  }

  .pl-@{value} {
    padding-left: 1px * @value !important;
  }

  .ptpb-@{value} {
    padding-top: 1px * @value !important;
    padding-bottom: 1px * @value !important;
  }

  .plpr-@{value} {
    padding-left: 1px * @value !important;
    padding-right: 1px * @value !important;
  }
});

each(range(extract(@paddingSettingB, 1), extract(@paddingSettingB, 2), extract(@paddingSettingB, 3)), {
  .pt-@{value} {
    padding-top: 1px * @value !important;
  }

  .pr-@{value} {
    padding-right: 1px * @value !important;
  }

  .pb-@{value} {
    padding-bottom: 1px * @value !important;
  }

  .pl-@{value} {
    padding-left: 1px * @value !important;
  }

  .ptpb-@{value} {
    padding-top: 1px * @value !important;
    padding-bottom: 1px * @value !important;
  }

  .plpr-@{value} {
    padding-left: 1px * @value !important;
    padding-right: 1px * @value !important;
  }
});

/* border */
.b-n {
  border: none !important;
}

each(range(extract(@borderSetting, 1), extract(@borderSetting, 2), extract(@borderSetting, 3)), {
  .b-@{value}s {
    border: 1px * @value solid !important;
  }

  .bt-@{value}s {
    border-top: 1px * @value solid !important;
  }

  .br-@{value}s {
    border-right: 1px * @value solid !important;
  }

  .bb-@{value}s {
    border-bottom: 1px * @value solid !important;
  }

  .bl-@{value}s {
    border-left: 1px * @value solid !important;
  }
});

each(range(extract(@borderSetting, 1), extract(@borderSetting, 2), extract(@borderSetting, 3)), {
  .b-@{value}d {
    border: 1px * @value dashed !important;
  }

  .bt-@{value}d {
    border-top: 1px * @value dashed !important;
  }

  .br-@{value}d {
    border-right: 1px * @value dashed !important;
  }

  .bb-@{value}d {
    border-bottom: 1px * @value dashed !important;
  }

  .bl-@{value}d {
    border-left: 1px * @value dashed !important;
  }
});

/* border-radius */
.br-50z {
  border-radius: 50% !important;
}

.br-100z {
  border-radius: 100% !important;
}

.br-999 {
  border-radius: 999px !important;
}

each(range(extract(@borderRadiusSettingA, 1), extract(@borderRadiusSettingA, 2), extract(@borderRadiusSettingA, 3)), {
  .br-@{value} {
    border-radius: 1px * @value !important;
  }
});

each(range(extract(@borderRadiusSettingB, 1), extract(@borderRadiusSettingB, 2), extract(@borderRadiusSettingB, 3)), {
  .br-@{value} {
    border-radius: 1px * @value !important;
  }
});

/* border-color */
.bc-t {
  border-color: transparent !important;
}

each(@black2white, {
  .bc-@{value} {
    border-color: e(%('#%s', @value)) !important;
  }
});

each(@tones, {
  .bc-@{key} {
    border-color: @value !important;
  }
});

each(@tonesBorderColor, {
  .bc-@{key} {
    border-color: @value !important;
  }
});

/* color */
.c-t {
  color: transparent !important;
}

each(@black2white, {
  .c-@{value} {
    color: e(%('#%s', @value)) !important;
  }
});

each(@tones, {
  .c-@{key} {
    color: @value !important;
  }
});

each(@tonesColor, {
  .c-@{key} {
    color: @value !important;
  }
});

/* background */
.b-t {
  background: transparent !important;
}

each(@black2white, {
  .b-@{value} {
    background: e(%('#%s', @value)) !important;
  }
});

each(@tones, {
  .b-@{key} {
    background: @value !important;
  }
});

each(@tonesBackground, {
  .b-@{key} {
    background: @value !important;
  }
});

/* font-size */
each(range(extract(@fontSizeSetting, 1), extract(@fontSizeSetting, 2), extract(@fontSizeSetting, 3)), {
  .fs-@{value} {
    font-size: 1px * @value !important;
  }
});

/* font-weight */
.fw-n {
  font-weight: normal !important;
}

.fw-b {
  font-weight: bold !important;
}

.fw-l {
  font-weight: lighter !important;
}

each(range(100, 900, 100), {
  .fw-@{value} {
    font-weight: @value !important;
  }
});

/* font-style */
.fs-n {
  font-style: normal !important;
}

.fs-i {
  font-style: italic !important;
}

/* width */
.w-a {
  width: auto !important;
}
.w-fc {
  width: fit-content !important;
}

.w-minc {
  width: min-content !important;
}

.w-maxc {
  width: max-content !important;
}

each(range(10, 100, 10), {
  .w-@{value}z {
    width: 1% * @value !important;
  }

  .w-@{value}vw {
    width: 1vw * @value !important;
  }
});

each(range(extract(@widthSettingA, 1), extract(@widthSettingA, 2), extract(@widthSettingA, 3)), {
  .w-@{value} {
    width: 1px * @value !important;
  }
});

each(range(extract(@widthSettingB, 1), extract(@widthSettingB, 2), extract(@widthSettingB, 3)), {
  .w-@{value} {
    width: 1px * @value !important;
  }
});

each(range(extract(@widthSettingC, 1), extract(@widthSettingC, 2), extract(@widthSettingC, 3)), {
  .w-@{value} {
    width: 1px * @value !important;
  }
});

/* height */
.h-a {
  height: auto !important;
}
.h-fc {
  height: fit-content !important;
}

.h-minc {
  height: min-content !important;
}

.h-maxc {
  height: max-content !important;
}

each(range(10, 100, 10), {
  .h-@{value}z {
    height: 1% * @value !important;
  }

  .h-@{value}vh {
    height: 1vh * @value !important;
  }
});

each(range(extract(@heightSettingA, 1), extract(@heightSettingA, 2), extract(@heightSettingA, 3)), {
  .h-@{value} {
    height: 1px * @value !important;
  }
});

each(range(extract(@heightSettingB, 1), extract(@heightSettingB, 2), extract(@heightSettingB, 3)), {
  .h-@{value} {
    height: 1px * @value !important;
  }
});

each(range(extract(@heightSettingC, 1), extract(@heightSettingC, 2), extract(@heightSettingC, 3)), {
  .h-@{value} {
    height: 1px * @value !important;
  }
});

/* line-height */
.lh-n {
  line-height: normal !important;
}

each(range(10, 100, 10), {
  .lh-@{value}z {
    line-height: 1% * @value !important;
  }

  .lh-@{value}vh {
    line-height: 1vh * @value !important;
  }
});

each(range(extract(@lineHeightSettingA, 1), extract(@lineHeightSettingA, 2), extract(@lineHeightSettingA, 3)), {
  .lh-@{value} {
    line-height: 1px * @value !important;
  }
});

each(range(extract(@lineHeightSettingB, 1), extract(@lineHeightSettingB, 2), extract(@lineHeightSettingB, 3)), {
  .lh-@{value} {
    line-height: 1px * @value !important;
  }
});

each(range(extract(@lineHeightSettingC, 1), extract(@lineHeightSettingC, 2), extract(@lineHeightSettingC, 3)), {
  .lh-@{value} {
    line-height: 1px * @value !important;
  }
});

/* display */
.d-f {
  display: flex !important;
}

.d-i {
  display: inline !important;
}

.d-ib {
  display: inline-block !important;
}

.d-b {
  display: block !important;
}

.d-n {
  display: none !important;
}

/* position */
.p-a {
  position: absolute !important;
}

.p-r {
  position: relative !important;
}

.p-sti {
  position: sticky !important;
}

.p-sta {
  position: static !important;
}

.p-f {
  position: fixed !important;
}

/* top right bottom left */
each(range(extract(@topRightBottomLeftSettingA, 1), extract(@topRightBottomLeftSettingA, 2), extract(@topRightBottomLeftSettingA, 3)), {
  .t-@{value} {
    top: 1px * @value !important;
  }

  .r-@{value} {
    right: 1px * @value !important;
  }

  .b-@{value} {
    bottom: 1px * @value !important;
  }

  .l-@{value} {
    left: 1px * @value !important;
  }
});

each(range(extract(@topRightBottomLeftSettingB, 1), extract(@topRightBottomLeftSettingB, 2), extract(@topRightBottomLeftSettingB, 3)), {
  .t-@{value} {
    top: 1px * @value !important;
  }

  .r-@{value} {
    right: 1px * @value !important;
  }

  .b-@{value} {
    bottom: 1px * @value !important;
  }

  .l-@{value} {
    left: 1px * @value !important;
  }
});

/* opacity */
.o-0 {
  opacity: 0 !important;
}

.o-1 {
  opacity: 1 !important;
}

each(range(1, 9, 1), {
  .o-0@{value} {
    opacity: e(%('0.%s', @value)) !important;
  }
});

/* text-align */
.ta-l {
  text-align: left !important;
}

.ta-c {
  text-align: center !important;
}

.ta-r {
  text-align: right !important;
}

.ta-s {
  text-align: start !important;
}

.ta-e {
  text-align: end !important;
}

/* float */
.f-n {
  float: none !important;
}

.f-l {
  float: left !important;
}

.f-r {
  float: right !important;
}

/* vertical-align */
.va-t {
  vertical-align: top !important;
}

.va-m {
  vertical-align: middle !important;
}

.va-b {
  vertical-align: bottom !important;
}

/* overflow */
.o-h {
  overflow: hidden !important;
}

.o-a {
  overflow: auto !important;
}

.o-v {
  overflow: visible !important;
}

.ox-h {
  overflow-x: hidden !important;
}

.oy-h {
  overflow-y: hidden !important;
}

.ox-s {
  overflow-x: scroll !important;
}

.oy-s {
  overflow-y: scroll !important;
}

/* z-index */
.zi-a {
  z-index: auto !important;
}

each(range(0, 10, 1), {
  .zi-@{value} {
    z-index: @value !important;
  }
});

/* other */
.ws-n {
  white-space: nowrap !important;
}

.to-e {
  text-overflow: ellipsis !important;
}

.to-c {
  text-overflow: clip !important;
}

.ww-bw {
  word-wrap: break-word !important;
}

.wb-ba {
  word-break: break-all !important;
}

.v-h {
  visibility: hidden !important;
}

.c-b {
  clear: both !important;
}

.c-p {
  cursor: pointer !important;
}
