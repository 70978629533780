small {
  opacity: .6;
}
.text-muted{
  opacity: .6;
}
.clear{
  display: block;
  overflow: hidden;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.y-center{
  display: flex;
  align-items: center;
}
.block{
  display: block;
}
.inline {
  display: inline;
}
.inline-block{
  display: inline-block;
}
.none{
  display: none;
}
.hidden {
  // visibility:hidden;
  display: none;
}
.b-white {
  border-color: #ffffff;
}
.w-full {
  width: 100%;
}

.w-500 {
  width: 500px;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-v {
  height: 100vh;
}

.h-v-5 {
  height: 50vh;
}


.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.w-40 {
  width:  40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}

.line{
  display: inline-block;
  box-sizing: border-box;
  width: 1px;
  height: 100px;
  background: #ffffff;
  margin: 0 24px;
}

.circle{
  display: inline-block;
  width:8px;
  height:8px;
  background-color:@dominant-color;
  border-radius:50%;
  &.disable{
    background-color:#aaa;
  }
}

.ant-btn-dominant ,
.ant-select-dominant {
  background-color: @dominant-color!important;
  border-color: @dominant-color!important;
  color: @white!important;
  .ant-select-arrow{
    color: @white!important;
  }

  &:hover{
    background:#ff9a44!important;
  }
  &[disabled]{
    background: #fbcda5!important;
    border:none;
  }
}

.ant-btn-dominant-white ,
.ant-select-dominant-white{
  background: #e5e5e5!important;
  color:#333333!important;
  border:none!important;
  .ant-select-arrow{
    color: #333333!important;
  }
  &[disabled]{
    opacity: 0.5;
  }
  &:hover{
    background: #dddddd!important;

  }
}

.custom-modal{
  .ant-btn{
    background: #e5e5e5!important;
    color:#333333!important;
    border:none!important;
    .ant-select-arrow{
      color: #333333!important;
    }
    &[disabled]{
      opacity: 0.5;
    }
    &:hover{
      background: #dddddd!important;
    }
  }
  .ant-btn-primary{
    background-color: @dominant-color!important;
    border-color: @dominant-color!important;
    color: @white!important;
    &:hover{
      background:#ff9a44!important;
    }
    &[disabled]{
      background: #fbcda5!important;
      border:none;
    }
  }
}

.select-edit{
  .ant-select-selection{
    background-color: #e5e5e5!important;
    border-color: #d3d3d3!important;
    color: #333333!important;
    .ant-select-arrow{
      color: #333333!important;

    }
    &:hover{
      background:#dddddd!important;
    }
    &[disabled]{
      background: #fbcda5!important;
      border:none;
    }
  }
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
  background-color:#dddddd !important
}
.select-edit-item{
  &.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
    background-color:#dddddd !important
  }
}


.ant-btn-transparent{
  color: @dominant-color!important;
  background-color: #fff!important;
  border-color: transparent!important;
  box-shadow: none;
  &[disabled]{
    opacity: 0.5;
  }
}

.ant-select + .ant-select,
.ant-select + .ant-calendar-picker,
.ant-select + .ant-btn {
  margin-left: 10px;
}

.cursor-p{
  cursor: pointer;
}


.color-dominant {
  color: @dominant-color;
}

.font-bold,h1, h2, h3, h4, h5, h6{
  font-weight: bold;
}

a.a-hover:hover {
  color: @dominant-color!important;
  opacity: 0.85;
}

.title-border-l{
  border-left: 4px solid @dominant-color;
}

.border-t{
  border-top: 1px solid #e5e5e5;
}

.border-b{
  border-bottom: 1px solid #e5e5e5;
}

.border{
  border: 1px solid #e5e5e5;
}

.space-nowrap{
  white-space: nowrap;
}

.po-re{
  position: relative;
}

.po-ab{
  position: absolute;
  z-index: 10;
}
//####新增统一样式///////
//全局编辑按钮
.ant-btn-edit{
  opacity: 1 !important;
  color: #333333!important;
  background-color: #f5f5f5 !important;
  border: 1px solid #d3d3d3 !important;
  height:32px!important;
  [class^="icon-"]:before, [class*=" icon-"]:before{
    color: #666666!important;
  }
  &:hover{
    background: #e9e9e9!important;
    border: 1px solid #999999!important;

  }
}
.ant-btn-edit[disabled]{
  border: 1px solid #d3d3d3!important;
  background: #f5f5f5!important;
  color:#cccccc!important;
  [class^="icon-"]:before, [class*=" icon-"]:before{
    color: #cccccc!important;
  }
}
//下拉菜单按钮 自定义
.Dropdown-self{
  &.ant-dropdown-trigger{
    background-color: #e5e5e5!important;
    border-color: #d3d3d3!important;
    color: #333333!important;
    height: 32px !important;
  }
}
.Dropdown-Menu-self{
  .Dropdown-MenuItem-self{
    .ant-upload-disabled{
      color:rgba(0, 0, 0, 0.25) !important;
    }
    &:hover{
      background: #f1f1f1!important;
      color:#F58320!important;
      .ant-upload{
        color:#F58320!important;
      }
      .ant-upload-disabled{
        color:rgba(0, 0, 0, 0.25) !important;
      }
    }
    &.ant-dropdown-menu-item-disabled:hover{
      background: #ffffff!important;
      color:rgba(0, 0, 0, 0.25) !important;
    }
  }
}

.modal-heightLimit-self{
  .ant-modal-body{
    max-height:60vh;
    overflow-y: scroll;
    .height-limit-40vh{
      max-height:40vh;
      overflow-y: scroll;
    }
  }
}
//弹窗按钮
//.ant-btn-modal-yes{
//  min-width: 80px!important;
//  font-size: 14px!important;
//  color: #ffffff!important;
//  border: none!important;
//  &:hover{
//    background: #ff9a44!important;
//  }
//  &[disabled]{
//    background: #fbcda5!important;
//  }
//}
//.ant-btn-modal-no{
//  background: #e5e5e5!important;
//  color: #333333!important;
//  border: none!important;
//  min-width: 80px;
//  height:32px;
//  font-size: 14px;
//  &:hover{
//    background:#dddddd!important;
//  }
//  &[disabled]{
//    color: #bbbbbb!important;
//    background: #e5e5e5!important;
//  }
//}
