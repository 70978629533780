.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}
.mactable{
  .ant-table-placeholder{
    border-bottom:1px solid #e8e8e8 !important;
    padding:0px !important
}
.ant-input-group-sm .ant-input, .ant-input-group-sm > .ant-input-group-addon{
    padding: 5px !important;
    text-align: center;
     }
}
.macerror{
  font-size: 12px;
  color: red;
  text-align: left;
}
.Configuration-check {
  color: #333333 !important
}
.new-cotent-from .ant-empty-description{
  margin:  0 auto !important
}
.noExpand {
  .ant-table-row-expand-icon {
    display: none;
  }
}
.edittimeform {
  margin: 20px 0 0 0;
.ant-form-item-label > label::after{
  content: '' !important;
  }
  .ant-form-item-required::before{
    margin-right:0 !important
 }
}
.macinput .has-error .ant-form-explain, .has-error .ant-form-split {
  display:none
}
.sche-model  .transfer-wrap-box {
  width: 340px !important;
  height: 300px !important;
}
.approve-modal .transfer-wrap-box {
  width: auto !important;
  height: 200px !important;
}
.approve-modal .selectTips {
  padding-top: 20%;
}
.none-c{
  // color: #857e7d !important
  opacity: 0.5;

}
.approve_ul{
  padding: 10px;
  margin: 0;
}
.approve_p{
  opacity: 0.4;
  text-align: left;
  margin: 0;
}
.approve_li{
list-style: none;
color: #333333;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
// display: inline-block;
width: 92% !important;
font-size: 12px;
text-align: left;

    .ant-tree-switcher-noop i{
      display: none;
    }
    .ant-tree.ant-tree-show-line li span.ant-tree-switcher{
      background-color: #fff !important;
    }
}
.approve-modal .transfer-list-content {
  overflow: scroll;
  height: 100%;
}
.approve-modal .transfer-wrap-box .ant-tree-title{
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // display: inline-block;
  width: 100% !important;
  font-size: 12px;
  text-align: left;
}
.approve-modal .ant-col-2 .ant-input{
  padding: 0;
  text-align: center;

}
.icon_pd{
  padding-left: 10px;
}
.approve_li .ant-tree{
  display: inline-block;
}
.mactable .ant-table-tbody > tr:hover > td{
  background:none !important;
  }
  .cvsmodal{
    width: 540px !important;
  }
  .cvsmodal .ant-table-small > .ant-table-content > .ant-table-body{
    margin: 0 !important
 }
 .nomailp{
  //  opacity: 0.6;
  color: #888888 !important;
 }
 .accessdenied{
  .ant-empty{
    position: relative;
  }
   .ant-empty-description{
     width: 100% !important;
     width: 100% !important;
     position: absolute;
     z-index: 1;
     left: 0;
     bottom: 45px;
     font-size: 14px;

   }
   .title{
     font-size: 22px!important;
     font-weight: bold;
     color: #444444;
   }
   button{
     font-size: 14px;
    background-color: #444444;
       border: 1px solid #444444;
    }
    .ant-empty-image{
      height:auto !important
    }
 }
