.Skeleton-main{
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top:45%;
    .loading{
          // width: 200px;
          height: 20px;
          margin: 0 auto;
          text-align: center;
      }
      .loading span{
          display: inline-block;
          width: 20px;
          height: 100%;
          margin-right:15px;
          background: #F58320;
          animation: load 1.04s ease infinite;
          -webkit-animation: load 1.04s ease infinite;
      }
      .loading span:last-child{
          margin-right: 0px; 
      }
      @keyframes load{
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
       }
       @-o-keyframes load{
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
        }
      @-moz-keyframes load{
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
        }
      @-webkit-keyframes load{
          0%{
              opacity: 1;
          }
          100%{
              opacity: 0;
          }
      }
      .loading span:nth-child(1){
           animation-delay:0.13s;
          -webkit-animation-delay:0.13s;
      }
      .loading span:nth-child(2){
           animation-delay:0.26s;
          -webkit-animation-delay:0.26s;
      }
      .loading span:nth-child(3){
          animation-delay:0.39s;
          -webkit-animation-delay:0.39s;
      }
      .loading span:nth-child(4){
          animation-delay:0.52s;
          -webkit-animation-delay:0.52s;
      }
      .loading span:nth-child(5){
          animation-delay:0.65s;
          -webkit-animation-delay:0.65s;
      }
      .loading span:nth-child(6){
        animation-delay:0.78s;
        -webkit-animation-delay:0.78s;
    }
    .tips{
      padding-top: 15px;
    }
      
}