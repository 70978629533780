.pb-s { padding-bottom: @spacer * 0.5 !important;  }
.pb-m { padding-bottom: @spacer * 1 !important;  }
.pb-l { padding-bottom: @spacer * 2 !important;  }
.pl-s { padding-left: @spacer * 0.5 !important;  }
.pl-m { padding-left: @spacer * 1 !important;  }
.pl-l { padding-left: @spacer * 2 !important;  }
.pr-s { padding-right: @spacer * 0.5 !important; }
.pr-m { padding-right: @spacer * 1 !important; }
.pr-l { padding-right: @spacer * 2 !important; }
.pt-s { padding-top: @spacer * 0.5 !important; }
.pt-m { padding-top: @spacer * 1 !important; }
.pt-l { padding-top: @spacer * 2 !important; }
.pa-s { padding: @spacer * 0.5 !important; }
.pa-m { padding: @spacer * 1 !important; }
.pa-l { padding: @spacer * 2 !important; }

.pt-4 { padding-top: 4px; }
.pt-30{ padding-top: 30px;}
.pt-20{ padding-top: 20px;}
.pl-50{ padding-left: 50px;}
.pl-30{ padding-left: 30px;}
.pl-20{ padding-left: 20px;}
.pr-30{ padding-right:30px;}
.pr-20{ padding-right: 20px;}
.pa-20{ padding: 20px; }
.pa-30{ padding: 30px; }


.ma-s { margin: @spacer * 0.5 !important;  }
.ma-m { margin: @spacer * 1 !important;  }
.ma-l { margin: @spacer * 2 !important;  }
.mr-s { margin-right: @spacer * 0.5 !important;  }
.mr-m { margin-right: @spacer * 1 !important;  }
.mr-l { margin-right: @spacer * 2 !important;  }
.mb-s { margin-bottom: @spacer * 0.5 !important;  }
.mb-m { margin-bottom: @spacer * 1 !important;  }
.mb-l { margin-bottom: @spacer * 2 !important;  }
.ml-s { margin-left: @spacer * 0.5 !important;  }
.ml-m { margin-left: @spacer * 1 !important;  }
.ml-l { margin-left: @spacer * 2 !important;  }
.mt-s { margin-top: @spacer * 0.5 !important;  }
.mt-m { margin-top: @spacer * 1 !important;  }
.mt-l { margin-top: @spacer * 2 !important;  }
.mb-30{ margin-bottom: 30px;}
.mb-20{ margin-bottom: 20px;}
