
.forgotPassword{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    height: 100vh;
    background: @dominant-color;
    overflow: hidden;
    @media (max-width: 1366px) {
      .signUp-wrap-wrap{
        transform: scale(.7) ;
      }
    }
    @media screen and (min-width:1366px) and (max-width:1919px){
      .signUp-wrap-wrap{
        transform: scale(.875) ;
      }
    }
    .signUp-wrap-wrap{
      position: relative;
      display: block;
      min-width: 650px;
      .line_1{
        width: 100vw;
        height: 100vmax;
        background: #ff9100;
        position: absolute;
        top: 300px;
        -webkit-transform: rotate(-34deg) skewY(45deg);
        transform: rotate(-56deg) skewY(21deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
      }
      .line_2{
        width: 100vw;
        height: 100vh;
        background: #f57520;
        position: absolute;
        top: 120px;
        -webkit-transform: rotate(-34deg) skewY(45deg);
        transform: rotate(-56deg) skewY(21deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
      }
      .signUp-wrap{  
        box-shadow: 20px 20px 1px 2px;
        background: #fff;
        border-radius: 20px;  
        justify-content: flex-start;
        display: flex;
        flex-shrink: 0;
        position: relative;
        z-index: 10;
        padding: 10%;
        width: 100%;
      }
      .signUp-title{
        display: none;
        width: 450px;
        text-align: center;
        color: #484848;
        background: @white;
        .foxit-logo{
          margin-bottom: 20px;
        }
        span{
          font-size: 20px;
        }
        p{
          font-size: 13px;
          margin: 20px auto 50px;
          line-height: 1.8;
        }
        .table-i{
          width: 222px;
        }
      }
      .signUp-form{
        width: 100%;
        // z-index: 3;
        color: #d6d6d6;
        background: #fff;
        .ant-form-item{
          min-height: 65px;
          margin-bottom: 0;
          .ant-form-explain{
            font-size: 12px!important;
          }
        }
        .foxit-logo{
          margin-bottom: 70px;
          width:81px;
          height:33px;
        }
        .signUp-logo{
          cursor: pointer;
          // margin-bottom: 55px;
          display: flex;
          flex-direction: column;
          justify-items: center;
          justify-content: center;
          p.title{
            vertical-align: text-bottom;
            font-size: 34px;
            text-align: center;
            //text-transform: capitalize;
            display: inline-block;
            color:#333333;
            margin:0 0 20px 0;
            font-weight: bold;
            font-family: "Segoe UI","Open Sans",Verdana,"Microsoft YaHei",Arial, sans-serif;
            line-height: 1;
            white-space: nowrap;
          }
          p.explain{
            font-size: 14px;
            text-transform: capitalize;
            display: inline-block;
            color:#999999;
            text-align: justify;
          }
        }
        .iconMail,.iconPassword{
          display:flex;
          margin-right:10px;
        }
        .pwdInfo{
          display: flex;
          justify-content: space-between;
          margin-bottom:34px;
          span{
            color:#999999;
            padding:0;
          }
        }
        .ant-input-affix-wrapper .ant-input:not(:first-child){
          font-size: 16px;
          padding-left:40px;
          border:none;
          border-bottom:1px solid #f58320;
          border-radius:0;
          outline: none!important;
        }
        .ant-input-affix-wrapper .ant-input::-webkit-input-placeholder{
          font-size: 16px;
        }
        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
          transition: background-color 5000s ease-in-out 0s !important;
        }
  
        .ant-checkbox-checked .ant-checkbox-inner ,.ant-checkbox-inner {
          background-color: @dominant-color;
          border-color:@dominant-color;
        }
        .ant-checkbox-wrapper {
          color: #d6d6d6;
        }
        .ant-btn-primary{
          color: #fff;
          background-color: @dominant-color;
          border-color: @dominant-color;
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
          border-radius: 4px;
          &:hover{
            background-color: #ff9a44;
            border-color: #ff9a44;
          }
        }
        .ant-input {
          outline: none;
          border: none;
          //border-color: #4f5154;
          color: #666666;
          font-size: 16px;
          line-height: 1.42857143;
          &:hover{
            //background-color: #2d2d2d!important;
          }
          &::-webkit-input-placeholder{
            color:#666666;
          }
          &:focus{
            box-shadow: none;
          }
        }
        .wrapBtn {
          width: 100%;
        }
        .wrapBtn a{
           color: red;
          // margin-bottom:30px;
        }
      }
      .SignUpGuide{
        color: #aaaaaa;
        width: 450px;    
        font-size: 14px;
        text-align: left;
      }
    }
    .icon-captchat::before{
       font-weight: 600 !important;
    }
    .signUp-icon{
       padding-bottom: 20px;
       text-align: center;
    }
    .icon-expired::before, .icon-success::before{
      font-size: 60px;
    }
    .Captchat {
      .ant-form-item-control{
      width: 100%;
      }
    .ant-form-item-children{
      width: 100%;
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-lg{
      width: 65%;
      vertical-align: middle;
    }
    .code_Captchat{
      margin-left: 2%;
      width: 33%;
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      cursor: pointer;
    }
    .code_Captchat img {
      width: 100%;
      height: 100%;
    }
    .clickNone{
      pointer-events: none;
      cursor:not-allowed !important;
    }
  }
  }