.securityContainer{
  .from-wrap{
    .ant-form-item-control-wrapper{
      max-width: 400px;
    }
  }
}
.DissolveBox{
  background-color: #f9f9f9;
  padding:0px 12px;
  border:2px solid #eeeeee;
  max-width: 720px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.DissolveTitlte{
  display: inline-block;
  padding-bottom: 8px;
}
.DissolveBtn{
  padding-top: 8px;
}
