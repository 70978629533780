.display-flex {
    display: flex;
    &.justify-center {
        justify-content: center;
    }
    &.space-between {
        justify-content: space-between;
    }
    &.space-around {
        justify-content: space-around;
    }
    &.direction-column{
        flex-direction:column;
    }
    &.flex-start{
        justify-content: flex-start;
    }
    /*
        flex-direction属性：决定主轴的方向（即项目的排列方向）
    */
    &.align-center {
        align-items: center;
    }
    &.align-top {
        align-items: flex-start;
    }
    &.align-bottom {
        align-items: flex-end;
    }
    &.align-baseline {
        align-items: baseline;
    }
    /*
        align-items属性：定义在交叉轴上的对齐方式
    */
    &.flex-01 {
        &>* {
            flex: 0 1 auto;
        }
    }
    &.flex-10 {
        &>* {
            flex: 1 0 auto;
        }
    }
    &.flex-11 {
        &>* {
            flex: 1 1 auto;
        }
    }
    /*
        flex-grow: 0;定义项目的放大比例   默认值为0，即如果空间有剩余，也不放大。
        flex-shrink: 1;定义项目的缩小比例 默认值都为1，即如果空间不足将等比例缩小。如果有一个项目的值为0，其他项目为1，当空间不足时，该项目不缩小。
        flex-basis: auto;定义在分配多余空间之前，项目占据的主轴空间。默认值为auto，浏览器根据此属性检查主轴是否有多余空间。
    */
}
