.react-draggable, .cursor-move{
  cursor: move;
  strong {
    background: #ddd;
    border: 1px solid #999;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    padding: 3px 5px;
    text-align: center;
  }
}
.no-cursor {
  cursor: auto;
}
.card-tool {
  position: absolute;
  right: 24px;
  top: 24px;
}

.list-group {
  .list-group-item {
    position: relative;
    display: block;
    margin-bottom: -1px;
    padding: 12px 16px;
    background: transparent;
    border: 1px solid #ddd;
    border-color: rgba(120, 130, 140, 0.065);
    border-width: 1px 0;
    &:first-child {
      border-top-width: 0;
    }
    &:last-child {
      border-bottom-width: 0;
    }
  }
}
.no-padding {
  .ant-card-body {
    padding: 0 !important;
  }
}
.new-card{
  .ant-card-bordered{
    box-shadow: 0px 0px 5px 1px #e8e8e8;
  }
  .ant-card{
    border-radius: 5px !important;
  }
  .ant-col{
    padding-left: 15px!important;
    padding-right: 15px!important;
  }
  .ant-card-hoverable:hover{
    border-color: rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.09) !important;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.09) !important;
  }
  .ant-card-cover{
    border-bottom: 1px solid #edf1f6;
  }
  .ant-card-cover img{
    padding: 10%;
  }
  .ant-card-meta-title{
    font-size: 1em !important;
  }
  .ant-card-meta-description{
    font-size: 0.8em !important;
  }
  .ant-card-body{
    min-height: 135px;
  }
}
.backListBtn{
  height: 16px;
  position: absolute;
  // left: 306px;
  z-index: 1;
  top: 68px;
  cursor: pointer;
}
