img {
  vertical-align: middle;
}
.img-responsive{
  width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
i img{
  width: 1.2em;
  height: 1.2em;
}
