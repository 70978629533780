.main{
  &.AdAuthentication_container{
    background: linear-gradient(#ffffff 50%, #F3F5F7 50%);
  }
  .main-cotent{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom:80px;
    .detail-title{
      text-align: center;
      font-weight: bold;
    }
    .stepsContainer{
      position: relative;
      .wrap_step{
        position:relative;
        left:50%;
        transform:translateX(-50%);
        .goPrev{
          width:35%;
          height:64px;
          position:absolute;
          left:0;top:0;
        }
        .goPrev_step2{
          cursor: pointer;
        }
        .stepsSelf{
          width:100%;
          height:100px;
          display: flex;
          justify-content: space-between;
          .stepItem{
            width:210px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            opacity: .3;
            &.act{
              opacity: 1;
            }
            .stepNum{
              width:32px;
              height:32px;
              line-height:32px;
              text-align:center;
              background:#F58320;
              border-radius:50%;
              color:#ffffff;
              margin-bottom:10px;
            }
          }
        }
      }
      .progressBorder{
        border-bottom:1px dashed #F58320;
        opacity: .3;
        margin-top:18px;
        width:calc(100% ~" - 242px ");
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        &.nextStep{
          opacity:1;
        }
      }
      .ant-steps{

        .ant-steps-item{
          &.ant-steps-item-wait{
            opacity: .5!important;
          }
          display: flex;
          flex-direction: column;
          width:250px;
          .ant-steps-item-icon{
            background:#F58320!important;
            border:none;

            .ant-steps-icon{
              color:#ffffff!important;
            }
          }
          .ant-steps-item-title::after{
            background-color: transparent;
            height:0;
            border-bottom:1px dashed #F58320;
            opacity: .3;
            display: none;
          }
        }
        .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after{
          background-color: transparent;
          opacity: 1;
        }
        .ant-steps-item-wait{
          opacity: .3;
        }
        .ant-steps-item:nth-child(1){
          .ant-steps-item-icon{
            margin-left:80px;
          }
        }
        .ant-steps-item:nth-child(2){
          .ant-steps-item-icon{
            margin-left:100px;
          }
        }
      }
      .stepContainer1{
        .ant-form{
          .ant-form-item-required::before{
            content: '*' !important;
          }
          .ant-form-explain{
            font-size: 12px;
            //width:160%;
          }
        }
      }
      .userGuide{
        padding:0px 30px 10px 30px;
        background: #f9f9f9;
        .wrapInput{
          display: flex;
          .ant-input{
            width:325px;
            margin-right:10px;
            background:#f3f3f3;
            border:1px solid #b7b7b7;
          }
        }
        .CollapseHeader{
          display: flex;
          justify-content: space-between;
          span:nth-child(1){
            font-weight: bold;
          }
          .btnRight{
            color:#3b73af;
          }
        }
        .wrapCode{
          display: flex;
          .detailCode{
            white-space: nowrap;
          }
          .wrapLine{
            text-align: right;
            width: 20px;
            margin-right:10px;
            padding-right:5px;
            border-right:1px solid #666666;
          }
        }
      }
      .guideTitle{
        padding-top:15px;
        color:#F58320;
        cursor: pointer;
      }
    }
  }
}
