.b-a {
  border: 1px solid @border-color;
}
.b-l {
  border-left: 1px solid @white;
}
.b-r {
  border-right: 1px solid @white;
}
.b-b {
  //border-bottom: 1px solid @border-color;
}
