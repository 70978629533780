.dashboard-main{
    p{
        margin: 0 !important;
        padding: 0 !important;
    }
    .title{
        width: 100%;
        //height: 60px;
        //line-height: 60px;
        background-color: #ffffff;
        box-shadow: 0 5px 5px#e8e8e8;
      .cotent-tips{
        height: 43px;
        line-height: 43px;
      }
      .title-detail{
        height: 43px;
        line-height: 43px;
          font-weight: bold;
          display: inline-block;
        }
      .icon-info{
        font-size: 14px!important;
      }
    }

    .pd-20{
      padding: 20px;
    }
    .select{
        text-align: right;
        color: #333333;
        line-height: 3;
        //padding-right: 20px;
        text-transform: none;
        border-color: #f2f2f2;
        .ant-select-selection-selected-value{
            color: #333333 !important
        }
      div{
        text-align:left;
      }
    }
    .dashboard-connect{
        height: 100px;
        padding-left: 30px;
    }

    [class^="icon-"], [class*=" icon-"]{
        font-size: 30px !important;
    }
    .ant-card-meta-avatar {
        float: left;
        padding-right: 14px !important;
        line-height: 60px;
    }
    .ant-card-meta-description{
        color: #999999 !important;
    }
    .ant-card-meta-title{
        font-size: 30px !important;
        color: #333333 !important;
    }
    .ant-card-meta-detail > div:not(:last-child){
        margin-bottom: 0px !important;
    }
    .gutter-box {
        padding: 0 0 20px !important;
    }
    .ant-card-body{
        // padding:0 20px 20px 20px !important
    }
    .ant-card-head-title{
        text-transform: none !important;
        font-size: 16px !important;
        color: #333333;
    }
    .tips_chart {
        width: 155px;
        height: 52px;
        text-align: center;
        line-height: 52px;
        vertical-align: middle;
    }
    .tips_chart p{
        text-transform: none !important;
        font-size: 12px !important;
        color: #ffffff;
    }
    .ant-card {
        box-shadow: 5px 5px 5px #e8e8e8;
    }

    .other_crad{
        background-color: #ffffff;
        box-shadow: 5px 5px 5px #e8e8e8;
        margin: 0 !important;
        .ant-card {
            box-shadow: none !important;
        }
    }
    .gcs-radius{
           padding: 20px 10px 10px 10px;
    }
    .gcs-radius a{
        width: 110px;
        height: 110px;
        line-height: 105px;
        text-align: center;
        display: inline-block;
        background: #ffffff;
        text-decoration: none;
        color: #333333;
        border-radius: 50%;
        -moz-border-radius:50%;
        -ms-border-radius:50%;
         -o-border-radius:50%;
         font-size: 24px;
      }

      .border_a4{
        border: 5px solid #a4a1fb;
      }
      .border_5f{
        border: 5px solid #5fe3a1;
      }
      .border_f9{
        border: 5px solid #f99e00;
      }
      .border_6b{
        border: 5px solid  #6BBFFF;
      }
      .gutter-box-ohter{
          text-align: center;
      }
      .gcs-radius-tips{
          color: #4d4f5c;
          font-size: 14px;
          text-transform: none;
      }
      .last-div .gutter-box{
          padding: 0 !important;
      }
    //   .echarts-for-react{
    //       width: 1080px !important;
    //   }
      @media (min-height:960px){
        .echarts-for-react{
            height: 39vh !important;
        }
      }
      @media (min-height:900px)and (max-height:959px){
        .echarts-for-react{
            height: 32vh !important;
        }
      }
      @media (min-height:859px) and (max-height:899px){
        .echarts-for-react{
            height: 29vh !important;
        }
      }
     .week-select{
         position: absolute;
         z-index: 1;
         top: 25px;
         right: 28px;
     }
    .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active ,.ant-select-selection:hover{
        border-color:#999999 !important;
        box-shadow:none !important
    }
    .ant-select-selection{
         //border-color:#999999 !important
    }
    .ant-card-head-title {
        padding: 20px 0 0 0!important;
    }
    .ant-card-loading-content{
        padding: 10px;
        height: 100px;
        overflow: hidden;
    }
    .minSelect .ant-select-selection{
         min-width: 192px;
    }
    .minSelect .ant-form-item{
        margin-bottom: 0 !important;
    }
    .echarts-for-react div{
        overflow:visible !important;
        // min-height: 275px!important;
        canvas{
            // top:-18px !important;
            min-height: 275px!important;
            // margin-left: 20px !important
            width: 101.5% !important;
        }
    }

}


.icon-new-user {
    .path1::before{
        font-size: 28px;
    }
    .path2::before{
        font-size: 28px;
    }
    .path2{
        line-height: 2;
    }
}
.lang-menu .ant-menu-submenu-title{
   height: 60px !important;
}
.min-width-time{
    min-width: 100px;
    display: inline-block;

}
.Dropdown_new_list.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover{
    a{
        color: #f58320 !important;
    }
    background-color: #f1f1f1 !important;
}
