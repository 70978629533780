
.login{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  height: 100vh;

  background: @dominant-color;
  overflow: hidden;
    //@media (max-width: 850px) {
    //  .login-wrap{
    //    min-width: 100%!important;
    //    display: flex;
    //    flex-direction: column;
    //    align-items: center;
    //    box-shadow:none!important;
    //    margin:auto!important;
    //  }
    //  .login-form{
    //    width: 450px!important;
    //  }
    //}
    @media (max-width: 1366px) {
      .login-wrap-wrap{
        transform: scale(.7) ;
        .scale_1{
          transform:scale(1.2) translateX(26px);
          .item_email{
            transform:translateY(10px);
          }
        }
      }
    }

  @media screen and (min-width:1366px) and (max-width:1919px){
    .login-wrap-wrap{
      transform: scale(.75) ;
      .scale_1{
        transform:scale(1.2) translateX(26px);
        .item_email{
          transform:translateY(15px);
        }
      }
    }
  }
  .login-wrap-wrap{
      position: relative;
      width:1306px;
      height: 783px;
      margin-bottom: -28px;
    .line_1{
      width: 100vw;
      height: 100vmax;
      background: #ff9100;
      position: absolute;
      left: 385px;
      top: 429px;
      -webkit-transform: rotate(-34deg) skewY(45deg);
      transform: rotate(-56deg) skewY(21deg);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    .line_2{
      width: 100vw;
      height: 100vh;
      background: #f57520;
      position: absolute;
      left: 384px;
      top: 305px;
      -webkit-transform: rotate(-34deg) skewY(45deg);
      transform: rotate(-56deg) skewY(21deg);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    .login-wrap{
      width:1306px;
      height: 783px;
      background: url("../style/imgs/back_login.png") no-repeat;
      background-size: 100% 100%;
      justify-content: flex-start;
      display: flex;
      flex-shrink: 0;
      position: absolute;
      left:0;
      top:0;
      z-index: 10;
    }
    .login-title{
      display: none;
      width: 450px;
      text-align: center;
      color: #484848;
      background: @white;
      .foxit-logo{
        margin-bottom: 20px;
      }
      span{
        font-size: 20px;
      }
      p{
        font-size: 13px;
        margin: 20px auto 50px;
        line-height: 1.8;
      }
      .table-i{
        width: 222px;
      }
    }
    .login-form{
      max-width: 680px;
      z-index: 3;
      color: #d6d6d6;
      margin-left:104px;
      margin-top:15px;
      padding-top:38px;
      .ant-form-item{
        margin-bottom: 30px;
      }
      .ant-form-item-with-help{
        margin-bottom: 11px;
      }
      .login-logo{
        cursor: pointer;
        margin-bottom: 20px;
        div{
          width:100%;
          height:111px;
          overflow: hidden;
        }
        .title{
          vertical-align: text-bottom;
          font-size: 40px;
          text-transform: capitalize;
          color:#333333;
          margin:0 0 20px 0;
          font-weight: bold;
          font-family:"Segoe UI","Open Sans",Verdana, "Microsoft YaHei",Arial, sans-serif;
          line-height: 1;
          white-space: nowrap;
          display: block;
        }
        p.explain{
          font-size: 14px;
          max-width: 490px;
          //text-transform: capitalize;
          display: inline-block;
          color:#999999;
          //text-align: justify;
        }
        .foxit-logo{
          margin-bottom: 70px;
          width:auto;
          height:auto;
          // max-width: 70px;
          // max-height: 70px;
        }
      }
      .iconMail,.iconPassword{
          display:flex;
          margin-right:10px;
      }
      .pwdInfo{
        display: flex;
        justify-content: space-between;
        margin-bottom:34px;
        span{
          color:#999999;
          padding:0;
        }
      }
      .ant-input-affix-wrapper .ant-input:not(:first-child){
        padding-left:40px;
        border:none;
        border-bottom:1px solid #f58320;
        border-radius:0;
      }
      input:-internal-autofill-previewed,
      input:-internal-autofill-selected {
        transition: background-color 5000s ease-in-out 0s !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner ,.ant-checkbox-inner {
        //background-color: @dominant-color;
        border-color:@dominant-color;
      }
      .ant-checkbox-wrapper {
        color: #d6d6d6;
      }
      .ant-btn-primary{
        color: #fff;
        background-color: @dominant-color;
        border-color: @dominant-color;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
        border-radius: 4px;
        &:hover{
          background-color: #ff9a44;
          border-color: #ff9a44;
        }
      }
      .ant-input {
        border-color: #4f5154;
        color: #666666;
        font-size: 14px;
        line-height: 1.42857143;
        &:hover{
          //background-color: #2d2d2d!important;
        }
        &:focus{
          box-shadow: none;
        }
        &::-webkit-input-placeholder{
          color:#666666;
        }
      }
    }
  }
}
