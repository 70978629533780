@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?yia4el');
  src:  url('fonts/icomoon.eot?yia4el#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?yia4el') format('truetype'),
    url('fonts/icomoon.woff?yia4el') format('woff'),
    url('fonts/icomoon.svg?yia4el#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-support:before {
  content: "\e96a";
  color: #999;
}
.icon-box:before {
  content: "\e95e";
  color: #0071f7;
}
.icon-dropbox:before {
  content: "\e95f";
  color: #0061ff;
}
.icon-Google-drive .path1:before {
  content: "\e960";
  color: rgb(66, 133, 244);
}
.icon-Google-drive .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Google-drive .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(15, 157, 88);
}
.icon-Google-drive .path4:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(255, 205, 64);
}
.icon-local:before {
  content: "\e964";
  color: #f68b1f;
}
.icon-one-drive .path1:before {
  content: "\e965";
  color: rgb(3, 100, 184);
}
.icon-one-drive .path2:before {
  content: "\e966";
  margin-left: -0.9619140625em;
  color: rgb(15, 120, 212);
}
.icon-one-drive .path3:before {
  content: "\e967";
  margin-left: -0.9619140625em;
  color: rgb(20, 144, 223);
}
.icon-one-drive .path4:before {
  content: "\e968";
  margin-left: -0.9619140625em;
  color: rgb(40, 168, 234);
}
.icon-url:before {
  content: "\e969";
  color: #f68b1f;
}
.icon-ou:before {
  content: "\e95c";
}

.icon-transfer:before {
  content: "\e95b";
  color: #999;
}
.icon-cc-copy:before {
  content: "\e95a";
  color: #f58320;
}
.icon-add-user:before {
  content: "\e955";
  color: #666;
}
.icon-active .path1:before {
  content: "\e956";
  color: rgb(255, 255, 255);
}
.icon-active .path2:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-active .path3:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-active .path4:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-account-mode:before {
  content: "\e900";
  color: #f58320;
}
.icon-account:before {
  content: "\e901";
  color: #666;
}
.icon-activated-licenses .path1:before {
  content: "\e902";
  color: rgb(164, 161, 251);
}
.icon-activated-licenses .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-admin-foxit .path1:before {
  content: "\e904";
  color: rgb(245, 130, 32);
}
.icon-admin-foxit .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-admin-role:before {
  content: "\e906";
  color: #888;
}
.icon-admin-role1:before {
  content: "\e95d";
  color: #888;
}
.icon-approve:before {
  content: "\e907";
  color: #fff;
}
.icon-arrow-down:before {
  content: "\e908";
}
.icon-arrow-up:before {
  content: "\e909";
}
// .icon-box:before {
//   content: "\e90a";
//   color: #777;
// }
.icon-cancel-push:before {
  content: "\e90b";
  color: #999;
}
.icon-captchat:before {
  content: "\e90c";
  color: #f58320;
}
.icon-dashboard-new:before {
  content: "\e90d";
  color: #888;
}
.icon-date:before {
  content: "\e90e";
  color: #ddd;
}
.icon-delete:before {
  content: "\e90f";
  color: #fff;
}
.icon-detail-big:before {
  content: "\e910";
  color: #f58320;
}
.icon-detail:before {
  content: "\e911";
  color: #999;
}
.icon-disable:before {
  content: "\e912";
  color: #999;
}
.icon-down-full:before {
  content: "\e913";
  color: #666;
}
.icon-down-gray:before {
  content: "\e914";
  color: #d8d8d8;
}
.icon-down:before {
  content: "\e915";
  color: #aaa;
}
// .icon-dropbox:before {
//   content: "\e916";
//   color: #777;
// }
.icon-edit:before {
  content: "\e917";
  color: #999;
}
.icon-enable:before {
  content: "\e918";
  color: #999;
}
.icon-enterprise:before {
  content: "\e919";
  color: #888;
}
.icon-expired:before {
  content: "\e91a";
  color: #919191;
}
.icon-export:before {
  content: "\e91b";
  color: #fff;
}
.icon-good:before {
  content: "\e91c";
  color: #fff;
}
.icon-googledrive:before {
  content: "\e91d";
  color: #777;
}
.icon-icon-assigned-licenses .path1:before {
  content: "\e91e";
  color: rgb(95, 227, 161);
}
.icon-icon-assigned-licenses .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-assigned-licenses .path3:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-assigned-licenses .path4:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-assigned-licenses .path5:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-assigned-licenses .path6:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-info:before {
  content: "\e924";
  color: #2e6dad;
}
.icon-internal-update:before {
  content: "\e925";
  color: #888;
}
.icon-license-new:before {
  content: "\e926";
  color: #888;
}
.icon-list:before {
  content: "\e927";
  color: #fff;
}
.icon-logo:before {
  content: "\e928";
  color: #f68b1f;
}
.icon-logout:before {
  content: "\e929";
  color: #666;
}
.icon-logs:before {
  content: "\e92a";
  color: #888;
}
.icon-mail-servers:before {
  content: "\e92b";
  color: #888;
}
.icon-new-user .path1:before {
  content: "\e92c";
  color: rgb(228, 228, 228);
}
.icon-new-user .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-non-account-mode:before {
  content: "\e92e";
  color: #f58320;
}
.icon-offline-activation .path1:before {
  content: "\e92f";
  color: rgb(64, 64, 64);
}
.icon-offline-activation .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(245, 131, 32);
}
.icon-onedrive .path1:before {
  content: "\e931";
  color: rgb(119, 119, 119);
}
.icon-onedrive .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(136, 136, 136);
}
.icon-onedrive .path3:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-onedrive .path4:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-online-activation .path1:before {
  content: "\e935";
  color: rgb(64, 64, 64);
}
.icon-online-activation .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(245, 131, 32);
}
.icon-open-file:before {
  content: "\e937";
  color: #555;
}
.icon-orange:before {
  content: "\e938";
  color: #f99e00;
}
.icon-order-down .path1:before {
  content: "\e939";
  color: rgb(153, 153, 153);
}
.icon-order-down .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(102, 102, 102);
}
.icon-order-up .path1:before {
  content: "\e93b";
  color: rgb(102, 102, 102);
}
.icon-order-up .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-product-configuration:before {
  content: "\e93d";
  color: #888;
}
.icon-product-new:before {
  content: "\e93e";
  color: #888;
}
.icon-push-grey:before {
  content: "\e93f";
  color: #999;
}
.icon-push-withe:before {
  content: "\e940";
  color: #fff;
}
.icon-push:before {
  content: "\e941";
  color: #fff;
}
.icon-reports:before {
  content: "\e942";
  color: #888;
}
.icon-retry:before {
  content: "\e943";
  color: #fff;
}
.icon-right:before {
  content: "\e944";
  color: #aaa;
}
.icon-send-test-email:before {
  content: "\e945";
  color: #999;
}
.icon-settings:before {
  content: "\e946";
  color: #888;
}
.icon-success:before {
  content: "\e947";
  color: #36d67b;
}
.icon-time:before {
  content: "\e948";
  color: #999;
}
.icon-total-licenses .path1:before {
  content: "\e949";
  color: rgb(249, 158, 0);
}
.icon-total-licenses .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-total-licenses .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-unapproved:before {
  content: "\e94c";
  color: #999;
}
.icon-uniE934:before {
  content: "\e94d";
  color: #888;
}
.icon-up-full:before {
  content: "\e94e";
  color: #999;
}
.icon-up-gray:before {
  content: "\e94f";
  color: #d8d8d8;
}
// .icon-url:before {
//   content: "\e950";
//   color: #555;
// }
.icon-user-id-new:before {
  content: "\e951";
  color: #888;
}
.icon-users .path1:before {
  content: "\e952";
  color: rgb(228, 228, 228);
}
.icon-users .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-view-details:before {
  content: "\e954";
  color: #999;
}
.icon-delete-999:before {
  content: "\e90f";
  color: #888;
}
