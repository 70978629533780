@initia:				#7bc76f;
.breathe{
    webkit-animation: breathe 1500ms ease infinite;
    -moz-animation: breathe 1500ms ease infinite;
    -o-animation: breathe 1500ms ease infinite;
    animation: breathe 1500ms ease infinite;
    &.first{
      height:80px;
      width:80px;
      border: 5px solid @initia;
      background-color: transparent;
      border-radius: 100%;
      top: -24px;
      left: 40px;
    }
    @media (min-width: 768px) {
      &.first{
        left: 40px;
      }
    }
    @media (min-width: 992px) {
      &.first{
        left: 150px;
      }
    }
    @media (min-width: 1200px) {
      &.first{
        left: 247px;
      }
    }
}
@-webkit-keyframes breathe{
  0% {opacity:.2;}
  100%{opacity:1;}
  50%{opacity:1;}
}
@-moz-keyframes breathe{
  0% {opacity:.2;}
  100%{opacity:1;}
  50%{opacity:1;}
}
@-o-keyframes breathe{
  0% {opacity:.2;}
  100%{opacity:1;}
  50%{opacity:1;}
}
@keyframes breathe{
  0% {opacity:.2;}
  100%{opacity:1;}
  50%{opacity:1;}
}
.breathe-shadow{
  webkit-animation: breathe 1500ms ease infinite;
  -moz-animation: breathe 1500ms ease infinite;
  -o-animation: breathe 1500ms ease infinite;
  animation: breathe 1500ms ease infinite;
  &.second{
    left:calc(50% ~" - 10px ");
    
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: rgba(123, 199, 111, 0.4);
    &.t-l{
      top: -13px;
      left: -35px;
    }
    &.t{
      top: -27px;
    }
    &::after{
      content:" ";
      position: absolute;
      top: -10px;
      left: -10px;
      display:block;
      height:40px;
      width: 40px;
      border-radius: 100%;
      background-color: rgba(123, 199, 111, 0.3);
    }
  }
}

@-webkit-keyframes breathe-shadow{
  0% {opacity:.2;box-shadow:0 1px 10px rgba(255,255,255,0.1)}
  100%{opacity:1;box-shadow:0 1px 40px rgba(123,199,111,0.5)}
  50%{opacity:1;box-shadow:0 1px 80px @initia}
}
@-moz-keyframes breathe-shadow{
  0% {opacity:.2;box-shadow:0 1px 10px rgba(255,255,255,0.1)}
  100%{opacity:1;box-shadow:0 1px 40px rgba(123,199,111,0.5)}
  50%{opacity:1;box-shadow:0 1px 80px @initia}
}
@-o-keyframes breathe-shadow{
  0% {opacity:.2;box-shadow:0 1px 10px rgba(255,255,255,0.1)}
  100%{opacity:1;box-shadow:0 1px 40px rgba(123,199,111,0.5)}
  50%{opacity:1;box-shadow:0 1px 80px @initia}
}
@keyframes breathe-shadow{
  0% {opacity:.2;box-shadow:0 1px 10px rgba(255,255,255,0.1)}
  100%{opacity:1;box-shadow:0 1px 40px rgba(123,199,111,0.5)}
  50%{opacity:1;box-shadow:0 1px 80px @initia}
}

.breathe-drop,.breathe-bor-drop{
  width: 320px;
  padding: 15px 25px;
  background-color: @initia;
  color: @white;
  border-radius:4px;
  position: absolute;
  top: 15px;
  &>*{
    color: @white!important;
  }
}
.breathe-bor-drop{
  top: -45px;
  left: 20px;
  .anticon-close{
    position: absolute;
    right: 10px;
  }
}

.initia-cloud{
  max-width: 700px;
  margin: auto;
  .ant-input-lg{
    font-size: 14px;
  }
  .breathe-shadow.second.t-l{
    top: -32px;
    left: 74px;
  }
}

.breathe-bor{
  width: 100%;
  height: 40px;
  border: 2px solid #7bc76f;
  border-radius: 4px;
  top: -40px;
  left: -10px;
}

.ant-btn-transparent-white{
  color: #fff!important;
  background-color: rgba(255,255,255,0.1)!important;
  border-color:rgba(255,255,255,0.1)!important;
}

.breathe-bag{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  cursor: default;
}

.ant-menu-sub {
  // transition: all 0.5s  !important;
}

.drop-right{
  right: 0;
}